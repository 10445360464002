<template>
  <div id="campusDetails">
    <div class="entry-box">
      <div class="image-box">
        <div class="image-box-left">
          <img class="demand1-icon" src="../assets/images/demand1-icon.png" alt="">
          <img class="left-circular" src="../assets/images/left-circular.png" alt="">
        </div>
        <img class="right-circular" src="../assets/images/right-circular.png" alt="">
      </div>
    </div>
    <div class="content-box">
<!--      <div class="header-box">-->
<!--        <div class="header-left">-->
<!--          <p class="title">公司名称</p>-->
<!--          <p class="name">企业</p>-->
<!--          <p class="name">企业2</p>-->
<!--        </div>-->
<!--        <p class="sign-state">个人中心</p>-->
<!--      </div>-->
      <div class="detail-box">
        <div class="details-left-box">
          <p class="title">{{campusList.event_title}}</p>
          <div class="time-box">
            <p class="time">{{campusList.create_at}} 发布</p>
            <div class="weChat-img-box">
              <img class="weChat-img" src="../assets/images/weChat-img.png" alt="">
              <p class="share">分享</p>
            </div>
          </div>
          <div class="vote-applet-box">
            <img class="vote-applet" src="../assets/images/vote-applet.png" alt="">
          </div>
          <div class="news-topping-box">
            <p class="news-state">该合作信息已置顶</p>
            <p class="install-top">我也要置顶</p>
          </div>
          <div class="essential-information-box">
            <p class="title-list">基本信息</p>
            <p class="type">活动类型： <span class="type-name">{{campusList.event_type}}</span></p>
            <p class="type">现金赞助： <span class="type-name">{{campusList.sponsorship_amount}}</span></p>
            <p class="type">活动时间： <span class="type-name">{{campusList.event_start_time}} 至 {{campusList.event_end_time}}</span>
            </p>
            <p class="type">活动地点： <span class="type-name">{{city}} </span>
            </p>
            <p class="type">活动规模： <span class="type-name">{{campusList.event_scale}}</span></p>
            <p class="type">宣传方式：
            <span class="type-name" v-if="campusList.is_cash === '1'">资金赞助</span>
            <span class="type-name" v-if="campusList.is_material === '1'">物资赞助</span>
              <span class="type-name" v-for="(item,index) in campusList.perform_functions"
                                        :key="index.id">{{item}}</span>
            </p>
          </div>
          <p class="partition"></p>
          <div class="sponsor-content-box">
            <p class="title-list">活动方案</p>
            <p class="content">{{campusList.event_infor}}</p>
          </div>
        </div>
        <div class="details-right-box">
          <p class="publisher-information">发布人信息</p>
          <p class="company-name">{{campusList.university_name}}</p>
          <p class="title">社团级别：<span class="title-name">{{campusList.association_level}}</span></p>
          <p class="title">社团类型：<span class="title-name">{{campusList.association_type}}</span></p>
          <p class="title">所属职位：<span class="title-name">{{campusList.association_position}}</span></p>
          <p class="title">项目联系人：<span class="title-name">{{campusList.nickname}}</span></p>
          <p class="contact-information">点击查看赞助者联系方式</p>
        </div>
      </div>
    </div>
    <!--    查看赞助者信息弹窗-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%">
      <div class="tip-background">
        <div class="content-box" ref="msk">
          <div class="content-title">
            <div class="introduce">
              <p class="words">留下您的联系方式，我们将尽快联系您</p>
              <p class="phone">或者您也可以拨打 <span class="phone-number">010-8170 8868 </span> 联系我们</p>
            </div>
            <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
          </div>
          <div class="input-box">
            <p class="title"><span class="required">*</span>联系人</p>
            <input class="input-frame" type="name" placeholder="请输入姓名" v-model="name">
            <p v-show="isName" class="prompt">联系人不能为空</p>
          </div>
          <div class="input-box">
            <p class="title"><span class="required">*</span>手机号</p>
            <input class="input-frame" @input="changeUserName" type="tel" placeholder="请输入手机号" v-model="tel"
                   maxlength="11">
            <p v-show="isTel" class="prompt">请输入正确手机号</p>
          </div>
          <div class="input-box">
            <p class="title">公司产品/业务</p>
            <input class="input-frame" type="text" placeholder="请输入公司产品/业务" v-model="business">
          </div>
          <div>
            <p class="submit-btn" id="submitBtn" @click="onSubmit(sponsorDetails.id)">提交</p>
          </div>
        </div>
        <img class="close-btn" src="../assets/images/close-btn.png" alt="">
      </div>
    </el-dialog>
  </div>
</template>
<script>
  const Base64 = require('js-base64').Base64
  export default {
    data () {
      return {
        isName: false, // 联系人不能为空
        isTel: false, // 手机号不能为空
        name: '', // 联系人
        tel: '', // 电话号
        companyName: '', // 公司名称
        business: '', // 公司产品业务
        returnId: '', // 活动或者赞助id
        dialogVisible: false,
        campusList: [], // 活动详情
        city: '' // 城市
      }
    },
    methods: {
      // 获取赞助详情
      async campusDetails () {
        this.id = this.$route.query.artist
        const formData = new FormData()
        formData.append('event_id', this.id)
        this.$http.request({
          url: 'Square/actionEventInfoApi',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.campusList = res.data.data // 活动详情
            this.campusList.perform_functions = JSON.parse(this.campusList.perform_functions)
            if (this.campusList.event_province === '全部') {
              this.city = '全国'
            } else if (this.campusList.event_county === '全部') {
              this.city = '全国'
            } else if (this.campusList.event_city === '全部') {
              this.city = '全国'
            } else {
              this.city = this.campusList.event_province + ' ' + this.campusList.event_city + ' ' + this.campusList.event_county
            }
          }
        })
      },
      changeUserName (e) {
        const { value } = e.target
        if (value) {
          document.getElementById('submitBtn').style.color = '#2988FC'
          document.getElementById('submitBtn').style.border = '1px solid #2988FC'
        } else {
          document.getElementById('submitBtn').style.color = '#C0C4CC'
          document.getElementById('submitBtn').style.border = '1px solid #C0C4CC'
        }
      },
      // 校验手机号码
      checkTel (tel) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        return reg.test(tel)
      },
      onSubmit (id) {
        this.name =  Base64.encode(this.name)
        this.returnId = id
        if (this.name === '') {
          this.isName = !this.isName
          return false
        } else if (!this.checkTel(this.tel)) {
          this.isTel = !this.isTel
          return false
        } else {
          const formData = new FormData()
          formData.append('nickname',this.name)
          formData.append('return_id', this.returnId) // 活动或者赞助id
          formData.append('telephone', this.tel)
          // formData.append('return_type', this.companyName) // 公司名称
          formData.append('return_type', '社团活动') // 公司名称
          formData.append('remarks', this.business) // 公司产品业务
          this.$http.request({
            url: 'Sponsor/actionReturnVisitApi',
            method: 'POST',
            data: formData
          }).then((res) => {
            const code = res.data.code
            if (code === 200) {
              this.dialogVisible = false
              this.name = []
              this.tel = []
              this.companyName = []
              this.business = []
            } else {
            }
          })
        }
      }
    },
    mounted () {
      this.campusDetails()
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #campusDetails
      padding-top 54px

      .entry-box
        width 1320px
        margin auto

        .image-box
          width 1320px
          display flex
          position absolute

          .image-box-left
            display flex

            .demand1-icon
              width 157px
              height 254px
              margin-top 280px
              margin-left 40px

            .left-circular
              width 140px
              height 140px
              position absolute
              top 380px
              left 124px

          .right-circular
            width 298px
            height 298px
            position absolute
            right 0
            margin-top 10px

      .content-box
        width 1005px
        margin auto
        position relative

        .header-box
          padding 0 24px
          margin-top 16px
          height 52px
          background #FFFFFF
          opacity 1
          border-radius 3px
          display flex
          align-items center
          justify-content space-between
          margin-bottom 16px

          .header-left
            display flex
            align-items center

            .title
              color #606266
              font-size 14px
              margin-right 12px

            .name
              padding 4px 12px
              background #E9F3FF
              border-radius 2px
              color #2988FC
              line-height 22px
              font-size 10px
              margin-right 8px

          .sign-state
            color #2988FC
            font-size 14px

        .detail-box
          display flex
          margin-top 18px

          .details-left-box
            width 605px
            background #FFFFFF
            box-shadow 0px 3px 24px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 8px
            padding 32px
            margin-right 16px
            margin-bottom 32px

            .title
              color #303133
              font-size 26px
              line-height 37px
              font-weight bold

            .time-box
              display flex
              justify-content space-between
              align-items center
              margin 12px 0 16px 0

              .time
                color #A6AAB3
                font-size 14px

              .weChat-img-box
                display flex
                align-items center
                cursor pointer

                .weChat-img
                  width 24px
                  height 24px
                  margin-right 2px

                .share
                  color #303133
                  font-size 12px
                  cursor pointer

            .time-box:hover + .vote-applet-box
              opacity 1
            .vote-applet-box
              position absolute
              margin-left 430px
              opacity 0
            .news-topping-box
              width 100%
              padding 6px 12px
              background #F0F6FF
              opacity 1
              border-radius 4px
              display flex
              justify-content space-between
              align-items center

              .news-state
                color #2988FC
                font-size 12px

              .install-top
                width 76px
                height 25px
                line-height 25px
                text-align center
                background #2988FC
                border 1px solid #2988FC
                opacity 1
                border-radius 4px
                color #FFFFFF
                font-size 12px
                cursor pointer

            .essential-information-box
              .title-list
                color #303133
                font-size 16px
                margin 24px 0 16px 0
                font-weight bold

              .type
                color #909399
                font-size 14px
                margin-right 8px
                margin-bottom 12px
                line-height 24px

                .type-name
                  color #303133
                  font-size 14px
                  margin-right 4px

            .partition
              width 100%
              border-radius 2px
              height 8px
              background #F5F7FA
              opacity 1
              margin-top 12px

            .sponsor-content-box
              .title-list
                color #303133
                font-size 16px
                font-weight bold
                margin 24px 0 16px 0

              .content
                color #303133
                font-size 14px
                line-height 21px
                margin-bottom 16px

          .details-right-box
            width 256px
            background #FFFFFF
            box-shadow 0px 3px 24px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 8px
            margin-bottom 32px
            padding 32px

            .publisher-information
              font-weight bold
              color #303133
              font-size 16px
              margin-bottom 16px

            .company-name
              color #303133
              font-size 14px
              margin-bottom 12px

            .title
              font-size 14px
              margin-right 8px
              margin-bottom 12px
              color #909399
              line-height 24px

              .title-name
                color #303133
                font-size 14px

            .contact-information
              width 100%
              font-size 14px
              background #F0F6FF
              border-radius 4px
              height 36px
              line-height 36px
              text-align center
              color #2988FC
              margin-top 12px
              cursor pointer
</style>
