<template>
  <div class="contents">

    <div class="case-details">
      <!--    左侧内容-->
      <div class="case-details-left">
        <p class="name">{{caseInfo.case_title}}</p>
        <p class="title">{{caseInfo.organizer}} • {{caseInfo.holding_time}} • {{caseInfo.case_label}}</p>
        <img class="active-cover" :src="caseInfo.case_cover" alt="">
        <div class="case-details-content" v-html="caseInfo.case_details"></div>
      </div>
      <!--    右侧内容-->
<!--      <van-sticky :offset-top="54">-->
        <div class="case-details-right">
          <div class="cover-list">
            <img class="hot-icon" src="../assets/images/hot-icon.png" alt="">
            <p class="title">热门案例</p>
          </div>
          <div class="activity-box" v-for="(item,index) in popularCasesList" :key="index.id"
               @click="goCaseDetails(item.id)">
            <img class="cover" :src="item.case_cover" alt="">
            <p class="name">{{item.case_title}}</p>
          </div>
          <div class="more-activity-list">
            <div class="more-activity" v-for="(item,index) in recommendCasesList" :key="index.id"
                 @click="goCaseDetails(item.id)">
              <img class="cover" :src="item.case_cover" alt="">
              <div class="content">
                <p class="name">{{item.case_title}}</p>
                <p class="time">{{item.holding_time}}</p>
              </div>
            </div>
          </div>
          <div class="news-list" v-show="isNews">
            <div class="cover-list">
              <img class="hot-icon" src="../assets/images/hot-icon.png" alt="">
              <p class="title">相关报道</p>
            </div>
            <div class="content-box" v-for="(item,index) in casesReportList" :key="index.id"
                 @click="jumpLink(item.jump_link)">
              <p class="name">{{item.report_title}} </p>
              <div class="news-content">
                <p class="time">{{item.release_time}}</p>
                <div class="event-providers">
                  <img class="cover" :src="item.news_party_cover" alt="">
                  <p class="trademark">{{item.news_party}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--      </van-sticky>-->
    </div>

    <!--    移动端 案例详情-->
    <div class="mobile-content">
      <p class="title">{{caseInfo.case_title}}</p>
      <p class="active">主办方：{{caseInfo.organizer}}</p>
      <span class="label-active">{{caseInfo.case_type}}</span>
      <span class="label-name">{{caseInfo.sponsor}}</span>
      <img class="cover" :src="caseInfo.case_cover" alt="">
      <div class="active-content-style" v-html="caseInfo.case_details"></div>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        isNews: true, // 相关报道
        caseId: '', // 案例详情id
        caseInfo: '',
        popularCasesList: [], // 热门案例
        recommendCasesList: [],
        casesReportList: [] // 相关报道
      }
    },
    methods: {
      jumpLink (link) {
        location.href = link
      },
      // 跳转到案例详情页
      async goCaseDetails (id) {
        this.caseId = id
        const formData = new FormData()
        formData.append('case_id', this.caseId)
        this.$http.request({
          url: 'Cases/actionCaseInfoApi/',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.caseInfo = res.data.data.CaseInfo // 案例详情
            this.popularCasesList = res.data.data.PopularCasesList // 热门案例
            this.recommendCasesList = res.data.data.RecommendCasesList // 热门案例
            this.casesReportList = res.data.data.CasesReportList // 相关报道
          }
        })
      },
      // 获取案例详情
      async caseDetails () {
        this.caseId = this.$route.query.artist
        const formData = new FormData()
        formData.append('case_id', this.caseId)
        this.$http.request({
          url: 'Cases/actionCaseInfoApi/',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.caseInfo = res.data.data.CaseInfo // 案例详情
            this.popularCasesList = res.data.data.PopularCasesList // 热门案例
            this.recommendCasesList = res.data.data.RecommendCasesList // 热门案例
            this.casesReportList = res.data.data.CasesReportList // 相关报道
          }
          if (this.casesReportList.length === 0) {
            this.isNews = false
          }
        })
      }
    },
    mounted () {
      this.caseDetails()
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    .case-details
      width 1320px
      margin 0 auto
      padding-top 70px
      display flex
      padding-bottom 53px
      align-items end
      position relative

      .van-sticky--fixed
        right unset
        left unset

      .case-details-left
        width 960px
        background #ffffff
        padding 32px
        margin-right 16px
        float left

        .name
          color #303133
          font-size 26px
          margin-bottom 16px

        .title
          color #909399
          font-size 14px
          margin-bottom 24px

        .active-cover
          width 600px
          height 338px

        .case-details-content p strong span
          font-weight bold
          margin-top 24px
          margin-bottom 16px
          color #303133
          font-size 16px

        .case-details-content p span
          line-height 21px
          color #606266
          font-size 14px

        .title-list
          color #303133
          font-size 16px
          font-weight bold
          margin-bottom 16px

        .content
          color #606266
          font-size 14px
          margin-bottom 24px

      .case-details-right
        width 248px
        background #ffffff
        padding 0 16px
        float right
        position sticky
        top 70px
        .cover-list
          margin-bottom 10px
          display flex
          margin-top 22px
          align-items center
          cursor pointer

          .hot-icon
            width 20px
            height 20px
            margin-right 6px
            align-items center

          .title
            color #303133
            font-size 16px
            font-weight bold

        .activity-box
          margin-bottom 6px

          .cover
            width 100%
            height 140px
            border-radius 3px
            line-height 20px
            object-fit cover
            cursor pointer

          .name
            padding 0 6px
            color #FFFFFF
            font-size 14px
            background rgba(0, 0, 0, 0.6)
            opacity 1
            border-radius 0px 0px 3px 3px
            height 30px
            line-height 30px
            margin-top -32px
            display -webkit-box
            -webkit-line-clamp 1
            -webkit-box-orient vertical
            overflow hidden

      .more-activity-list
        padding-top 6px

      .more-activity
        display flex
        margin-bottom 12px
        cursor pointer

      .cover
        width 64px
        height 64px
        object-fit cover
        margin-right 8px
        border-radius 3px

      .content
        .name
          display -webkit-box
          -webkit-line-clamp 2
          -webkit-box-orient vertical
          overflow hidden
          color #303133
          font-size 14px
          height 40px
          line-height 20px

        .time
          color #909399
          font-size 12px
          margin-top 7px

      .news-list
        padding-bottom 2px

        .cover-list
          display flex
          align-items center

          .hot-icon
            width 20px
            height 20px
            margin-right 6px

          .title
            font-size 16px
            font-weight bold
            color #303133

        .content-box
          margin-bottom 16px

        .name
          height 41px
          display -webkit-box
          -webkit-line-clamp 2
          -webkit-box-orient vertical
          overflow hidden
          color #303133
          line-height 21px
          margin-bottom 8px
          font-size 14px

        .news-content
          display flex
          justify-content space-between

          .time
            color #909399
            font-size 12px

          .event-providers
            display flex
            align-items center

            .cover
              width 14px
              height 14px
              margin-right 2px

            .trademark
              font-size 12px
              color #909399

    .mobile-content
      display none

  @media all and (max-width: 1024px)
    .case-details
      display flex
      margin-top 76px
      align-items end
      position relative
      padding 0 28px
      justify-content space-between
      margin-bottom 28px

      .van-sticky--fixed
        right 28px

      .case-details-left
        /*width 73%*/
        width 64%
        float left
        padding 28px 0
        background #FFFFFF
        padding-right 28px

        .name
          color #303133
          font-size 26px
          margin-bottom 16px
          font-weight bold

        .title
          color #909399
          font-size 14px
          margin-bottom 24px

        .active-cover
          width 456px
          height 267px

        .case-details-content p strong span
          font-weight bold
          font-size 16px
          margin-top 24px
          margin-bottom 16px

        .case-details-content p span
          color #606266
          font-size 14px
          line-height 21px

        .case-details-content p img
          max-width 456px
          object-fit cover

        .title-list
          color #303133
          font-size 16px
          margin-bottom 16px

        .content
          color #606266
          font-size 14px
          margin-bottom 24px

      .case-details-right
        /*width 25%*/
        float right
        width 200px
        background #FFFFFF
        padding 16px 0 8px 16px

        .cover-list
          display flex
          align-items center

          .hot-icon
            width 20px
            height 20px

        .activity-box
          margin-top 12px

          .cover
            width 93%
            border-radius 3px
            height 120px
            object-fit cover

          .name
            width 85%
            padding 0 6px
            color #FFFFFF
            font-size 14px
            background rgba(0, 0, 0, 0.6)
            opacity 1
            border-radius 0px 0px 3px 3px
            height 30px
            line-height 30px
            margin-top -32px
            display -webkit-box
            -webkit-line-clamp 1
            -webkit-box-orient vertical
            overflow hidden

        .more-activity-list
          .more-activity
            display flex
            margin-top 12px

            .cover
              width 64px
              height 64px
              object-fit cover
              margin-right 8px

            .content
              .name
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical
                overflow hidden
                color #303133
                font-size 14px
                height 40px
                line-height 20px
                border-radius 3px
                margin-bottom 8px

              .time
                color #909399
                font-size 12px

        .news-list
          margin-top 12px
          margin-bottom 12px

          .cover-list
            .hot-icon
              margin-right 6px

            .title
              color #303133
              font-size 16px

          .content-box
            margin-top 16px

            .name
              height 41px
              display -webkit-box
              -webkit-line-clamp 2
              -webkit-box-orient vertical
              overflow hidden
              color #303133
              line-height 21px
              margin-bottom 8px
              font-size 14px

            .news-content
              display flex
              justify-content space-between

            .time
              color #909399
              font-size 14px

            .event-providers
              display flex

              .cover
                width 14px
                height 14px
                margin-right 4px

              .trademark
                color #909399
                font-size 12px

  @media all and (min-width: 768px)
    .mobile-content
      display none

  @media all and (max-width: 767px)
    .contents
      padding-top 48px

    .case-details
      display none

    .mobile-content
      padding 16px 16px 24px 16px
      background #FFFFFF

      .title
        color #303133
        font-size 26px
        margin-bottom 12px
        font-weight bold
        line-height 31px

      .active
        color #909399
        font-size 14px
        margin-bottom 24px
        display inline-block

      .label-active
        color #FFFFFF
        font-size 10px
        padding 2px 4px
        background #2988FC
        border-radius 2px
        margin-left 6px

      .label-name
        color #FFFFFF
        font-size 10px
        padding 2px 4px
        background #2988FC
        border-radius 2px
        margin-left 6px

      .cover
        width 100%
        margin-bottom 19px

      .active-content-style p span strong span
        color #303133
        font-size 16px
        font-weight bold
        margin-top 24px
        margin-bottom 16px

      .active-content-style p span
        color #606266
        font-size 14px
        line-height 21px

      .active-content-style p img
        max-width 100%
        object-fit cover

      .name
        color #303133
        font-size 16px
        margin-bottom 16px

      .time
        color #606266
        font-size 12px
        margin-bottom 24px
</style>
