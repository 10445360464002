import axios from 'axios'
// 将axios 封装为一个组件，全局使用
const myaxios = {}
// 添加一个方法
myaxios.install = function (vue) {
  //   统一挂载请求路径
  axios.defaults.baseURL = 'https://xiwangos.xiaoyuanmax.com/index.php/api/'
  // axios.defaults.baseURL = '/api'
  axios.defaults.headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
  // 添加请求拦截器
  axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  })
  vue.prototype.$http = axios
}
export default myaxios
