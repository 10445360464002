<template>
  <div id="resourceEntry">
    <!--    ipad pc-->
    <div class="entry-box">
      <div class="image-box">
        <div class="image-box-left">
          <img class="demand1-icon" src="../assets/images/demand1-icon.png" alt="">
          <img class="ic-icon" src="../assets/images/ic-icon.png" alt="">
          <img class="left-circular" src="../assets/images/left-circular.png" alt="">
        </div>
        <img class="right-circular" src="../assets/images/right-circular.png" alt="">
      </div>
      <div class="entry-content-box">
        <p class="title-name">资源入驻</p>
        <div class="apply-list-box">
          <p class="content" id="active">Step 1 入驻申请</p>
          <p class="lines"></p>
          <p class="content" id="active-examine">Step 2 资质审核</p>
          <p class="lines"></p>
          <p class="content">Step 3 达成合作</p>
        </div>
        <!--        入驻申请模块-->
        <div class="choice" v-show="selectTips">
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>渠道类型：</div>
            <div class="model">
              <p :class="advert_type == 1? 'active type': 'type'" @click="changeType(1)">广告传媒公司</p>
              <p :class="advert_type == 2? 'active type': 'type'" @click="changeType(2)">校园官方</p>
              <p :class="advert_type == 3? 'active type': 'type'" @click="changeType(3)">社团组织</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>资源类型：</div>
            <div class="model-list">
              <el-cascader placeholder="请选择资源类型" :options="options" @change="handleChange" collapse-tags
                           clearable>
              </el-cascader>
              <p v-show="resourceType" class="tips-active">请选择资源类型</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>覆盖城市：</div>
            <div class="model-list" style="cursor: pointer">
<!--              @hide="setAddressData" @show="haveAddressData"-->
              <el-popover placement="bottom" trigger="focus" >
                <el-tree class="address-tree" style="overflow: auto; height:340px" :data="cityTreeData" show-checkbox
                         ref="tree" node-key="value" @check="onCitiesTreeCheckChange"
                         :props="defaultProps"></el-tree>
                <!--                v-model="treeCities.length === 0 ? '' : (treeCities.length > 4 ? '已选择'+ lableName + '等' + treeCities.length + '个城市' : '已选择' + lableName)"-->
<!--                <el-input slot="reference"-->
<!--                          v-model="test"-->
<!--                          ref="cityPlaceholder" readonly-->
<!--                          :placeholder="'请选择城市'" size="mini">-->
<!--                </el-input>-->
                <el-input slot="reference" v-model="addressVal" ref="cityPlaceholder" readonly
                          :placeholder="checkedCities.length == 0 ? '请选择城市': '已选择'+ checkedCities.slice(0, 3) +''+ checkedCities.length +'个城市'" size="mini">
                </el-input>
              </el-popover>
              <p v-show="city" class="tips-active">请选择覆盖城市</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>联系人：</div>
            <div class="model-list">
              <input class="type-name" type="text" v-model="name" placeholder="请输入姓名">
              <p v-show="nameTip" class="tips-active">请填写联系人</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>联系电话：</div>
            <div class="model-list">
              <input class="type-name" type="text" v-model="telephone" maxlength="11" placeholder="请输入手机号码">
              <p v-show="tel" class="tips-active">请输入正确手机号码</p>
            </div>
          </div>
          <div class="submit-button" @click="onSubmit">
            <p class="model-btn">提交申请</p>
          </div>
        </div>
        <!--        资质审核中模块-->
        <div class="to-examine" v-show="successTips">
          <p class="hint">入驻申请提交成功</p>
          <p class="explain">我们的工作人员将会在1~3个工作日内对您进行回访 届时请保持所预留的手机号通讯畅通，感谢配合</p>
          <div class="go-index-box" @click="goIndex">
            <p class="btn-index">返回首页</p>
          </div>
        </div>
      </div>
    </div>
    <!--    移动端-->
    <div class="move-entry-box">
      <div class="image-box">
        <div class="image-box-left">
          <img class="demand1-icon" src="../assets/images/demand1-icon.png" alt="">
          <img class="left-circular" src="../assets/images/left-circular.png" alt="">
        </div>
        <img class="right-circular" src="../assets/images/right-circular.png" alt="">
      </div>
      <div class="move-entry-content-box">
        <p class="title-name">资源入驻</p>
        <div class="move-apply-list-box">
          <div class="time-box" id="styleBox">
            <div class="style">
              <p class="list"></p>
              <p class="axis" id="moveActive"></p>
              <p class="list"></p>
            </div>
            <div class="select">
              <p class="name" id="selectNmae">入驻申请</p>
            </div>
          </div>
          <div class="time-box" id="styleBox2">
            <div class="style">
              <p class="list"></p>
              <p class="axis" id="moveActive2"></p>
              <p class="list"></p>
            </div>
            <div class="select">
              <p class="name" id="selectNmae2">资质审核</p>
            </div>
          </div>
          <div class="time-box">
            <div class="style">
              <p class="list"></p>
              <p class="axis"></p>
              <p class="list"></p>
            </div>
            <div class="select">
              <p class="name">达成合作</p>
            </div>
          </div>
        </div>
        <!--        入驻申请模块-->
        <div class="move-choice-box" v-show="moveSelect">
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>渠道类型：</div>
            <div class="model">
              <el-cascader placeholder="请选择渠道类型" :options="channel" @change="channelChange" collapse-tags
                           clearable>
              </el-cascader>
              <p v-show="channelTips" class="tips-active">请选择渠道类型</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>资源类型：</div>
            <div class="model">
              <el-cascader placeholder="请选择资源类型" :options="options" @change="handleChange" collapse-tags
                           clearable>
              </el-cascader>
              <p v-show="resourceType" class="tips-active">请选择资源类型</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>覆盖城市：</div>
            <div class="model">
              <el-popover placement="bottom" trigger="focus" @hide="setAddressDataMove" @show="haveAddressDataMove">
                <el-tree class="address-tree" style="overflow: auto; height:250px" :data="cityTreeData" show-checkbox
                         ref="trees" node-key="value"
                         :props="defaultProps"></el-tree>
                <el-input slot="reference" v-model="addressVal" ref="cityPlaceholder"

                          :placeholder="treeCities.length == 0 ? '请选择城市': ')已选择'+ treeCities.length +'个城市'" size="mini">
                </el-input>
                <!--                          .slice(0,5-->
              </el-popover>
              <p v-show="city" class="tips-active">请选择覆盖城市</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>联系人：</div>
            <div class="model">
              <input class="type-name" type="text" v-model="name" placeholder="请输入姓名">
              <p v-show="nameTip" class="tips-active">请填写联系人</p>
            </div>
          </div>
          <div class="title-content">
            <div class="typeface"><span class="must-fill">*</span>联系电话：</div>
            <div class="model">
              <input class="type-name" type="text" v-model="telephone" placeholder="请输入手机号码">
              <p v-show="tel" class="tips-active">请输入正确手机号码</p>
            </div>
          </div>
          <div class="submit-button">
            <p class="model-btn" @click="moveOnSubmit">提交申请</p>
          </div>
        </div>
        <!--        资质审核中模块-->
        <div class="to-examine" v-show="moveExamine">
          <p class="hint">入驻申请提交成功</p>
          <p class="explain">我们的工作人员将会在1~3个工作日内对您进行回访 届时请保持所预留的手机号通讯畅通，感谢配合</p>
          <div class="go-index-box" @click="goIndex">
            <p class="btn-index">返回首页</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { addressInfo } from '/src/assets/js/address.js'

  export default {
    data () {
      return {
        // ------移动端------
        channelTips: false, // 渠道类型提示
        moveSelect: true, // 资源入驻
        moveExamine: false, // 资质审核
        channel_type: '', // 渠道类型
        channel: [ // 移动端渠道类型
          {
            value: 1,
            label: '广告传媒公司'
          },
          {
            value: 2,
            label: '校园官方'
          },
          {
            value: 3,
            label: '社团组织'
          }
        ],
        // ------移动端 end  ------
        checkedCities: '',
        // lableName: '',
        arr: '', // 选择城市数组
        props: { multiple: true }, // 提示
        addressVal: '', // 选择的城市
        treeCities: '', // 选择城市的数量
        cityTreeData: addressInfo, // 省市数据
        selectTips: true, // 填写提交申请模块
        successTips: false, // 提交成功后内容
        resourceType: false, // 请选择资源类型
        city: false, // 请选择覆盖城市
        nameTip: false, // 请填写联系人
        tel: false, // 请填写联系电话
        advert_type: '1', // 默认选中渠道类型
        resource_type: '', // 资源类型
        name: '', // 姓名
        telephone: '', // 手机号码
        options: [{
          value: '线上媒体',
          label: '线上媒体',
          children: [{
            value: '微信公众号',
            label: '微信公众号'
          }, {
            value: '微博KOL',
            label: '微博KOL'
          }, {
            value: '微信KOL朋友圈',
            label: '微信KOL朋友圈'
          }, {
            value: 'QQ表白墙',
            label: 'QQ表白墙'
          }, {
            value: 'QQ表白墙',
            label: 'QQ表白墙'
          }, {
            value: 'QQ群聊',
            label: 'QQ群聊'
          }
          ]
        },
          {
            value: '线下媒体',
            label: '线下媒体',
            children: [{
              value: '桌贴',
              label: '桌贴'
            }, {
              value: '操场围栏',
              label: '操场围栏'
            }, {
              value: '框架广告',
              label: '框架广告'
            }]
          },
          {
            value: '资源场地',
            label: '资源场地',
            children: [{
              value: '室内路演场地',
              label: '室内路演场地'
            },
              {
                value: '室外路演场地',
                label: '室外路演场地'
              }
            ]
          }
        ],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      }
    },
    methods: {
      onCitiesTreeCheckChange: function (e, checkedNodes) {
        const checkedCities = []
        checkedNodes.checkedNodes.forEach(element => {
          checkedCities.push(element.label)
        })
        this.checkedCities = checkedCities
      },
      test () {
        if(this.treeCities.length  === 0) {
          return ''
        }
      },
      // -------------------------  移动端 ----------------------
      // 移动端城市选择
      setAddressDataMove () {
        this.treeCities = this.$refs.trees.getCheckedNodes()
        let arr = []
        for (const item of this.$refs.trees.getCheckedNodes()) {
          if (item.children) continue
          arr.push(item.label)
        }
        this.treeCities = [...new Set(arr)]
        arr = arr.join(',')
        this.arr = arr
      },
      haveAddressDataMove () {
        if (this.cleanFlag) {
          this.$refs.trees.setCheckedKeys([])
          this.cleanFlag = false
        }
      },
      // 渠道类型
      channelChange (value) {
        var value, arr
        value = new Array(value)
        arr = value.join(',')
        this.channel_type = arr
      },
      // 移动端提交
      moveOnSubmit () {
        if (this.channel_type === '') { // 渠道类型
          this.channelTips = !this.channelTips
          return false
        }
        if (this.resource_type === '') { // 资源类型
          this.resourceType = !this.resourceType
          return false
        }
        if (this.arr === '') { // 覆盖城市
          this.city = !this.city
          return false
        }
        if (this.name === '') { // 姓名
          this.nameTip = !this.nameTip
          return false
        }
        if (!this.checkTel(this.telephone)) { // 手机号
          this.tel = !this.tel
          return false
        } else {
          const formData = new FormData()
          formData.append('channel_type', this.channel_type) // 渠道类型
          formData.append('resource_type', this.resource_type) // 资源类型
          formData.append('covering_city', this.arr) // 选择的城市
          formData.append('contacts', this.name) // 填写的姓名
          formData.append('telephone', this.telephone) // 填写的电话
          this.$http.request({
            url: 'Datacenter/actionResourceallocationAddApi',
            method: 'POST',
            data: formData
          }).then((res) => {
            const code = res.data.code
            if (code === 200) {
              this.moveExamine = true
              this.moveSelect = false
              document.getElementById('moveActive').style.background = '#c0c4cc'
              document.getElementById('moveActive').style.border = 'none'
              document.getElementById('selectNmae').style.color = '#C0C4CC'
              document.getElementById('styleBox').style.marginTop = '-1px'

              document.getElementById('moveActive2').style.background = '#2988fc'
              document.getElementById('moveActive2').style.border = '3px solid #a6ccf'
              document.getElementById('moveActive2').style.width = '15px'
              document.getElementById('moveActive2').style.height = '15px'
              document.getElementById('selectNmae2').style.color = '#2988FC'
              document.getElementById('styleBox2').style.marginTop = '-1px'
            } else {
            }
          })
        }
      },
      // -------------------------  移动端 end ----------------------
      // 渠道类型
      changeType (type) {
        this.advert_type = type
      },
      // 资源类型
      handleChange (value) {
        var value, arr
        value = new Array(value)
        arr = value.join(',')
        this.resource_type = arr
      },
      // 校验手机号码
      checkTel (telephone) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        return reg.test(telephone)
      },
      // 返回到首页
      goIndex () {
        this.$router.push({
          path: '/index'
        }).then()
      },
      // pc，ipad提交
      onSubmit () {
        if (this.resource_type === '') { // 资源类型
          this.resourceType = !this.resourceType
          return false
        }
        if (this.arr === '') { // 覆盖城市
          this.city = !this.city
          return false
        }
        if (this.name === '') { // 姓名
          this.nameTip = !this.nameTip
          return false
        }
        if (!this.checkTel(this.telephone)) { // 手机号
          this.tel = !this.tel
          return false
        } else {
          const formData = new FormData()
          formData.append('channel_type', this.advert_type) // 渠道类型
          formData.append('resource_type', this.resource_type) // 资源类型
          formData.append('covering_city', this.arr) // 选择的城市
          formData.append('contacts', this.name) // 填写的姓名
          formData.append('telephone', this.telephone) // 填写的电话
          this.$http.request({
            url: 'Datacenter/actionResourceallocationAddApi',
            method: 'POST',
            data: formData
          }).then((res) => {
            const code = res.data.code
            if (code === 200) {
              this.successTips = true
              this.selectTips = false
              document.getElementById('active-examine').style.background = '#2988FC'
              document.getElementById('active-examine').style.color = '#FFFFFF'
              document.getElementById('active-examine').style.border = 'none'

              document.getElementById('active').style.background = '#FFFFFF'
              document.getElementById('active').style.color = '#303133'
              document.getElementById('active').style.border = '1px solid #B4D6FF'
            } else {
            }
          })
        }
      }
    },
    mounted () {
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @import '../assets/font/font.css';
  @media all and (min-width: 1025px)
    #resourceEntry
      padding-top 54px

      .entry-box
        width 1320px
        margin auto

        .image-box
          width 1320px
          display flex
          position absolute

          .image-box-left
            display flex

            .demand1-icon
              width 289px
              height 466px

            .ic-icon
              width 93px
              height 93px
              margin-left -166px

            .left-circular
              width 266px
              height 266px
              position absolute
              top 280px
              left 124px

          .right-circular
            width 298px
            height 298px
            position absolute
            right 0

        .entry-content-box
          width 880px
          background #FFFFFF
          box-shadow 0px 3px 24px rgba(4, 30, 103, 0.1)
          opacity 1
          border-radius 12px
          margin auto
          margin-top 32px
          position relative
          margin-bottom 67px

          .title-name
            color #303133
            font-size 28px
            font-weight bold
            padding 48px 0 24px 0
            text-align center

          .apply-list-box
            display flex
            align-items center
            justify-content center
            margin-bottom 32px

            .content
              color #303133
              font-size 14px
              text-align center
              width 155px
              height 46px
              line-height 46px
              background #FFFFFF
              border 1px solid #B4D6FF
              opacity 1
              border-radius 23px
              cursor pointer

            #active
              background #2988FC
              color #FFFFFF

            .lines
              width 89px
              height 0px
              border 1px solid #B4D6FF
              opacity 1

          .choice
            .title-content
              display flex
              align-items baseline
              margin-bottom 16px
              justify-content center

              .typeface
                color #303133
                font-size 14px
                width 86px
                margin-right 16px
                text-align right

                .must-fill
                  color #FF4E00
                  margin-right 2px

              .model
                display flex
                align-items center

                .tips-active
                  color #FF0000
                  font-size 14px
                  margin-top 10px
                  padding-left 4px

                .type
                  border 1px solid #C0C4CC
                  opacity 1
                  border-radius 16px
                  padding 6px 16px
                  line-height 20px
                  color #303133
                  font-size 14px
                  margin-right 12px
                  cursor pointer

                .active
                  background #2988FC
                  color #FFFFFF
                  border 0 solid #C0C4CC

              .model-list
                align-items center

                .tips-active
                  color #FF0000
                  font-size 14px
                  margin-top 10px
                  padding-left 4px

                .type
                  border 1px solid #C0C4CC
                  opacity 1
                  border-radius 16px
                  padding 6px 16px
                  line-height 20px
                  color #303133
                  font-size 14px
                  margin-right 12px
                  cursor pointer

                .active
                  background #2988FC
                  color #FFFFFF
                  border 0 solid #C0C4CC

                .type-name
                  font-size 14px
                  width 276px
                  height 32px
                  background #FFFFFF
                  border 1px solid #F0F2F5
                  opacity 1
                  padding-left 12px
                  border-radius 2px

              .el-input__inner
                font-size 14px
                width 290px
                height 34px
                background #FFFFFF
                border 1px solid #F0F2F5
                opacity 1
                border-radius 2px
                cursor pointer

              .el-cascader-panel
                height 150px

            .submit-button
              padding-top 16px
              padding-bottom 48px

              .model-btn
                font-weight bold
                color #FFFFFF
                font-size 20px
                width 200px
                height 46px
                line-height 46px
                text-align center
                background #2988FC
                opacity 1
                cursor pointer
                border-radius 4px
                margin-top 16px
                margin auto

          .to-examine
            .hint
              padding 43px 0 24px 0
              color #303133
              font-size 18px
              text-align center

            .explain
              padding 0 260px
              text-align center
              color #303133
              font-size 14px
              line-height 18px

            .go-index-box
              margin-top 127px
              padding-bottom 48px

              .btn-index
                margin auto
                color #FFFFFF
                font-size 20px
                font-weight bold
                width 200px
                height 46px
                line-height 46px
                text-align center
                background #2988FC
                opacity 1
                cursor pointer
                border-radius 4px

    .el-cascader-menu
      height 120px

  @media all and (max-width: 1024px)
    #resourceEntry
      .entry-box
        padding-top 62px

        .image-box
          display flex
          position absolute
          width 100%

          .image-box-left
            display flex
            padding-top 62px

            .demand1-icon
              width 90px
              height 254px
              margin-top 300px

            .ic-icon
              width 20px
              height 20px

            .left-circular
              width 145px
              height 145px

          .right-circular
            width 298px
            height 298px
            position absolute
            right 0

        .entry-content-box
          position relative
          margin 60px 28px 90px 28px
          background #FFFFFF
          box-shadow 0px 3px 24px rgba(4, 30, 103, 0.1)
          opacity 1
          border-radius 12px

          .title-name
            padding 48px 0 24px 0
            color #303133
            font-size 28px
            font-weight bold
            text-align center

          .apply-list-box
            display flex
            justify-content center
            align-items center
            margin-bottom 32px

            .content
              color #303133
              font-size 14px
              width 155px
              height 46px
              line-height 46px
              text-align center
              background #FFFFFF
              border 1px solid #B4D6FF
              opacity 1
              border-radius 23px

            #active
              background #2988FC
              color #FFFFFF

            .lines
              width 89px
              height 0px
              border 1px solid #B4D6FF
              opacity 1

          .choice
            .title-content
              display flex
              align-items baseline
              margin-bottom 16px
              justify-content center

              .typeface
                color #303133
                font-size 14px
                padding-right 16px
                width 86px
                text-align right

                .must-fill
                  color #FF4E00
                  font-size 14px
                  margin-right 4px

              .model
                display flex

                .type
                  color #303133
                  font-size 14px
                  padding 8px 16px
                  background #FFFFFF
                  border 1px solid #C0C4CC
                  opacity 1
                  border-radius 16px
                  margin-right 12px

                .active
                  color #FFFFFF
                  background #2988FC

                .type-name
                  font-size 14px
                  padding-left 12px
                  width 276px
                  height 32px
                  line-height 32px
                  background #FFFFFF
                  border 1px solid #F0F2F5
                  opacity 1
                  border-radius 2px

              .model-list
                align-items center

                .tips-active
                  color #FF0000
                  font-size 14px
                  margin-top 10px
                  padding-left 4px

                .type
                  color #303133
                  font-size 14px
                  padding 8px 16px
                  background #FFFFFF
                  border 1px solid #C0C4CC
                  opacity 1
                  border-radius 16px
                  margin-right 12px

                .active
                  color #FFFFFF
                  background #2988FC

                .type-name
                  font-size 14px
                  padding-left 12px
                  width 264px
                  height 32px
                  line-height 32px
                  background #FFFFFF
                  border 1px solid #F0F2F5
                  opacity 1
                  border-radius 2px

                .el-input__inner
                  font-size 14px
                  padding-left 12px
                  width 276px
                  height 32px
                  line-height 32px
                  background #FFFFFF
                  border 1px solid #F0F2F5
                  opacity 1
                  border-radius 2px

            .submit-button
              padding 16px 0 48px 0

              .model-btn
                color #FFFFFF
                font-size 20px
                font-weight bold
                width 200px
                height 46px
                line-height 46px
                text-align center
                background #2988FC
                opacity 1
                border-radius 4px
                margin auto

          .to-examine
            .hint
              padding 43px 0 24px 0
              text-align center
              color #303133
              font-size 18px

            .explain
              color #303133
              font-size 14px
              line-height 18px
              padding 0 150px
              text-align center

            .go-index-box
              padding 127px 0 48px 0

              .btn-index
                margin auto
                color #FFFFFF
                font-size 20px
                width 200px
                height 46px
                line-height 46px
                text-align center
                background #2988FC
                opacity 1
                border-radius 4px

  @media all and (min-width: 768px)
    #resourceEntry
      .move-entry-box
        display none

    .el-cascader-menu
      height 120px

  @media all and (max-width: 767px)
    #resourceEntry
      .entry-box
        display none

      .move-entry-box
        padding-top 40px

        .image-box
          display flex
          position absolute
          width 100%

          .image-box-left
            display flex

            .demand1-icon
              width 85px
              height 254px
              margin-top 244px

            .left-circular
              width 145px
              height 145px
              margin-top 352px
              margin-left -60px

          .right-circular
            position absolute
            right 0
            width 151px
            height 151px

        .move-entry-content-box
          position relative
          margin 16px 16px 32px 16px
          background #FFFFFF
          box-shadow 0px 3px 24px rgba(4, 30, 103, 0.1)
          opacity 1
          border-radius 12px

          .title-name
            font-size 24px
            font-weight bold
            text-align center
            padding 32px 0
            color #303133

          .move-apply-list-box
            display flex
            justify-content center
            margin-bottom 24px

            .time-box
              .style
                display flex
                align-items center

                .list
                  width 48px
                  height 1px
                  background #dde0e3
                  opacity 1

                .axis
                  width 13px
                  height 13px
                  background #C0C4CC
                  border-radius 50%
                  opacity 1

                #moveActive
                  width 15px
                  height 15px
                  background #2988FC
                  border-radius 50%
                  border 3px solid #A6CCFB
                  display inline-block

              .select
                margin-top 10px
                text-align center

                .name
                  color #C0C4CC
                  font-size 12px

                #selectNmae
                  color #2988FC

            #styleBox
              margin-top -4px

          .move-choice-box
            .title-content
              display flex
              align-items baseline
              margin-bottom 16px
              justify-content center

              .typeface
                color #303133
                font-size 14px
                padding-right 16px
                width 86px
                text-align right

                .must-fill
                  color #ff4e00
                  font-size 14px
                  margin-right 4px

              .model
                .tips-active
                  color #FF0000
                  font-size 14px
                  margin-left 6px

                .el-input__inner
                  font-size 14px
                  padding-left 12px
                  width 220px
                  height 32px
                  line-height 32px
                  background #fff
                  border 1px solid #f0f2f5
                  opacity 1
                  border-radius 2px

                .type-name
                  font-size 14px
                  width 208px
                  height 32px
                  line-height 32px
                  background #FFFFFF
                  border 1px solid #F0F2F5;
                  opacity 1
                  padding-left 12px
                  border-radius 2px

            .submit-button
              padding 16px 0 32px 0

              .model-btn
                color #FFFFFF
                font-size 20px
                font-weight bold
                width 200px
                height 46px
                line-height 46px
                text-align center
                background #2988FC
                opacity 1
                margin auto
                border-radius 4px

          .to-examine
            .hint
              padding 40px 0 24px 0
              text-align center
              color #303133
              font-weight 500
              font-size 18px

            .explain
              padding 0 14px
              font-size 14px
              line-height 20px
              text-align center
              color #303133
              margin-bottom 130px

            .go-index-box
              padding-bottom 32px

              .btn-index
                margin auto
                color #FFFFFF
                font-size 20px
                width 200px
                height 46px
                line-height 46px
                text-align center
                background #2988FC
                opacity 1
                border-radius 4px

    .el-cascader-menu
      height 120px
</style>
