<template>
  <div id="tip">
    <div class="tip-background" v-show="show" @click="submit($event)">
      <img class="tips-icon" src="../assets/images/tips-icon.png" alt="">
      <div>
        <div class="content-box" ref="msk">
          <div class="content-title">
            <div class="introduce">
              <p class="words">留下您的联系方式，我们将尽快联系您</p>
              <p class="phone">或者您也可以拨打 <span class="phone-number">010-8170 8868 </span> 联系我们</p>
            </div>
            <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
          </div>
          <div class="input-box">
            <p class="title"><span class="required">*</span>联系人</p>
            <input class="input-frame" type="name" placeholder="请输入姓名" v-model="name">
            <p v-show="isName" class="prompt">联系人不能为空</p>
          </div>
          <div class="input-box">
            <p class="title"><span class="required">*</span>手机号</p>
            <input class="input-frame" @input="changeUserName" type="tel" placeholder="请输入手机号" v-model="tel"
                   maxlength="11">
            <p v-show="istel" class="prompt">请输入正确手机号</p>
          </div>
          <div class="input-box">
            <p class="title">公司名称</p>
            <input class="input-frame" type="text" placeholder="请输入公司名称" v-model="companyName">
          </div>
          <div class="input-box">
            <p class="title">公司产品/业务</p>
            <input class="input-frame" type="text" placeholder="请输入公司产品/业务" v-model="business">
          </div>
          <div>
            <p class="submit-btn" id="submitBtn" @click="onSubmit">提交</p>
          </div>
        </div>
        <img class="close-btn" src="../assets/images/close-btn.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        isName: false, // 联系人不能为空
        istel: false, // 手机号不能为空
        name: '', // 联系人
        tel: '', // 电话号
        companyName: '', // 公司名称
        business: '' // 公司产品业务
      }
    },
    methods: {
      changeUserName (e) {
        const { value } = e.target
        if (value) {
          document.getElementById('submitBtn').style.color = '#2988FC'
          document.getElementById('submitBtn').style.border = '1px solid #2988FC'
        } else {
          document.getElementById('submitBtn').style.color = '#C0C4CC'
          document.getElementById('submitBtn').style.border = '1px solid #C0C4CC'
        }
      },
      submit (ev) {
        if (!this.$refs.msk.contains(ev.target)) {
          this.default = false
          this.$emit('submit')
        }
      },
      // 校验手机号码
      checkTel (tel) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        return reg.test(tel)
      },
      onSubmit () {
        if (this.name === '') {
          this.isName = !this.isName
          return false
        } else if (!this.checkTel(this.tel)) {
          this.istel = !this.istel
          return false
        } else {
          const formData = new FormData()
          formData.append('truename', this.name)
          formData.append('telephone', this.tel)
          formData.append('company', this.companyName)
          formData.append('business', this.business)
          this.$http.request({
            url: 'Home/actionContactUsApi',
            method: 'POST',
            data: formData
          }).then((res) => {
            const code = res.data.code
            if (code === 200) {
              this.show = false
              this.$emit('submit')
              this.name = []
              this.tel = []
              this.companyName = []
              this.business = []
            } else {
            }
          })
        }
      }
    },
    mounted () {
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #tip
      .tip-background
        position fixed
        top 0
        left 0
        z-index 2001
        width 100%
        height 100%
        background rgba(0, 11, 25, 0.4)

        .tips-icon
          position fixed
          top 2%
          left 33%
          width 164px
          height 656px

        .content-box
          width 492px
          border-radius 12px
          background #ffffff
          position relative
          margin-top  2%
          left 50%
          transform translateX(-50%)
          cursor pointer
          padding 48px 0 48px 60px

          .content-title
            display flex
            justify-content space-between

            .introduce
              .words
                color #606266
                font-size 24px
                font-weight bold
                height 33px
                line-height 33px

              .phone
                color #606266
                font-size 12px
                height 25px
                line-height 25px
                display flex
                align-items center

                .phone-number
                  margin 0 2px
                  color #606266
                  font-size 18px
                  font-weight 600

            .tips-flow
              height 88px
              width 84px

          .input-box
            .title
              color #606266
              font-size 16px
              margin-bottom 8px
              margin-top 16px

              .required
                color #FF0000

            .input-frame
              padding-left 12px
              width 420px
              height 42px
              background #FFFFFF
              border 1px solid #909399
              opacity 1
              border-radius 2px

            .prompt
              color #FF0000
              font-size 14px
              margin-top 6px

          .submit-btn
            color #C0C4CC
            font-size 20px
            width 432px
            height 48px
            line-height 48px
            text-align center
            border 1px solid #C0C4CC
            opacity 1
            border-radius 2px
            margin-top 32px
            cursor pointer

        .close-btn
          width 37px
          height 37px
          margin-top 32px
          cursor pointer
          position relative
          left 50%
          transform translateX(-50%)
          cursor pointer

  @media all and (max-width: 1024px)
    #tip
      .tip-background
        position fixed
        top 0
        left 0
        z-index 2001
        width 100%
        height 100%
        background rgba(0, 11, 25, 0.4)
        display block
        text-align center

        .tips-icon
          display none

        .content-box
          display inline-block
          background #ffffff
          border-radius 12px
          margin-top 8%
          padding 32px
          text-align left

          .content-title
            .introduce
              .words
                color #606266
                font-size 18px
                font-weight bold
                margin-bottom 6px

            .tips-flow
              display none

            .phone
              color #606266
              font-size 14px

              .phone-number
                font-weight bold
                margin 0 2px

          .input-box
            .title
              color #606266
              font-size 14px
              margin-bottom 8px
              margin-top 16px

              .required
                color #FF0000
                font-size 14px
                margin-right 2px

            .input-frame
              font-size 14px
              width 294px
              height 40px
              background #FFFFFF
              border 1px solid #909399
              opacity 1
              padding-left 12px
              border-radius 2px

            .prompt
              color #f00
              font-size 14px
              margin-top 6px

          .submit-btn
            color #C0C4CC
            font-size 18px
            margin-top 24px
            width 306px
            height 46px
            line-height 46px
            text-align center
            border 1px solid #C0C4CC
            opacity 1
            border-radius 2px

        .close-btn
          width 24px
          height 24px
          position fixed
          top 57%
          left 49%
          cursor pointer

  @media all and (max-width: 767px)
    #tip
      .tip-background
        .content-box
          padding 32px 18px

        .close-btn
          width 24px
          height 24px
          position fixed
          top 80%
          left 47%
          cursor pointer
</style>
