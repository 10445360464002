<template>
  <div id="case">
    <!--    swiper-->
    <div class="swipers-box">
      <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" ref="swiper"  @change="onChange">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index.id" @mouseover="swipeTapList(index)" @mouseout="swipeTaps(index)">
          <a :href="item.jump_link" target="_blank">
            <img class="banner-cover" :src="item.banner_cover" alt="">
          </a>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="click-cover">
      <p class="click-cover-list" v-for="(item,index) in bannerList" :key="index.id"
         @mouseover="swipeTap(index)" @mouseout="swipeTaps(index)" :class="{active:index==isActive}">
        <img class="see-more" :class="{active:index==isActive}" src="../assets/images/more-icon.png" alt="">
        {{item.banner_title}}</p>
    </div>
    <div class="preIcon">
      <img class="left-page" @click="prevTap()" src="../assets/images/left-page.png" alt="">
      <img class="right-page" @click="nextTap()" src="../assets/images/right-page.png" alt="">
    </div>
    <!--    case-->
    <div class="case-content">
      <!--    case-title-->
      <div class="create">
        <ul class="ulStyle">
          <li :id="flag == 0?'liWidth':''" :class="{action:flag == 0}" class="lis" @click="clickLI(0)">娱乐赛事运营</li>
          <li :id="flag == 1?'liWidth':''" :class="{action:flag == 1}" class="lis" @click="clickLI(1)">活动营销</li>
          <li :id="flag == 2?'liWidth':''" :class="{action:flag == 2}" class="lis" @click="clickLI(2)">媒体投放</li>
          <li :id="flag == 3?'liWidth':''" :class="{action:flag == 3}" class="lis" @click="clickLI(3)">校园大使运营管理</li>
          <li :id="flag == 4?'liWidth':''" :class="{action:flag == 4}" class="lis" @click="clickLI(4)">官方合作活动</li>
          <li class="liStyle style-list" :style="`width:${width}px;transform:translateX(${offsetW}px);`"></li>
        </ul>
      </div>
      <!--    移动端案例title-->
      <div class="moveList">
        <van-sticky :offset-top="40">
        <div class="create-move">
          <ul class="ulStyle">
            <li :id="flagmove == 0?'liWidthmove':''" :class="{action:flagmove == 0}" class="lis" @click="clickLI(0)">
              娱乐赛事运营
            </li>
            <li :id="flagmove == 1?'liWidthmove':''" :class="{action:flagmove == 1}" class="lis" @click="clickLI(1)">
              活动营销
            </li>
            <li :id="flagmove == 2?'liWidthmove':''" :class="{action:flagmove == 2}" class="lis" @click="clickLI(2)">
              媒体投放
            </li>
            <li :id="flagmove == 3?'liWidthmove':''" :class="{action:flagmove == 3}" class="lis" @click="clickLI(3)">
              校园大使运营管理
            </li>
            <li :id="flagmove == 4?'liWidthmove':''" :class="{action:flagmove == 4}" class="lis" @click="clickLI(4)">
              官方合作活动
            </li>
            <li class="liStyle style-list" :style="`width:${widthMOve}px;transform:translateX(${offsetWMove}px);`"></li>
          </ul>
        </div>
        </van-sticky>
      </div>
      <!--   pc,ipad 案例内容区域-->
      <div class="case-list-content">
        <div class="list" v-for="(item,index) in casesList" :key="index.id" @click="goCaseDetails(item.id)">
          <img class="case-cover" :src="item.case_cover" alt="">
          <div class="content">
            <p class="content-title">{{item.case_title}}</p>
            <p class="content-name">{{item.case_introduction}}</p>
            <div class="brand-list">
              <span class="brand">品牌：{{item.sponsor}}</span>
              <span class="time">{{item.holding_time}}</span>
            </div>
          </div>
        </div>
      </div>
      <!--   移动端 案例内容区域-->
      <div class="move-case-content">
        <div class="move-list" v-for="(item,index) in casesList" :key="index.id" @click="goCaseDetails(item.id)">
          <img class="case-cover" :src="item.case_cover" alt="">
          <div class="list">
            <p class="case-title">{{item.case_title}}</p>
            <p class="case-name">{{item.case_introduction}}</p>
            <div class="brand-list">
              <span class="brand">品牌：{{item.sponsor}}</span>
              <span class="time">{{item.holding_time}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        autoplay: 3000,
        isActive: 0,
        width: '',
        offsetW: '',
        flag: 0,
        flagmove: 0,
        widthMOve: '',
        offsetWMove: '',
        case_type: '娱乐赛事运营',
        casesList: [], // 案例
        bannerList: [] // 轮播图
      }
    },
    methods: {
      swipeTapList (index) {
        this.autoplay = 20000
      },
      swipeTap (index) {
        this.$refs.swiper.swipeTo(index)
        this.autoplay = 20000
        this.isActive = index
      },
      swipeTaps() {
        this.autoplay = 3000
      },
      prevTap () {
        this.$refs.swiper.prev()
        this.autoplay = 10000
      },
      nextTap () {
        this.$refs.swiper.next()
        this.autoplay = 10000
      },
      onChange (index) {
        this.isActive = index
      },
      // 点击进行案例更改
      clickLI: function (id) {
        this.flag = id
        this.flagmove = id
        if (this.flag === 0) {
          this.case_type = '娱乐赛事运营'
        }
        if (this.flag === 1) {
          this.case_type = '活动营销'
        }
        if (this.flag === 2) {
          this.case_type = '媒体投放'
        }
        if (this.flag === 3) {
          this.case_type = '校园大使运营管理'
        }
        if (this.flag === 4) {
          this.case_type = '官方合作活动'
        }
        if (this.flagmove === 0) {
          this.case_type = '娱乐赛事运营'
        }
        if (this.flagmove === 1) {
          this.case_type = '活动营销'
        }
        if (this.flagmove === 2) {
          this.case_type = '媒体投放'
        }
        if (this.flagmove === 3) {
          this.case_type = '校园大使运营管理'
        }
        if (this.flagmove === 4) {
          this.case_type = '官方合作活动'
        }
        this.$nextTick(() => {
          this.width = document.getElementById('liWidth').offsetWidth
          this.offsetW = document.getElementById('liWidth').offsetLeft
          this.widthMOve = document.getElementById('liWidthmove').offsetWidth
          this.offsetWMove = document.getElementById('liWidthmove').offsetLeft
        })
        this.caseList()
      },
      // 获取案例
      async caseList () {
        const formData = new FormData()
        formData.append('pageCurrent', '1')
        formData.append('pageSize', '20')
        formData.append('case_type', this.case_type)
        this.$http.request({
          url: 'Cases/actionCasesListApi/',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.casesList = res.data.data.CasesList // 案例列表
          }
        })
      },
      // 获取轮播图
      async caseBannerList () {
        const formData = new FormData()
        this.$http.request({
          url: 'Banner/actionBannerListApi',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.bannerList = res.data.data.BannerList // 轮播图
          }
        })
      },
      // 跳转到案例详情页
      async goCaseDetails (caseId) {
        this.$router.push({
          path: '/CaseDetails',
          query: {
            artist: caseId
          }
        }).then()
      }
    },
    mounted () {
      this.caseList()
      this.caseBannerList()
      this.$nextTick(() => {
        this.width = document.getElementById('liWidth').offsetWidth
        this.offsetW = document.getElementById('liWidth').offsetLeft
        this.widthMOve = document.getElementById('liWidthmove').offsetWidth
        this.offsetWMove = document.getElementById('liWidthmove').offsetLeft
      })
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #case
      .case-content

        .create
          width 100%
          height 100%
          background #ffffff
          padding-top 32px

          .ulStyle
            height 50px
            display flex
            position relative
            margin-left 21%
            z-index 3

            .lis
              list-style none
              line-height 50px
              height 50px
              font-size 16px
              color #C0C4CC
              cursor pointer
              margin 0 3%

            .lis:hover
              color #2988fc

            .liStyle
              list-style none
              height 2px
              background #2988FC
              position absolute
              bottom 0

            .style-list
              transition-duration 0.5s

            .action
              color #2988FC

        .moveList
          display none

        .case-list-content
          width 1320px
          margin 0 auto
          margin-top 32px
          display grid
          grid-template-columns repeat(auto-fill, 432px)
          justify-content space-between
          margin-bottom 16px

          .list
            grid-row-gap 16px
            margin-bottom 16px
            background rgba(255, 255, 255, 1)
            -moz-transition all .3s
            -o-transition all .3s
            -webkit-transition all .3s
            transition all .3s
            cursor pointer
            border-radius 0px 0px 3px 3px

            .case-cover
              width 432px
              height 243px
              border-radius 3px 3px 0px 0px
              object-fit cover
              cursor pointer
              vertical-align middle

            .content
              width 400px
              background #ffffff
              padding 16px
              border-top 0px
              border-radius 0px 0px 3px 3px

              .content-title
                color #303133
                font-size 18px
                margin-bottom 8px
                font-weight bold

              .content-name
                font-size 14px
                color #606266
                line-height 20px
                margin-bottom 10px
                height 40px
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical
                overflow hidden

              .brand-list
                display flex
                justify-content space-between

                .brand
                  color #C0C4CC
                  font-size 12px

                .time
                  font-size 12px
                  color #C0C4CC
                  text-align right

          .list:hover
            box-shadow 0 5px 20px 0 rgba(0, 0, 0, .19)
            transform translate3d(0, -5px, 0)
            transition all .3s cubic-bezier(.55, 0, .1, 1)
            border-radius 0px 0px 3px 3px

        .move-case-content
          display none

      .van-swipe__indicators
        display none

      .swipers-box
        .my-swipe
          padding-top 60px

          .banner-cover
            width 100%
            height 500px
            object-fit cover

      .swipers-box:hover + .preIcon
        opacity 1

      .click-cover
        height 30px
        line-height 30px
        background rgba(48, 49, 51, 0.2)
        display flex
        color #909399
        justify-content space-around
        position absolute
        margin-top -32px
        width 100%
        opacity 0.8
        transition all 0.9s linear

        .active
          color #E3AD13
          opacity 1

        .active .see-more
          opacity 1
        .click-cover-list
          cursor pointer
        .click-cover-list:hover
          color #E3AD13
          opacity 1

        .see-more
          width 6px
          height 7px
          opacity 0
          margin-right 4px
          margin-bottom 2px

      .preIcon
        display flex
        justify-content space-between
        position relative
        margin 0 120px
        cursor pointer
        opacity 0.6
        transition all 0.9s linear

        .left-page
          width 48px
          height 64px
          margin-top -282px

        .right-page
          width 48px
          height 64px
          margin-top -282px

  @media all and (max-width: 1024px)
    #case
      .case-content

        .create
          width 100%
          height 100%
          background #ffffff
          padding-top 32px

          .ulStyle
            height 50px
            display flex
            position relative
            margin-left 4%
            z-index 3

            .lis
              list-style none
              line-height 50px
              height 50px
              font-size 16px
              color #C0C4CC
              cursor pointer
              margin: 0 3%;

            .liStyle
              list-style none
              height 2px
              background #2988FC
              position absolute
              bottom 0

            .style-list
              transition-duration 0.5s

            .action
              color #2988FC

        .case-list-content
          margin 0 28px
          margin-top 24px
          display grid
          grid-template-columns repeat(auto-fill, 32%)
          justify-content space-between

          .list
            grid-row-gap 16px
            margin-bottom 16px
            width: 100%;
            box-shadow 0px 2px 10px rgba(1, 20, 44, 0.1)

            .case-cover
              height 55%
              width: 100%;
              border-radius 3px 3px 0px 0px
              object-fit cover
              cursor pointer
              vertical-align middle

            .content
              background #ffffff
              padding 16px
              border 1px solid #F0F2F5
              border-top 0px
              border-radius 0px 0px 3px 3px

              .content-title
                color #303133
                font-size 18px
                margin-bottom 8px
                font-weight bold
                display -webkit-box
                -webkit-line-clamp 1
                -webkit-box-orient vertical
                overflow hidden

              .content-name
                font-size 14px
                color #606266
                line-height 20px
                margin-bottom 10px
                height 40px
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical
                overflow hidden

              .brand-list
                display flex
                justify-content space-between

                .brand
                  color #C0C4CC
                  font-size 12px

                .time
                  font-size 12px
                  color #C0C4CC
                  text-align right

      .van-swipe__indicators
        display none

      .my-swipe
        padding-top 54px

        .banner-cover
          width 100%
          height 400px
          object-fit cover

      .click-cover
        height 30px
        line-height 30px
        background rgba(48, 49, 51, 0.2)
        opacity 1
        display flex
        color #909399
        justify-content space-around
        position absolute
        margin-top -32px
        width 100%
        opacity 0.8
        transition all 0.9s linear

        .active
          color #E3AD13

        .active .see-more
          opacity 1

        .click-cover-list:hover
          color #E3AD13
          opacity 1

        .see-more
          width 6px
          height 7px
          opacity 0
          margin-right 4px
          margin-bottom 2px

  @media all and (min-width: 768px)
    .move-case-content
      display none

    .van-swipe__indicators
      display none

    .preIcon
      display none

    .moveList
      display none

  @media all and (max-width: 767px)
    #case
      .case-content
        margin 0

        .create
          display none

        .create-move
          padding-top 15px
          overflow auto
          background #ffffff

        .create-move::-webkit-scrollbar
          display none

        .ulStyle
          height 44px
          display inline-flex
          position relative
          z-index 3
          white-space nowrap
          margin-left 0px

          .lis
            list-style none
            line-height 44px
            height 44px
            font-size 16px
            color #C0C4CC
            cursor pointer
            margin: 0 16px

          .liStyle
            list-style none
            height 2px
            background #2988FC
            position absolute
            bottom 0

          .style-list
            transition-duration 0.5s

          .action
            color #2988FC

        .create::-webkit-scrollbar
          display none

        .case-list-content
          display none

        .move-case-content
          padding 16px 18px 18px 16px

          .move-list
            margin-bottom 16px
            box-shadow 2px 2px 10px rgba(1, 20, 44, 0.1)

            .case-cover
              object-fit cover
              width 100%
              height 192px
              border-radius 3px 3px 0px 0px
              vertical-align middle

            .list
              background #FFFFFF
              opacity 1
              border-radius 0px 0px 3px 3px
              padding 12px

              .case-title
                color #303133
                font-size 18px
                line-height 25px
                margin-bottom 6px
                font-weight bold
                display -webkit-box
                -webkit-line-clamp 1
                -webkit-box-orient vertical
                overflow hidden

              .case-name
                color #606266
                font-size 14px
                height 40px
                line-height 20px
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical
                overflow hidden
                margin-bottom 10px

              .brand-list
                display flex
                justify-content space-between
                color #C0C4CC
                font-size 12px

      .van-swipe__indicators
        display flex

      .my-swipe
        padding-top 40px

        .van-swipe__indicator
          width 4px
          height 4px

        .banner-cover
          height 210px
          width 100%
          object-fit cover

      .click-cover
        display none

      .pages-icon
        display none

      .preIcon
        display none
</style>
