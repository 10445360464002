<template>
  <div id="enterpriseDetails">
    <div class="entry-box">
      <div class="image-box">
        <div class="image-box-left">
          <img class="demand1-icon" src="../assets/images/demand1-icon.png" alt="">
          <img class="left-circular" src="../assets/images/left-circular.png" alt="">
        </div>
        <img class="right-circular" src="../assets/images/right-circular.png" alt="">
      </div>
    </div>
    <div class="content-box">
      <div class="detail-box">
        <div class="details-left-box">
          <p class="title">{{sponsorDetails.sponsor_title}}</p>
          <div class="time-box">
            <p class="time">{{sponsorDetails.create_at}}发布</p>
            <div class="weChat-img-box">
              <img class="weChat-img" src="../assets/images/weChat-img.png" alt="">
              <p class="share">分享</p>
            </div>
          </div>
          <div class="vote-applet-box">
            <img class="vote-applet" src="../assets/images/vote-applet.png" alt="">
          </div>
          <div class="news-topping-box">
            <p class="news-state">该合作信息已置顶</p>
            <p class="install-top">我也要置顶</p>
          </div>
          <div class="essential-information-box">
            <p class="title-list">基本信息</p>
            <p class="type">赞助类型： <span class="type-name">{{sponsorDetails.sponsor_type}}</span></p>
            <p class="type">赞助时间： <span class="type-name">{{sponsorDetails.sponsor_start_time}} 至 {{sponsorDetails.sponsor_end_time}}</span>
            </p>
            <p class="type">赞助地点： <span class="type-name">{{city}} </span>
            </p>
            <p class="type">推广目的：
              <span class="type-name" v-if="sponsorDetails.is_cash === '1'">资金赞助</span>
              <span class="type-name" v-if="sponsorDetails.is_material === '1'">物资赞助</span>
              <span class="type-name"
                    v-for="(item,index) in sponsorDetails.promotion_mode"
                    :key="index.id">{{item}}</span>
            </p>
            <p class="type">推广方式： <span class="type-name" v-for="(item,index) in sponsorDetails.promotion_purpose"
                                        :key="index.id">{{item}}</span>
              <span class="type-name" v-if="sponsorDetails.s_province !== ''">{{sponsorDetails.material}}</span>
            </p>
          </div>
          <p class="partition"></p>
          <div class="sponsor-content-box">
            <p class="title-list">赞助方案</p>
            <p class="content">{{sponsorDetails.sponsor_infor}}</p>
          </div>
        </div>
        <div class="details-right-box">
          <p class="publisher-information">发布人信息</p>
          <p class="company-name">{{sponsorDetails.enterprise_name}}</p>
          <p class="title">公司性质：<span class="title-name">{{sponsorDetails.enterprise_nature}}</span></p>
          <p class="title">企业规模：<span class="title-name">{{sponsorDetails.enterprise_scale}}</span></p>
          <p class="title">所属部门：<span class="title-name">{{sponsorDetails.department_name}}</span></p>
          <p class="title">项目联系人：<span class="title-name">{{sponsorDetails.nickname}}</span></p>
          <p class="contact-information" @click="dialogVisible = true">点击查看赞助者联系方式</p>
        </div>
      </div>
    </div>
    <!--    查看赞助者信息弹窗-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%">
      <div class="tip-background">
        <div class="content-box" ref="msk">
          <div class="content-title">
            <div class="introduce">
              <p class="words">留下您的联系方式，我们将尽快联系您</p>
              <p class="phone">或者您也可以拨打 <span class="phone-number">010-8170 8868 </span> 联系我们</p>
            </div>
            <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
          </div>
          <div class="input-box">
            <p class="title"><span class="required">*</span>联系人</p>
            <input class="input-frame" type="name" placeholder="请输入姓名" v-model="name">
            <p v-show="isName" class="prompt">联系人不能为空</p>
          </div>
          <div class="input-box">
            <p class="title"><span class="required">*</span>手机号</p>
            <input class="input-frame" @input="changeUserName" type="tel" placeholder="请输入手机号" v-model="tel"
                   maxlength="11">
            <p v-show="isTel" class="prompt">请输入正确手机号</p>
          </div>
          <div class="input-box">
            <p class="title">公司产品/业务</p>
            <input class="input-frame" type="text" placeholder="请输入公司产品/业务" v-model="business">
          </div>
          <div>
            <p class="submit-btn" id="submitBtn" @click="onSubmit(sponsorDetails.id)">提交</p>
          </div>
        </div>
        <img class="close-btn" src="../assets/images/close-btn.png" alt="">
      </div>
    </el-dialog>
  </div>
</template>
<script>
  const Base64 = require('js-base64').Base64
  export default {
    data () {
      return {
        isName: false, // 联系人不能为空
        isTel: false, // 手机号不能为空
        name: '', // 联系人
        tel: '', // 电话号
        companyName: '', // 公司名称
        business: '', // 公司产品业务
        returnId: '', // 活动或者赞助id
        dialogVisible: false,
        sponsorDetails: '', // 赞助详情
        city: '', // 城市
        promotion_mode: [] // 宣传方式
      }
    },
    methods: {
      // 进入个人中心详情页
      goPersonalCenter () {
        this.$router.push({
          path: '/PersonalCenter'
        }).then()
      },
      // 获取赞助详情
      async enterpriseDetails () {
        this.id = this.$route.query.artist
        const formData = new FormData()
        formData.append('sponsor_id', this.id)
        this.$http.request({
          url: 'Square/actionSponsorInfoApi',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.sponsorDetails = res.data.data // 赞助详情
            this.sponsorDetails.promotion_mode = JSON.parse(this.sponsorDetails.promotion_mode)
            this.sponsorDetails.promotion_purpose = JSON.parse(this.sponsorDetails.promotion_purpose)
            if (this.sponsorDetails.s_province === '全国') {
              this.city = '全国'
            } else if (this.sponsorDetails.s_county === '全国') {
              this.city = '全国'
            } else if (this.sponsorDetails.s_city === '全国') {
              this.city = '全国'
            } else {
              this.city = this.sponsorDetails.s_province + ' ' + this.sponsorDetails.s_city + ' ' + this.sponsorDetails.s_county
            }
          }
        })
      },
      changeUserName (e) {
        const { value } = e.target
        if (value) {
          document.getElementById('submitBtn').style.color = '#2988FC'
          document.getElementById('submitBtn').style.border = '1px solid #2988FC'
        } else {
          document.getElementById('submitBtn').style.color = '#C0C4CC'
          document.getElementById('submitBtn').style.border = '1px solid #C0C4CC'
        }
      },
      // 校验手机号码
      checkTel (tel) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        return reg.test(tel)
      },
      onSubmit (id) {
        this.name =  Base64.encode(this.name)
        this.returnId = id
        if (this.name === '') {
          this.isName = !this.isName
          return false
        } else if (!this.checkTel(this.tel)) {
          this.isTel = !this.isTel
          return false
        } else {
          const formData = new FormData()
          formData.append('nickname',this.name)
          formData.append('return_id', this.returnId) // 活动或者赞助id
          formData.append('telephone', this.tel)
          // formData.append('return_type', this.companyName) // 公司名称
          formData.append('return_type', '企业赞助') // 公司名称
          formData.append('remarks', this.business) // 公司产品业务
          this.$http.request({
            url: 'Sponsor/actionReturnVisitApi',
            method: 'POST',
            data: formData
          }).then((res) => {
            const code = res.data.code
            if (code === 200) {
              this.dialogVisible = false
              this.name = []
              this.tel = []
              this.companyName = []
              this.business = []
            } else {
            }
          })
        }
      }
    },
    mounted () {
      this.enterpriseDetails()
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #enterpriseDetails
      padding-top 54px

      .entry-box
        width 1320px
        margin auto

        .image-box
          width 1320px
          display flex
          position absolute

          .image-box-left
            display flex

            .demand1-icon
              width 157px
              height 254px
              margin-top 230px
              margin-left 40px

            .left-circular
              width 140px
              height 140px
              position absolute
              top 330px
              left 124px

          .right-circular
            width 298px
            height 298px
            position absolute
            right 0
            margin-top 10px

      .content-box
        width 1005px
        margin auto
        position relative

        .header-box
          padding 0 24px
          margin-top 16px
          height 52px
          background #FFFFFF
          opacity 1
          border-radius 3px
          display flex
          align-items center
          justify-content space-between
          margin-bottom 16px

          .header-left
            display flex
            align-items center

            .title
              color #606266
              font-size 14px
              margin-right 12px

            .name
              padding 4px 12px
              background #E9F3FF
              border-radius 2px
              color #2988FC
              line-height 22px
              font-size 10px
              margin-right 8px

          .sign-state
            color #2988FC
            font-size 14px
            cursor pointer

        .detail-box
          display flex
          margin-top 18px

          .details-left-box
            width 605px
            background #FFFFFF
            box-shadow 0px 3px 24px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 8px
            padding 32px
            margin-right 16px
            margin-bottom 32px

            .title
              color #303133
              font-size 26px
              line-height 37px
              font-weight bold

            .time-box
              display flex
              justify-content space-between
              align-items center
              margin 12px 0 16px 0

              .time
                color #A6AAB3
                font-size 14px

              .weChat-img-box
                display flex
                align-items center
                cursor pointer

                .weChat-img
                  width 24px
                  height 24px
                  margin-right 2px

                .share
                  color #303133
                  font-size 12px
                  cursor pointer

            .time-box:hover + .vote-applet-box
              opacity 1

            .vote-applet-box
              position absolute
              margin-left 430px
              opacity 0

            .news-topping-box
              width 100%
              padding 6px 12px
              background #F0F6FF
              opacity 1
              border-radius 4px
              display flex
              justify-content space-between
              align-items center

              .news-state
                color #2988FC
                font-size 12px

              .install-top
                width 76px
                height 25px
                line-height 25px
                text-align center
                background #2988FC
                border 1px solid #2988FC
                opacity 1
                border-radius 4px
                color #FFFFFF
                font-size 12px
                cursor pointer

            .essential-information-box
              .title-list
                color #303133
                font-size 16px
                margin 24px 0 16px 0
                font-weight bold

              .type
                color #909399
                font-size 14px
                margin-right 8px
                margin-bottom 12px
                line-height 24px

                .type-name
                  color #303133
                  font-size 14px
                  margin-right 4px

            .partition
              width 100%
              border-radius 2px
              height 8px
              background #F5F7FA
              opacity 1
              margin-top 12px

            .sponsor-content-box
              .title-list
                color #303133
                font-size 16px
                font-weight bold
                margin 24px 0 16px 0

              .content
                color #303133
                font-size 14px
                line-height 21px
                margin-bottom 16px

          .details-right-box
            width 256px
            background #FFFFFF
            box-shadow 0px 3px 24px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 8px
            margin-bottom 32px
            padding 32px

            .publisher-information
              font-weight bold
              color #303133
              font-size 16px
              margin-bottom 16px

            .company-name
              color #303133
              font-size 14px
              margin-bottom 12px

            .title
              font-size 14px
              margin-right 8px
              margin-bottom 12px
              color #909399
              line-height 24px

              .title-name
                color #303133
                font-size 14px

            .contact-information
              width 100%
              font-size 14px
              background #F0F6FF
              border-radius 4px
              height 36px
              line-height 36px
              text-align center
              color #2988FC
              margin-top 12px
              cursor pointer

      .tip-background
        .tips-icon
          position fixed
          top 2%
          left 33%
          width 164px
          height 656px

        .content-box
          width 492px
          border-radius 12px
          background #ffffff
          position relative
          cursor pointer
          padding-left 60px

          .content-title
            display flex
            justify-content space-between
            margin-top 14px

            .introduce
              .words
                color #606266
                font-size 24px
                font-weight bold
                height 33px
                line-height 33px

              .phone
                color #606266
                font-size 12px
                height 25px
                line-height 25px
                display flex
                align-items center

                .phone-number
                  margin 0 2px
                  color #606266
                  font-size 18px
                  font-weight 600

            .tips-flow
              height 88px
              width 84px

          .input-box
            .title
              color #606266
              font-size 16px
              margin-bottom 8px
              margin-top 16px

              .required
                color #FF0000

            .input-frame
              padding-left 12px
              width 420px
              height 42px
              background #FFFFFF
              border 1px solid #909399
              opacity 1
              border-radius 2px

            .prompt
              color #FF0000
              font-size 14px
              margin-top 6px

          .submit-btn
            color #C0C4CC
            font-size 20px
            width 432px
            height 48px
            line-height 48px
            text-align center
            border 1px solid #C0C4CC
            opacity 1
            border-radius 2px
            margin-top 32px
            cursor pointer

        .close-btn
          width 37px
          height 37px
          margin-top 32px
          cursor pointer
          position relative
          left 50%
          transform translateX(-50%)
          cursor pointer

      .el-dialog__body
        padding 0
</style>
