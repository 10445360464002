<template>
  <div id="dataContent">
    <!--    pc上半部-->
    <div class="top-box">
      <div class="search-box">
        <input class="search-input" @keyup.enter="search" v-model="productName" type="text" placeholder="请输入您想查找的学校名称">
        <p class="search-title" @click="search">搜索</p>
      </div>
      <div class="hot-search-box">
        <p class="title-list">热门搜索：</p>
        <p class="title-list" @change="FilterChange('name')" @click="searchList(item['name'])"
           v-for="(item,index) in searchKey"
           :key="index">{{item.name}}</p>

        <!--        <el-checkbox-group size="medium" v-model="productName" @change="FilterChange('name')" text-color="#2988FC"-->
        <!--                           fill="rgba(41, 136, 252, 0.1)">-->
        <!--          <el-checkbox-button v-for="name in names" :label="name" :key="name" v-cloak>{{name}}</el-checkbox-button>-->
        <!--        </el-checkbox-group>-->


      </div>
      <div class="res-type-box">
        <p class="list" :class="TopType == 1 ? 'active' : ''" @click="changeTopType(1)">线上资源</p>
        <p class="list" :class="TopType == 2 ? 'active' : ''" @click="changeTopType(2)">线下资源</p>
        <p class="list" :class="TopType== 3 ? 'active' : ''" @click=" changeTopType(3)">场地资源</p>
        <p class="list" :class="TopType == 4 ? 'active' : ''" @click="changeTopType(4)">全国高校</p>
      </div>
      <!--      热门城市-->
      <div class="choice-city-box">
        <div class="choice-city">
          <p class="filter-name">热门城市：</p>
          <el-checkbox-group size="medium" v-model="checkedCities" @change="FilterChange('city')" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="city in cities" :label="city" :key="city" v-cloak>{{city}}</el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div class="more-city-box">
          <p class="filter-name">更多城市:</p>
          <!--          <el-popover style="cursor: pointer" placement="bottom" trigger="focus" @hide="setAddressData" @show="haveAddressData">-->
          <el-popover style="cursor: pointer" placement="bottom" trigger="focus">
            <el-tree class="address-tree" style="overflow: auto; height:340px" :data="cityTreeData"
                     ref="tree" show-checkbox node-key="value" @check="onCitiesTreeCheckChange"
                     :props="defaultProps"></el-tree>
            <el-input slot="reference" v-model="addressVal" ref="cityPlaceholder" readonly
                      :placeholder="checkedCities.length === 0 ? '请选择城市': '已选择'+ checkedCities.length +'个城市'"
                      size="mini">
            </el-input>
          </el-popover>
        </div>
      </div>
      <!--      资源类型-->
      <div class="choice-city-box">
        <div class="choice-city" v-if="TopType === 1">
          <p class="filter-name">资源类型：</p>
          <p class="title" :class="online_type === 5? 'active': ''" @click="changeDetailType(5)">微信公众号</p>
          <p class="title" :class="online_type === 6? 'active': ''" @click="changeDetailType(6)">微信朋友圈</p>
          <p class="title" :class="online_type === 1? 'active': ''" @click="changeDetailType(1)">微信社群</p>
          <p class="title" :class="online_type === 2? 'active': ''" @click="changeDetailType(2)">QQ社群</p>
          <p class="title" :class="online_type === 3? 'active': ''" @click="changeDetailType(3)">QQ表白墙</p>
          <p class="title" :class="online_type === 4? 'active': ''" @click="changeDetailType(4)">校园微博</p>
        </div>
        <div class="choice-city" v-if="TopType === 2">
          <p class="filter-name">资源类型：</p>
          <p class="title" :class="online_type === 7? 'active': ''" @click="changeDetailType(7)">桌贴</p>
          <p class="title" :class="online_type === 8? 'active': ''" @click="changeDetailType(8)">操场围栏</p>
          <p class="title" :class="online_type === 9? 'active': ''" @click="changeDetailType(9)">框架广告</p>
        </div>
        <div class="choice-city" v-if="TopType === 3">
          <p class="filter-name">资源类型：</p>
          <p class="title" :class="online_type === 10? 'active': ''" @click="changeDetailType(10)">室内路演场地</p>
          <p class="title" :class="online_type === 11? 'active': ''" @click="changeDetailType(11)">室外路演场地</p>
        </div>
      </div>
      <!--      学校类型-->
      <div class="choice-city-box">
        <div class="choice-city">
          <p class="filter-name">学校类型：</p>
          <el-checkbox-group size="medium" v-model="checkedTypes" @change="FilterChange('type')" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="type in types" :label="type" :key="type" v-cloak>{{type}}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <!--      学校属性-->
      <div class="choice-city-box">
        <div class="choice-city">
          <p class="filter-name">学校属性：</p>
          <el-checkbox-group size="medium" v-model="checkedAttrs" @change="FilterChange('attr')" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="attr in attrs" :label="attr" :key="attr" v-cloak>{{attr}}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <!--      其他条件-->
      <div class="choice-city-box">
        <div class="choice-city">
          <p class="filter-name">其他条件：</p>
          <div class="content-box">
            <el-popover placement="bottom" trigger="click">
              <div class="filter-price-info">
                <el-input v-model="beginSchool" maxlength="8" placeholder="请输入人数" size="mini"></el-input>
                <div class="filter-price-cute">~</div>
                <el-input v-model="endSchool" maxlength="8" placeholder="请输入人数" size="mini"></el-input>
                <div class="filter-price-btn" @click="getSchoolBtn">确定</div>
              </div>
              <span class="filter-price-title" slot="reference">
              学校人数
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            </el-popover>
          </div>
          <div class="content-box">
            <el-popover placement="bottom" trigger="click">
              <div class="filter-price-info">
                <el-input v-model="beginCover" maxlength="8" placeholder="请输入人数" size="mini"></el-input>
                <div class="filter-price-cute">~</div>
                <el-input v-model="endCover" maxlength="8" placeholder="请输入人数" size="mini"></el-input>
                <div class="filter-price-btn" @click="getCoverBtn">确定</div>
              </div>
              <span class="filter-price-title" slot="reference">
              覆盖人数
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            </el-popover>
          </div>
          <div class="content-box">
            <el-popover placement="bottom" trigger="click">
              <div class="filter-price-info">
                <el-input v-model="beginPublish" maxlength="8" placeholder="请输入阅读量" size="mini"></el-input>
                <div class="filter-price-cute">~</div>
                <el-input v-model="endPublish" maxlength="8" placeholder="请输入阅读量" size="mini"></el-input>
                <div class="filter-price-btn" @click="getPublishBtn">确定</div>
              </div>
              <span class="filter-price-title" slot="reference">
              阅读量
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            </el-popover>
          </div>
          <div class="content-box">
            <el-dropdown @command="handleGenderCommand">
            <span class="el-dropdown-link">
              女生比例
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="no">不限</el-dropdown-item>
                <el-dropdown-item command="少于50%">少于50%</el-dropdown-item>
                <el-dropdown-item command="50%-60%">50%-60%</el-dropdown-item>
                <el-dropdown-item command="60%以上">60%以上</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <!--      已选条件-->
      <div class="choice-city-box"
           v-if="checkedCities.length || checkedTypes.length || checkedAttrs.length || commandGender || schoolPeople || coverPeople || readNum || checkedAttrs.length">
        <div class="choice-city">
          <p class="filter-name">已选条件：</p>
          <div class="select-all">
            <div class="list">
              <el-tag :key="index" v-for="(tag, index) in dynamicTags" closable v-if="tag" min-width="120" size="small"
                      @close="handleClose(tag)" :disable-transitions="false">{{tag}}
              </el-tag>
            </div>
            <p class="clean-condition" @click="cleanCondition">清空所有筛选条件</p>
          </div>
        </div>
      </div>
    </div>
    <!--    pc下半部-->
    <div class="table-box">
      <el-table ref="multipleTable" tooltip-effect="dark" lazy @selection-change="handleSelectionChange"
                v-loading="loadingPC"
                @select-all="handleSelectAll"
                :data="tableData" style="width: 100%" :key="toggleIndex">
        <el-table-column type="selection" min-width="55">
        </el-table-column>
        <el-table-column v-if="online_type == 12" prop="logo" label="学校logo" min-width="120">
          <template slot-scope="scope">
            <img class="school-logo" :src="scope.row.logo" alt="">
          </template>
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="university_name" label="学校名称"
                         min-width="180">
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="city_name" label="城市名称" min-width="100">
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="province_name" label="省份" min-width="120">
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="is_211" label="211" min-width="100">
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="is_985" label="985" min-width="100">
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="datacenter_number" sortable label="学校人数"
                         width="120">
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="kind_name" label="属性" min-width="120">
        </el-table-column>

        <el-table-column v-if="online_type == 12" prop="female_rate" sortable label="女生比例"
                         min-width="120">
        </el-table-column>

        <el-table-column v-if="online_type != 10 && online_type != 11 && online_type != 12"
                         prop="datacenter_name"
                         label="资源名称" min-width="300">
        </el-table-column>

        <el-table-column v-if="(online_type == 10 || online_type ==11) && online_type != 12"
                         prop="datacenter_name" label="场地名称" min-width="180">
        </el-table-column>

        <el-table-column v-if="(online_type == 10 || online_type ==11) && online_type != 12"
                         prop="datacenter_number" sortable label="可容纳人数" min-width="180">
        </el-table-column>

        <el-table-column v-if="online_type == 10 && online_type != 12" prop="site_type"
                         label="场地类型" min-width="240">
        </el-table-column>

        <el-table-column v-if="(online_type == 4 || online_type == 5) && online_type != 12"
                         prop="category" label="类别" min-width="220">
        </el-table-column>

        <el-table-column v-if="online_type == 3  && online_type != 12" prop="space_type"
                         label="类型" min-width="200">
        </el-table-column>

        <el-table-column v-if="online_type != 3  && online_type != 12" prop="datacenter_number"
                         label="覆盖人数" sortable min-width="220">
        </el-table-column>

        <el-table-column v-if="online_type == 9  && online_type != 12" prop="location" label="位置"
                         min-width="180">
        </el-table-column>

        <el-table-column v-if="online_type == 8  && online_type != 12" prop="published_number"
                         label="上刊数量" sortable min-width="180">
        </el-table-column>

        <el-table-column v-if="online_type == 7  && online_type != 12"
                         prop="total_point_position" label="点位数量" sortable min-width="380">
        </el-table-column>

        <el-table-column v-if="(online_type == 10 || online_type ==11)  && online_type != 12"
                         prop="area" label="面积(㎡)" min-width="150">
        </el-table-column>

        <el-table-column prop="average_read_num"
                         v-if="(online_type == 3 || online_type == 5)  && online_type != 12" label="平均阅读量" sortable
                         min-width="220">
        </el-table-column>

        <el-table-column prop="average_awesome_num" v-if="online_type == 3  && online_type != 12"
                         label="平均点赞数" sortable min-width="230">
        </el-table-column>

        <el-table-column prop="position" v-if="online_type == 6  && online_type != 12" label="属性"
                         sortable min-width="180">
        </el-table-column>
        <!--        <el-table-column :key="Math.random()" prop="price" label="价格" v-if="online_type != 12" sortable="true"-->
        <!--                         width="200">-->
        <!--        </el-table-column>-->
        <el-table-column label="操作" min-width="160">
          <template slot-scope="scope">
            <span class="see-details" @click="goSeeDetails(scope.row.id,online_type,telephone)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-btn-box">
        <div class="left-btn-box">
          <el-checkbox class="all-select" v-model="checkAll" :indeterminate="isIndeterminate"
                       @change="handleCheckAllChange(tableData)">
            全选
          </el-checkbox>
          <a download="资源数据统计表.xls" class="out-res-btn" @click="outResBtn">导出所选资源表</a>
          <div class="give-case" @click="projectTip">获取定制投放方案</div>
        </div>
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="pageDate.currentPage" :page-sizes="pageDate.pageGroup" :page-size="20"
                         layout="total, sizes, prev, pager, next, jumper" :total="pageDate.totalNumber">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--    移动端，ipad数据中心-->
    <div class="move-content-box">
      <div class="search-box">
        <div class="left-box">
          <img class="search" src="../assets/images/search.png" alt="">
          <input class="input-box" @input="searchSchool" :readonly="!isInputActive" v-model="inputSchool"
                 @click="goSearchSchool" type="text" placeholder="请输入学校名称">
        </div>
        <div class="right-box" @click="isChoiceActive = !isChoiceActive" v-if="!isInputActive">
          <img class="screen" src="../assets/images/screen.png" alt="">
          <p class="search-btn">筛选</p>
        </div>
        <div class="right-box" @click="searchSureSchoolBtn" v-if="isInputActive">
          <p class="search-btn">确定</p>
        </div>
      </div>
      <div class="content-box" v-if="!isChoiceActive && !isInputActive">
        <van-tabs v-model="active" @change="initOrdersList" title-active-color="#4C7BFF" color="#4C7BFF"
                  line-height="2px" line-width="13%">
          <van-tab v-for="(item,index) in medium" :key="index" :title="item.title"
                   @click="initOrdersList(item.onlineType)">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <div class="item-box-out" v-for="(item,index) in moveTableData" :key="index"
                     @click="goDetails(item.id)">
                  <div class="content-list">
                    <img class="content-list-cover" :src="item.banner" alt="">
                    <div class="mask"></div>
                    <div class="item-top-box">
                      <div class="item-top">
                        <div class="school-list">
                          <p class="school-name">{{item.datacenter_name}}</p>
                          <!--                          <p class="attribute">服务号</p>-->
                        </div>
                        <img class="weichat-icon" src="../assets/images/weichat-icon.png" alt="">
                      </div>
                      <p class="cover-number">覆盖人数 {{item.datacenter_number}}</p>
                      <div class="address">
                        <div class="bot-inner">
                          <img class="address-cover" src="../assets/images/address.png" alt="">
                          <p class="name">{{item.university_name}}</p>
                        </div>
                        <p class="price">{{item.price}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
          </van-tab>
        </van-tabs>
      </div>
      <!--      筛选数据-->
      <div class="hot-city" v-if="isChoiceActive">
        <div class="hot-city-box">
          <p class="name-city">热门城市</p>
          <div class="selelct-box">
            <div :class="item.active? 'name active' : 'name'" @click="changeCity(item)" v-for="(item, index) in cityArr"
                 :key="index">{{item.name}}
            </div>
          </div>
        </div>
        <div class="hot-city-box">
          <p class="name-city">类型</p>
          <div class="selelct-box">
            <div :class="item.active? 'name active' : 'name'" @click="changeType(item)" v-for="(item, index) in typeArr"
                 :key="index">{{item.name}}
            </div>
          </div>
        </div>
        <div class="hot-city-box">
          <p class="name-city">属性</p>
          <div class="selelct-box">
            <div :class="item.active? 'name active' : 'name'" @click="changeAttr(item)" v-for="(item, index) in attrArr"
                 :key="index">{{item.name}}
            </div>
          </div>
        </div>
        <div class="hot-city-box">
          <p class="name-city">学校人数</p>
          <div class="selelct-box">
            <div :class="item.active? 'name active' : 'name'" @click="changeSchool(item)"
                 v-for="(item, index) in schoolArr"
                 :key="index">{{item.name}}
            </div>
          </div>
        </div>
        <div class="bottom-btn">
          <p class="clean-btn" @click="cleanItem">清除</p>
          <p class="submit-btn" @click="confirmBtn">确定</p>
        </div>
      </div>
      <!--      关键字查询-->
      <div class="key-search-box" v-if="isInputActive">
        <p class="default-graph" v-if="!searchSchoolArr.length">没有查询到这个学校</p>
        <div class="search-result">
          <p class="list" @click="searchSureSchool(item)" v-for="(item) in searchSchoolArr"
             :key="item.id">{{item.university_name}}</p>
        </div>
      </div>
    </div>
    <!--    获取定制投放方案弹窗组件-->
    <div class="pc-popup">
      <my-tips :show="show" @submit="submit"></my-tips>
    </div>
  </div>
</template>
<script>
  import Tips from '../components/pcTip.vue'
  import { addressInfo } from '/src/assets/js/address.js'
  import qs from 'qs'

  const cityOptions = ['北京', '上海', '广州', '深圳', '杭州', '南京', '武汉', '重庆', '长沙', '成都']
  const typeOptions = ['985', '211', '本科', '专科']
  const citys = ['北京大学', '上海大学', '遵化', '昌平']
  const attrOptions = ['综合类', '理科类', '财经类', '艺术类', '医科类', '师范类', '体育类', '其他']
  export default {
    // 组件
    components: {
      'my-tips': Tips
    },
    data () {
      return {
        loadingPC: true, // 表单加载状态
        toggleIndex: '', // 设置索引默认值
        show: false, // 弹窗隐藏
        readNum: '',
        expandedArr: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        addressVal: '',
        cityTreeData: addressInfo, // 省市数据
        dynamicTags: ['', '', '', '', '', '', ''], // 标签tag数组,
        checkAll: false, // 全选按钮
        isIndeterminate: false, // 底部全选按钮
        allSelect: false,
        telephone: '',
        names: citys, //城市
        cities: cityOptions, // 城市
        types: typeOptions, // 学校类型
        attrs: attrOptions, // 学校属性
        checkedCities: [], // 选择城市
        checkedTypes: [], // 选择学校类型
        checkedAttrs: [], // 选择学校属性
        checkedResources: [], // 资源类型
        productName: '', // 搜索学校名称
        TopType: 1, // 自命名 进行定义 进入页面的资源id
        online_type: 5, // 进入页面自动获取线上资源
        beginSchool: '', // 学校人数开始
        endSchool: '', // 学校人数结束
        beginCover: '', // 覆盖人数开始
        endCover: '', // 覆盖人数结束
        beginPublish: '', // 阅读量人数开始
        endPublish: '', // 阅读量人数结束
        commandGender: '', // 男女比例
        schoolPeople: '', // 学校人数
        coverPeople: '', // 覆盖人数
        tag: '',
        active: 0,
        onlineType: 1,
        tableData: [],
        multipleSelection: [],
        pageDate: {
          pageGroup: [20, 50, 100, 200],
          currentPage: 1,
          pageSize: 20,
          pageCurrent: 1,
          totalNumber: 0
        },
        pageCurrentMove: 1, // 当前页码
        pageSize: 20, // 每页加载最大的条目数量
        loading: false, // 加载状态
        finished: false, // 是否加载完成
        moveTableData: [], // 移动端列表数据
        refreshing: false, // 刷新状态
        isInputActive: false,
        isChoiceActive: false,
        inputSchool: '', // 输入搜索内容
        searchSchoolArr: [], // 获取到的搜索内容
        university_name: '', // 搜索学校名称
        city: '', // 热门城市
        type: '', // 类型
        kind_name: '',
        start_student_count: '',
        end_student_count: '',
        medium: [
          {
            onlineType: '1',
            title: '微信群聊'
          },
          {
            onlineType: '2',
            title: 'QQ群'
          },
          {
            onlineType: '3',
            title: '表白墙'
          },
          {
            onlineType: '4',
            title: '微博'
          },
          {
            onlineType: '5',
            title: '微信公众号'
          },
          {
            onlineType: '6',
            title: '微信KOL朋友圈'
          },
          {
            onlineType: '7',
            title: '桌贴'
          },
          {
            onlineType: '8',
            title: '操场围栏'
          },
          {
            onlineType: '9',
            title: '框架资源'
          },
          {
            onlineType: '10',
            title: '室内场地'
          },
          {
            onlineType: '11',
            title: '室外场地'
          }
        ],
        cityArr: [ // 移动端热门城市
          {
            name: '全国',
            active: true
          },
          {
            name: '北京',
            active: false
          },
          {
            name: '南京',
            active: false
          },
          {
            name: '上海',
            active: false
          },
          {
            name: '重庆',
            active: false
          },
          {
            name: '天津',
            active: false
          },
          {
            name: '黑龙江',
            active: false
          }
        ],
        typeArr: [ // 类型
          {
            name: '不限',
            active: true
          },
          {
            name: '985',
            active: false
          },
          {
            name: '211',
            active: false
          },
          {
            name: '本科',
            active: false
          },
          {
            name: '专科',
            active: false
          }
        ],
        attrArr: [ // 移动端属性
          {
            name: '不限',
            active: true
          },
          {
            name: '师范',
            active: false
          },
          {
            name: '农林',
            active: false
          },
          {
            name: '政法',
            active: false
          },
          {
            name: '民族',
            active: false
          },
          {
            name: '医科',
            active: false
          },
          {
            name: '理工',
            active: false
          },
          {
            name: '综合',
            active: false
          },
          {
            name: '艺术',
            active: false
          },
          {
            name: '语言',
            active: false
          },
          {
            name: '财经',
            active: false
          },
          {
            name: '体育',
            active: false
          }
        ],
        schoolArr: [ // 学校人数
          {
            name: '不限',
            active: true
          },
          {
            name: '5K以下',
            active: false
          },
          {
            name: '5-10K',
            active: false
          },
          {
            name: '10-20K',
            active: false
          },
          {
            name: '20-30K',
            active: false
          },
          {
            name: '30-40K',
            active: false
          },
          {
            name: '40-50K',
            active: false
          },
          {
            name: '50-100K',
            active: false
          },
          {
            name: '100-200K',
            active: false
          }
        ],
        searchKey: [ // 进行循环关键字搜索学校
          {
            name: '清华大学',
            active: false
          },
          {
            name: '北京大学',
            active: false
          },
          {
            name: '同济大学',
            active: false
          },
          {
            name: '南开大学',
            active: false
          }
        ]
      }
    },
    methods: {
      /**
       * 监听城市 tree 组件选中事件
       *
       * @param data data
       * @param checked 选中的城市
       * @param indeterminate
       */
      onCitiesTreeCheckChange: function (e, checkedNodes) {
        const checkedCities = []
        checkedNodes.checkedNodes.forEach(element => {
          checkedCities.push(element.label)
        })
        this.checkedCities = checkedCities
        const newArr = Array.from(
          new Set([...this.checkedCities])
        );

        [...this.checkedCities].length
          ? this.dynamicTags.splice(
          0,
          1,
          `城市：${Array.from(
            new Set([...this.checkedCities])
          ).join('、')}`
          )
          : this.dynamicTags.splice(0, 1, 0)
        this.getList()
      },
      // --------------------------移动端和ipad api对接---------------------------------
      // 选择热门城市
      changeCity (item) {
        this.cityArr.map(items => {
          if (items.name === item.name) {
            items.active = !item.active
          }
          return items
        })
      },
      // 选择类型
      changeType (item) {
        this.typeArr.map(items => {
          if (items.name === item.name) {
            items.active = !item.active
          }
          return items
        })
      },
      // 选择属性
      changeAttr (item) {
        this.attrArr.map(items => {
          if (items.name === item.name) {
            items.active = !item.active
          }
          return items
        })
      },
      // 选择学校人数
      changeSchool (item) {
        this.schoolArr.map(items => {
          if (items.name === item.name) {
            items.active = true
          } else {
            items.active = false
          }
          return items
        })
      },
      // 清除点击查询的关键字
      cleanItem () {
        this.cityArr.map(items => {
          if (items.name !== '全国') {
            items.active = false
          }
          return items
        })
        this.typeArr.map(items => {
          if (items.name !== '不限') {
            items.active = false
          }
          return items
        })
        this.attrArr.map(items => {
          if (items.name !== '不限') {
            items.active = false
          }
          return items
        })
        this.schoolArr.map(items => {
          if (items.name !== '不限') {
            items.active = false
          }
          return items
        })
      },
      // 点击确认进行查询
      confirmBtn () {
        const cityChoice = []
        const attrChoice = []
        const typeChoice = []
        const schoolChoice = []
        this.cityArr.forEach(item => {
          if (item.active) {
            cityChoice.push(item.name)
          }
        })

        this.typeArr.forEach(item => {
          if (item.active) {
            if (item.name == '985') {
              typeChoice.push(1)
            } else if (item.name == '211') {
              typeChoice.push(2)
            } else if (item.name == '本科') {
              typeChoice.push(3)
            } else if (item.name == '专科') {
              typeChoice.push(4)
            }
          }
        })

        this.attrArr.forEach(item => {
          if (item.active) {
            attrChoice.push(item.name)
          }
        })

        this.schoolArr.forEach(item => {
          if (item.active) {
            schoolChoice.push(item.name)
          }
        })

        // console.log(cityChoice.join(','))
        // console.log(attrChoice.join(','))
        // console.log(typeChoice.join(','))
        // console.log(schoolChoice.join(','))

        this.city = cityChoice.join(',') === '全国' ? '' : cityChoice.join(',')
        this.type = typeChoice.join(',') === '不限' ? '' : typeChoice.join(',')
        this.kind_name =
          attrChoice.join(',') === '不限' ? '' : attrChoice.join(',')

        if (schoolChoice.join(',') === '不限') {
          this.start_student_count = ''
          this.end_student_count = ''
        } else if (schoolChoice.join(',') === '5K以下') {
          this.end_student_count = '5000'
          this.start_student_count = '0'
        } else if (schoolChoice.join(',') === '5-10K') {
          this.end_student_count = '10000'
          this.start_student_count = '5000'
        } else if (schoolChoice.join(',') === '10-20K') {
          this.start_student_count = '10000'
          this.end_student_count = '20000'
        } else if (schoolChoice.join(',') === '20-30K') {
          this.start_student_count = '20000'
          this.end_student_count = '30000'
        } else if (schoolChoice.join(',') === '30-40K') {
          this.start_student_count = '30000'
          this.end_student_count = '40000'
        } else if (schoolChoice.join(',') === '40-50K') {
          this.start_student_count = '40000'
          this.end_student_count = '50000'
        } else if (schoolChoice.join(',') === '50-100K') {
          this.start_student_count = '50000'
          this.end_student_count = '100000'
        } else if (schoolChoice.join(',') === '100-200K') {
          this.start_student_count = '100000'
          this.end_student_count = '200000'
        }
        this.moveDataList()
        this.isChoiceActive = false
      },
      // 加载更多
      onLoad () {
        this.loading = true
        const formData = new FormData()
        formData.append('pageCurrent', (this.pageCurrentMove++).toString())
        formData.append('pageSize', this.pageSize)
        formData.append('university_name', this.university_name) // 搜索学校名字
        formData.append('online_type', this.onlineType)
        formData.append('city', this.city)
        formData.append('type', this.type)
        formData.append('kind_name', this.kind_name)
        formData.append('start_student_count', this.start_student_count)
        formData.append('end_student_count', this.end_student_count)
        this.$http.request({
          url: 'Datacenter/actionOnLineDatacenterList',
          method: 'POST',
          data: formData
        }).then((res) => {
          const code = res.data.code
          switch (code) {
            case 200:
              this.loadingPC = false
              // 判断加载完所有的订单数据
              this.finished = res.data.data.DatacenterList.length !== this.pageSize
              // 拼接加载出的新订单数据
              this.moveTableData = this.moveTableData.concat(res.data.data.DatacenterList)
              break
            case 202:
              // 当前页暂无数据，加载完成
              this.finished = true
              break
            default:
          }
          this.$nextTick(() => {
            // 加载完成后修改加载状态
            this.loading = false
          })
        })
      },
      // move,ipad 接口详情
      async moveDataList () {
        const formData = new FormData()
        formData.append('pageCurrent', 1)
        formData.append('pageSize', this.pageSize)
        formData.append('university_name', this.university_name) // 搜索学校名字
        formData.append('online_type', this.onlineType)
        formData.append('city', this.city)
        formData.append('type', this.type)
        formData.append('kind_name', this.kind_name)
        formData.append('start_student_count', this.start_student_count)
        formData.append('end_student_count', this.end_student_count)
        this.$http.request({
          url: 'Datacenter/actionOnLineDatacenterList',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.loadingPC = false
            // 重置当前页码
            this.pageCurrentMove = 1
            this.moveTableData = res.data.data.DatacenterList
            // 判断是否加载全部订单数据
            this.finished = this.moveTableData.length !== this.pageSize
          } else if (res.data.code === 202) {
            this.moveTableData = []
            // 判断是否加载全部订单数据
            this.finished = this.moveTableData.length !== this.pageSize
          }
          this.$nextTick(() => {
            this.refreshing = false
          })
        })
      },
      // 点击搜索进行 输入学校搜索
      async searchSchool () {
        const formData = new FormData()
        formData.append('shcool_name', this.inputSchool)
        this.$http.request({
          url: 'Datacenter/actionUniversityList',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.searchSchoolArr = res.data.data
          } else if (res.data.code === 202) {
          }
        })
      },
      // 点击搜索内容
      searchSureSchool (item) {
        this.university_name = item.university_name
        this.inputSchool = item.university_name
        this.isInputActive = false
        this.moveDataList() // 进行调取搜索出列表
      },
      // 点击确认按钮
      searchSureSchoolBtn () {
        this.university_name = this.inputSchool
        this.isInputActive = false
        this.moveDataList()
      },
      // 刷新列表
      onRefresh () {
        this.initOrdersList(this.onlineType)
      },
      // 点击每个title进行接口调取
      initOrdersList (id) {
        this.onlineType = id + 1
        this.moveDataList()
      },
      // 输入学校名称进行筛选
      goSearchSchool () {
        if (this.isChoiceActive) {
          return
        }
        this.isInputActive = true
      },
      // 点击跳转学校详情
      goDetails (id) {
        this.$router.push({
          path: '/DataCenterDetail',
          query: {
            id: id,
            type: this.onlineType,
            tel: ''
          }
        }).then()
      },
      // --------------------------------------------------move，ipad 结束 ---------------------------------------------------------
      // ------------------------------------------PC端 ----------------------------------------------------
      // 点击显示弹窗
      projectTip () {
        this.show = true
      },
      // 点击空白处 进行弹窗回调
      submit () {
        this.show = false
      },
      // 查看详情
      goSeeDetails (id, type, tel) {
        this.$router.push({
          path: '/DataCenterDetail',
          query: {
            id: id,
            type: type,
            tel: tel
          }
        }).then()
      },
      handleSelectionChange (val) {
        this.multipleSelection = val
      },
      // 点击更换资源按钮 进行资源切换
      changeTopType (type) {
        this.TopType = type
        if (type === 1) {
          this.online_type = 5
        }
        if (type === 2) {
          this.online_type = 7
        }

        if (type === 3) {
          this.online_type = 10
        }

        if (type === 4) {
          this.online_type = 12
        }
        this.allSelect = false
        this.checkAll = false // 全选按钮
        this.isIndeterminate = false // 全选
        this.getList()
      },
      handleCheckAllChange (rows) {
        this.isIndeterminate = false
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row, !this.allSelect)
          })
          this.allSelect = !this.allSelect
        }
      },
      handleSelectAll () {
        this.allSelect = !this.allSelect
        this.checkAll = !this.checkAll
        this.isIndeterminate = false
      },
      // 点击资源类型 进行切换
      changeDetailType (type) {
        this.online_type = type
        this.pageDate = {
          pageGroup: [20, 50, 100, 200],
          pageCurrent: 1,
          pageSize: 20,
          totalNumber: 0
        }
        this.allSelect = false
        this.checkAll = false // 全选按钮
        this.isIndeterminate = false // 全选
        this.getList()
      },
      // 点击搜索按钮 进行学校搜索
      search () {
        this.getList()
      },
      // 线上资源
      async getList () {
        const checkedTypes = this.checkedTypes.map((item) => {
          return item === '985' ? item = 1 : (item === '211' ? item = 2 : (item === '本科' ? item = 3 : item = 4))
        }) // 学校类型进行赋值数字
        const formData = {
          online_type: this.online_type, // 资源属性
          university_name: this.productName, // 搜索结果
          type: checkedTypes.join(','), // 学校类型
          kind_name: this.checkedAttrs.join(','), // 学校属性
          city: Array.from(new Set([...this.checkedCities, ...this.checkedCities])).join(','), // 选择城市
          start_student_count: this.beginSchool, // 搜索学校人数开始
          end_student_count: this.endSchool, // 搜索学校认识结束
          start_cover_number: this.beginCover, // 覆盖人数筛选结束
          end_cover_number: this.endCover, // 覆盖人数筛选结束
          start_read_number: this.beginPublish, // 阅读量人数筛选
          end_read_number: this.endPublish, // 阅读量人数筛选
          female_rate: this.commandGender === '50%-60%' ? 2 : (this.commandGender === '少于50%' ? 1 : (this.commandGender === '60%以上' ? 3 : '')), // 女生比例筛选
          telephone: this.telephone,
          pageCurrent: this.pageDate.pageCurrent,
          pageSize: this.pageDate.pageSize
        }
        this.$http.request({
          url: 'Datacenter/actionOnLineDatacenterList',
          method: 'POST',
          data: qs.stringify(formData)
        }).then((res) => {
          if (res.data.code === 200) {
            this.loadingPC = false
            this.tableData = res.data.data.DatacenterList
            this.pageDate.pageCurrent = res.data.data.PageList.pageCurrent
            this.pageDate.pageSize = res.data.data.PageList.pageSize
            this.pageDate.totalNumber = res.data.data.PageList.totalNumber
          }
        })
      },
      // 分页
      handleSizeChange (val) {
        this.pageDate.pageSize = +val
        this.getList()
      },
      handleCurrentChange (val) {
        this.pageDate.pageCurrent = +val
        this.getList()
      },
      // 城市选择 进行筛选
      FilterChange (payload) {
        // 选择城市
        if (payload === 'city') {
          [...this.checkedCities].length
            ? this.dynamicTags.splice(
            0,
            1,
            `城市：${Array.from(
              new Set([...this.checkedCities])
            ).join('、')}`
            )
            : this.dynamicTags.splice(0, 1, 0)
        }
        // 选择学校类型
        if (payload === 'type') {
          this.checkedTypes.length
            ? this.dynamicTags.splice(
            1,
            1,
            `类型：${this.checkedTypes.join('、')}`
            )
            : this.dynamicTags.splice(1, 1, '')
        }
        // 选择学校
        if (payload === 'attr') {
          this.checkedAttrs.length
            ? this.dynamicTags.splice(
            2,
            1,
            `属性：${this.checkedAttrs.join('、')}`
            )
            : this.dynamicTags.splice(2, 1, '')
        }
        this.getList()
      },
      // 学校人数 进行数量筛选
      getSchoolBtn () {
        if (this.beginSchool == '' && this.endSchool == '') {
          return this.$message('请填写正确的范围')
        }
        if (this.beginSchool == '' && !this.endSchool == '') {
          this.dynamicTags.splice(4, 1, `学校人数~${this.endSchool}`)
        }
        if (!this.beginSchool == '' && this.endSchool == '') {
          this.dynamicTags.splice(4, 1, `学校人数>=${this.beginSchool}`)
        }
        if (!this.beginSchool == '' && !this.endSchool == '') {
          this.dynamicTags.splice(
            4,
            1,
            `学校人数${this.beginSchool}~${this.endSchool}`
          )
        }
        this.schoolPeople = `学校人数${this.beginSchool}~${this.endSchool}`
        this.getList()
        document.querySelectorAll('.el-popover.el-popper').forEach(item => {
          item.style.display = 'none'
        })
      },
      // 覆盖人数进行筛选
      getCoverBtn () {
        if (this.beginCover == '' && this.endCover == '') {
          return this.$message('请填写正确的范围')
        }
        if (this.beginCover == '' && !this.endCover == '') {
          this.dynamicTags.splice(5, 1, `覆盖人数~${this.endCover}`)
        }
        if (!this.beginCover == '' && this.endCover == '') {
          this.dynamicTags.splice(5, 1, `覆盖人数>=${this.beginCover}`)
        }
        if (!this.beginCover == '' && !this.endCover == '') {
          this.dynamicTags.splice(
            5,
            1,
            `覆盖人数${this.beginCover}~${this.endCover}`
          )
        }
        this.getList()
        this.coverPeople = `覆盖人数${this.beginCover}~${this.endCover}`
        document.querySelectorAll('.el-popover.el-popper').forEach(item => {
          item.style.display = 'none'
        })
      },
      // 阅读量进行筛选
      getPublishBtn () {
        if (this.beginPublish == '' && this.endPublish == '') {
          return this.$message('请填写正确的范围')
        }
        if (this.beginPublish == '' && !this.endPublish == '') {
          this.dynamicTags.splice(6, 1, `阅读量~${this.endPublish}`)
        }
        if (!this.beginPublish == '' && this.endPublish == '') {
          this.dynamicTags.splice(6, 1, `阅读量>=${this.beginPublish}`)
        }
        if (!this.beginPublish == '' && !this.endPublish == '') {
          this.dynamicTags.splice(
            6,
            1,
            `阅读量：${this.beginPublish}~${this.endPublish}`
          )
        }
        this.readNum = `阅读量：${this.beginPublish}~${this.endPublish}`
        this.getList()
        document.querySelectorAll('.el-popover.el-popper').forEach(item => {
          item.style.display = 'none'
        })
      },
      // 女生比例筛选
      handleGenderCommand (command) {
        command === 'no' ? this.dynamicTags.splice(3, 1, '') : this.dynamicTags.splice(3, 1, `女生比例：${command}`)
        this.commandGender = command
        this.getList()
      },
      // 清除全已经选择内容标签
      cleanCondition () {
        this.expandedArr = []
        this.dynamicTags = ['', '', '', '', '', '', '', '']
        this.checkedCities = []
        this.checkedTypes = []
        this.checkedAttrs = []
        this.schoolPeople = ''
        this.getList()
      },
      searchList (item) {
        this.productName = item
        this.getList()
      },
      //  清除已经选择好的条件标签
      handleClose (tag) {
        tag.indexOf('城市：') === -1
          ? ''
          : (this.dynamicTags.splice(0, 1, ''),
            (this.checkedCities = []))

        tag.indexOf('类型：') === -1
          ? ''
          : (this.dynamicTags.splice(1, 1, ''), (this.checkedTypes = []))

        tag.indexOf('属性：') === -1
          ? ''
          : (this.dynamicTags.splice(2, 1, ''), (this.checkedAttrs = []))
        tag.indexOf('女生比例') === -1 ? '' : (this.dynamicTags.splice(3, 1, ''), this.commandGender = '')
        tag.indexOf('学校人数') === -1
          ? ''
          : (this.dynamicTags.splice(4, 1, ''),
            (this.schoolPeople = ''),
            (this.beginSchool = ''),
            (this.endSchool = ''))

        tag.indexOf('覆盖人数') === -1
          ? ''
          : (this.dynamicTags.splice(5, 1, ''),
            (this.coverPeople = ''),
            (this.beginCover = ''),
            (this.endCover = ''))

        tag.indexOf('阅读量') === -1
          ? ''
          : (this.dynamicTags.splice(6, 1, ''))
        this.getList()
      },
      // 导出资源列表
      outResBtn () {
        const data = this.multipleSelection
        if (this.multipleSelection.length === 0) {
          return false
        } else {
          let html = '<table>'

          if (this.online_type == 5) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>类型</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>平均阅读量</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].category + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].average_read_num + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 4) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>类别</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].category + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 6) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>属性</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].position + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 3) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>类型</th>'
            html += '<th>平均阅读量</th>'
            html += '<th>平均点赞数</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].space_type + '</td>'
              html += '<td>' + data[i].average_read_num + '</td>'
              html += '<td>' + data[i].average_awesome_num + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 1) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 2) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 7) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>点位数量</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].total_point_position + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 8) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>上刊数量</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].published_number + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 9) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>位置</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].location + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 10) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>场地名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>场地地点</th>'
            html += '<th>可容纳人数</th>'
            html += '<th>场地类型</th>'
            html += '<th>面积</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].location + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].site_type + '</td>'
              html += '<td>' + data[i].area + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 11) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>资源名称</th>'
            html += '<th>所属学校</th>'
            html += '<th>覆盖人数</th>'
            html += '<th>面积</th>'
            html += '<th>价格</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].datacenter_name + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].area + '</td>'
              html += '<td>' + data[i].price + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          if (this.online_type == 12) {
            html += '<thead>'
            html += '<tr>'
            html += '<th>学校logo</th>'
            html += '<th>学校名称</th>'
            html += '<th>城市名称</th>'
            html += '<th>省份</th>'
            html += '<th>211</th>'
            html += '<th>985</th>'
            html += '<th>学校人数</th>'
            html += '<th>属性</th>'
            html += '<th>女生比例</th>'
            html += '</tr>'
            html += '</thead>'
            html += '<tbody>'
            for (let i = 0; i < data.length; i++) {
              html += '<tr>'
              html += '<td>' + data[i].logo + '</td>'
              html += '<td>' + data[i].university_name + '</td>'
              html += '<td>' + data[i].city_name + '</td>'
              html += '<td>' + data[i].province_name + '</td>'
              html += '<td>' + data[i].is_211 + '</td>'
              html += '<td>' + data[i].is_985 + '</td>'
              html += '<td>' + data[i].datacenter_number + '</td>'
              html += '<td>' + data[i].kind_name + '</td>'
              html += '<td>' + data[i].female_rate + '</td>'
              html += '</tr>'
            }
            html += '</tbody>'
          }

          html += '</table>'

          var now_html = '<html><head><meta charset=\'utf-8\' /></head><body>' + html + '</body></html>'
          /* outerHTML:获取整个table元素的HTML代码（包括<table>标签）,包装成一个完整的HTML文档
           charset为urf-8以防止中文乱码 */
          var blob = new Blob([now_html], { type: 'application/vnd.ms-excel' })
          /* 实例化一个Blob对象
           * Blob构造函数:[now_html]包含文件的数组
           * { type: "application/vnd.ms-excel" }：是包含文件类型属性的对象 */
          var a = document.querySelector('.out-res-btn')
          // 利用URL.createObjectURL()方法为a元素生成blob URL
          a.href = URL.createObjectURL(blob)
        }
      }
    },
    activated () {
      this.productName = this.$route.query.name
      this.getList()
    },
    // mounted () {
    //   this.productName = this.$route.query.name
    //   this.getList() // pc端获取数据
    // }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #dataContent
      width 1320px
      margin 70px auto

      .top-box
        padding 32px
        background #FFFFFF
        margin-bottom 16px

        .search-box
          display flex
          justify-content center

          .search-input
            font-size 14px
            padding-left 12px
            width 440px
            height 32px
            border 1px solid #2988FC
            opacity 1
            border-radius 2px

          .search-title
            color #FFFFFF
            font-size 16px
            text-align center
            width 120px
            height 34px
            line-height 34px
            background #2988FC
            opacity 1
            cursor pointer
            border-radius 0 2px 2px 0

        .hot-search-box
          margin-left -23%
          display flex
          justify-content center

          .title-list
            color #909399
            font-size 12px
            margin-top 8px
            margin-right 4px
            cursor pointer

        .res-type-box
          display flex
          margin-top 30px

          .list
            width 208px
            height 50px
            line-height 50px
            text-align center
            opacity 1
            border-radius 2px
            color #606266
            font-size 16px
            margin-right 16px
            cursor pointer
            -moz-transition all .3s
            -o-transition all .3s
            -webkit-transition all .3s
            transition all .3s

          .list:hover
            box-shadow 0 2px 10px 0 rgba(41, 136, 252, 0.1)
            transform translate3d(0, -1px, 0)
            transition all .4s cubic-bezier(.22, 0, .1, 1)
            border-radius 2px 2px 2px 2px
            background rgba(41, 136, 252, 0.1)

          .active
            font-size 16px
            color #2988FC
            width 208px
            height 50px
            line-height 50px
            background rgba(41, 136, 252, 0.1)
            opacity 1
            border-radius 2px

        .choice-city-box
          display flex
          justify-content space-between
          align-items flex-end

          .choice-city
            display flex
            height 30px
            margin-top 16px
            align-items center

            .filter-name
              color #909399
              font-size 16px
              margin-right 16px

            .type-style
              display flex

            .address-tree
              height 200px
              overflow hidden

            .title
              color #606266
              font-size 14px
              padding 8px 16px
              border-radius 2px
              background #FFFFFF
              cursor pointer
              transition all 0.3s

            .title:hover
              color #2988FC

            .active
              color #2988FC
              font-size 14px
              background rgba(41, 136, 252, 0.1)

            .el-checkbox-button
              margin-right 12px
              opacity 1
              color #606266
              font-size 14px
              cursor pointer

              .el-checkbox-button__inner
                border-radius 2px
                border 0
                padding 8px 16px

            .select-all
              width 1156px
              display flex
              justify-content space-between

              .list
                width 950px

                .el-tag
                  margin-right 16px
                  position relative
                  max-width 190px
                  white-space nowrap
                  overflow hidden
                  text-overflow ellipsis
                  padding-right 20px

              .clean-condition
                color #2988FC
                font-size 14px
                cursor pointer

            .content-box
              margin-right 33px

              .el-dropdown-link
                margin-left 15px

              .filter-price-title
                color #606266
                font-size 14px
                cursor pointer
                margin-left 16px

              .el-icon--right
                margin-left 2px
                margin-top 2px
                cursor pointer

          .more-city-box
            display flex
            align-items center

            .filter-name
              margin-right 16px
              color #909399
              font-size 16px

      .table-box
        background #FFFFFF
        padding 32px

        .school-logo
          width 50px
          height 50px
          object-fit cover

        .see-details
          color #4C7BFF
          cursor pointer

        .el-table th
          background rgba(41, 136, 252, 0.1)
          padding 8px 0

          .cell
            padding-left 14px
            padding-right 14px

        .el-table--enable-row-transition .el-table__body td
          height 86px

          .cell
            padding-left 14px

        .bottom-btn-box
          height 86px
          display flex
          background rgba(41, 136, 252, 0.1)
          opacity 1
          border-radius 0px 0px 4px 4px
          align-items center
          padding 0 14px
          justify-content space-between

          .all-select
            margin-right 12px
            color #666666
            font-size 16px

          .left-btn-box
            display flex
            align-items center

            .out-res-btn
              background #2988fc
              border-radius 2px
              width 108px
              height 25px
              line-height 25px
              text-align center
              color #FFFFFF
              font-size 12px
              margin-right 12px
              cursor pointer

            .give-case
              background rgba(41, 136, 252, 1)
              border-radius 2px
              width 108px
              height 25px
              line-height 25px
              text-align center
              color #FFFFFF
              font-size 12px
              cursor pointer

    .filter-price-info
      display flex

      .el-input
        width 84px

        .el-input__inner
          height 28px
          line-height 28px
          padding 0
          outline none
          border none
          cursor pointer

      .filter-price-cute
        line-height 28px
        padding 0 8px

      .filter-price-btn
        text-align center
        margin auto 0
        box-sizing border-box
        font-size 14px
        height 20px
        margin-left 10px
        line-height 20px
        width 50px
        color #20a0ff
        cursor pointer

    .move-content-box
      display none

    ::-webkit-scrollbar
      display none

    .el-table th:last-child
      text-align right !important
      padding-right 50px !important

    .el-table td:last-child
      text-align right !important
      padding-right 30px

  @media all and (max-width: 1024px)
    #dataContent
      .top-box
        display none

      .table-box
        display none

      .move-content-box
        padding-top 60px

        .search-box
          display flex
          justify-content space-between
          align-items center
          background #fff
          height 54px
          padding 0 28px

          .left-box
            display flex
            align-items center
            width 631px
            height 42px
            background #F5F7F9
            opacity 1
            border-radius 3px

            .search
              width 25px
              height 25px
              margin-left 10px

            .input-box
              width 300px
              height 32px
              padding-left 10px
              background #F5F7F9
              font-size 14px

          .right-box
            display flex
            align-items center

            .screen
              width 32px
              height 32px
              margin-right 4px

            .search-btn
              color #909399
              font-size 14px

        .content-box
          .item-box-out
            margin-top 16px
            margin-bottom 16px

            .content-list
              position relative

              .content-list-cover
                height 100%
                width 100%
                position absolute
                top 0
                left 0

              .mask
                background #FFFFFF
                opacity 0.4
                height 100%
                width 100%
                position absolute
                top 0
                left 0

              .item-top-box
                padding 24px

                .item-top
                  display flex
                  position relative
                  align-items center
                  justify-content space-between
                  padding-bottom 12px

                  .school-list
                    display flex

                    .school-name
                      color #FFFFFF
                      margin-right 12px
                      font-size 16px

                    .attribute
                      width 45px
                      height 20px
                      line-height 20px
                      text-align center
                      background rgba(255, 255, 255, 0.8)
                      opacity 1
                      border-radius 3px
                      font-size 11px
                      color #4C7BFF

                  .weichat-icon
                    width 24px
                    height 24px

                .cover-number
                  height 18px
                  line-height 18px
                  position relative
                  font-size 13px
                  color #FFFFFF
                  padding-bottom 90px

                .address
                  position relative
                  display flex
                  justify-content space-between
                  align-items center

                  .bot-inner
                    display flex
                    align-items center

                    .address-cover
                      margin-right 4px
                      width 22px
                      height 22px

                    .name
                      color #FFFFFF
                      font-size 12px

                  .price
                    color #FFFFFF
                    font-size 12px

        .hot-city
          background #FFFFFF
          padding 8px 28px 8px 28px

          .hot-city-box
            font-size 14px
            color #303133
            margin-top 8px

            .name-city
              font-size 14px
              color #303133
              margin-top 8px

            .selelct-box
              display grid
              grid-template-columns repeat(auto-fill, 80px)
              justify-content space-between
              margin-top 12px

              .name
                font-size 14px
                width 80px
                height 30px
                line-height 30px
                text-align center
                background #fff
                border 1px solid #909399
                opacity 1
                border-radius 4px
                color #909399
                margin-bottom 8px

              .active
                font-size 14px
                width 80px
                height 30px
                line-height 30px
                text-align center
                background #fff
                border 1px solid #4c7bff
                opacity 1
                border-radius 4px
                color #4c7bff
                margin-bottom 8px

          .bottom-btn
            display flex
            justify-content space-between
            margin-top 47px

            .clean-btn
              color #4c7bff
              font-size 16px
              width 107px
              height 38px
              line-height 38px
              text-align center
              background #fff
              border 1px solid #4c7bff
              opacity 1
              border-radius 4px

            .submit-btn
              font-size 16px
              color #fff
              width 225px
              height 40px
              line-height 40px
              text-align center
              background #4c7bff
              opacity 1
              border-radius 4px

        .key-search-box
          padding 30px
          background #FFFFFF
          height calc(100vh - 114px)

          .default-graph
            color #909399
            font-size 14px
            text-align center
            margin-top 30%

          .search-result
            .list
              color #303133
              font-size 14px
              width 375px
              height 36px

  @media all and (max-width: 767px)
    #dataContent
      .move-content-box
        padding-top 40px

        .search-box
          display flex
          justify-content space-between
          align-items center
          background #FFFFFF
          height 52px
          padding 0 16px

          .left-box
            display flex
            align-items center
            width 283px
            height 32px
            background #F5F7F9
            opacity 1
            border-radius 3px

            .search
              width 20px
              height 20px
              margin-left 10px

            .input-box
              height 32px
              padding-left 10px
              background #F5F7F9
              font-size 14px

          .right-box
            display flex
            align-items center

            .screen
              width 20px
              height 20px

            .search-btn
              color #909399
              font-size 14px

        .content-box
          .item-box-out
            margin-top 12px
            margin-bottom 12px

            .content-list
              position relative

              .content-list-cover
                height 100%
                width 100%
                position absolute
                top 0
                left 0

              .mask
                background #FFFFFF
                opacity 0.4
                height 100%
                width 100%
                position absolute
                top 0
                left 0

              .item-top-box
                padding 25px 16px 12px 16px

                .item-top
                  display flex
                  position relative
                  align-items center
                  justify-content space-between
                  padding-bottom 6px

                  .school-list
                    display flex
                    align-items center

                    .school-name
                      color #FFFFFF
                      font-size 16px
                      font-weight bold
                      margin-right 8px

                    .attribute
                      width 42px
                      height 20px
                      line-height 20px
                      text-align center
                      background rgba(255, 255, 255, 0.8)
                      opacity 1
                      border-radius 3px
                      color #4C7BFF
                      font-size 10px

                  .weichat-icon
                    width 25px
                    height 25px

                .cover-number
                  position relative
                  color #FFFFFF
                  font-size 13px
                  padding-bottom 32px

                .address
                  position relative
                  display flex
                  justify-content space-between
                  align-items center

                  .bot-inner
                    display flex
                    align-items center

                    .address-cover
                      width 17px
                      height 17px
                      margin-right 4px

                    .name
                      color #FFFFFF
                      font-size 12px

                  .price
                    font-size 12px
                    color #FFFFFF

        .hot-city
          background #FFFFFF
          padding 8px 16px 8px 16px

          .hot-city-box
            .name-city
              font-size 14px
              color #303133
              margin-top 8px

            .selelct-box
              display grid
              grid-template-columns repeat(auto-fill, 80px)
              justify-content space-between
              margin-top 12px

              .name
                font-size 14px
                width 80px
                height 30px
                line-height 30px
                text-align center
                background #FFFFFF
                border 1px solid #909399
                opacity 1
                border-radius 4px
                color #909399
                margin-bottom 8px

              .active
                font-size 14px
                width 80px
                height 30px
                line-height 30px
                text-align center
                background #FFFFFF
                border 1px solid #4C7BFF
                opacity 1
                border-radius 4px
                color #4C7BFF
                margin-bottom 8px

          .bottom-btn
            display flex
            justify-content space-between
            margin-top 47px

            .clean-btn
              color #4C7BFF
              font-size 16px
              width 107px
              height 38px
              line-height 38px
              text-align center
              background #FFFFFF
              border 1px solid #4C7BFF
              opacity 1
              border-radius 4px

            .submit-btn
              font-size 16px
              color #FFFFFF
              width 225px
              height 40px
              line-height 40px
              text-align center
              background #4C7BFF
              opacity 1
              border-radius 4px

        .key-search-box
          padding 20px
          height calc(100vh - 92px)
</style>
