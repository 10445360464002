<template>
  <div id="second-hand-tool">
    <!--    PC，ipad  -->
    <div class="vote-content">
      <div class="vote-header">
        <div class="vote-header-box">
          <div class="vote-box-content">
            <p class="title">校元二手市场</p>
            <p class="inner">1. 快速发布闲置好物</p>
            <p class="inner">2. 支持发布求购信息</p>
            <p class="inner-last">3. 个人中心查看已发布的闲置好物和求购信息</p>
            <div class="btn-box">
              <p class="btn-icon">立即使用</p>
              <img class="vote-applet" src="../assets/images/es-logo.png" alt="">
              <img class="vote-logo" src="../assets/images/vote-logo.png" alt="">
            </div>
          </div>
          <div class="vote-box-picter">
            <img class="vote-content-img" src="../assets/images/second-hand1-img.png" alt="">
            <img class="vote-circular" src="../assets/images/vote-circular.png" alt="">
          </div>
        </div>
      </div>
      <div class="vote-footer">
        <img class="vote-footer-img" src="../assets/images/vote-footer.png" alt="">
      </div>
      <div class="middle-box">
        <div class="middle-content">
          <div class="mid-top">
            <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
            <p class="name">发布闲置/求购信息</p>
          </div>
          <div class="mid-content-box">
            <div class="mid-content-box-style">
              <p class="list">1. 快速发布闲置好物，输入标题、图片、售价、联系方</p>
              <p class="list">2. 发布者可以发布商品原价，便于购买或对比价格</p>
              <p class="list">3. 快速发布求购信息，输入标题、图片、联系方</p>
              <p class="mid-btn-icon">立即使用</p>
              <img class="vote-applet" src="../assets/images/es-logo.png" alt="">
            </div>
            <div class="midden-vote-box">
              <img class="midden-vote" src="../assets/images/second-hand2-img.png" alt="">
            </div>
          </div>
          <div class="btn-vote-box">
            <img class="btn-vote-box-img" src="../assets/images/second-hand3-img.png" alt="">
          </div>
        </div>
      </div>
      <div class="demand-box">
        <div class="demand-left-img">
          <img class="demand2-icon" src="../assets/images/demand2-icon.png" alt="">
          <img class="demand1-icon" src="../assets/images/demand1-icon.png" alt="">
          <img class="demand3-icon" src="../assets/images/second-hand4-img.png" alt="">
        </div>
        <div class="demand-right-content">
          <p class="name">个人中心</p>
          <p class="title">1. 个人中心可设置个性签名和所在位置</p>
          <p class="title">2. 可查看自己发布的闲置好物和求购信息，还有想要的商品</p>
          <p class="demand-btn">立即使用</p>
          <img class="vote-applet" src="../assets/images/es-logo.png" alt="">
          <img class="demand4-icon" src="../assets/images/second-hand5-img.png" alt="">
        </div>
      </div>
      <div class="administrator">
        <div class="cms-left">
          <p class="name">自主管理后台</p>
          <p class="list">1. 后台发布商品人工审核，更安全、更放心</p>
          <p class="list">2. 数据清晰化，累计发布商品、累计交易量、累计阅读量、金额数，交易情况随时掌握，对学生交易习惯了解更清晰</p>
          <a href="https://es.tianshuai.com/cms" target="_blank"><p class="cms-btn">立即使用</p></a>
        </div>
        <img class="demand5-icon" src="../assets/images/second-hand6-img.png" alt="">
      </div>
    </div>
    <!--    移动端-->
    <div class="move-vote">
      <div class="header-box">
        <div class="content-box">
          <p class="name">校元二手市场</p>
          <p class="title">1. 快速发布闲置好物</p>
          <p class="title">2. 支持发布求购信息</p>
          <p class="title">3. 个人中心查看已发布的闲置好物和求购信息</p>
          <p class="btn-wechat" @click="showWechatCode">立即使用</p>
          <div class="img-box">
            <img class="vote-logo" src="../assets/images/vote-logo.png" alt="">
            <img class="move-vote1-icon" src="../assets/images/move-second-hand1-img.png" alt="">
          </div>
          <div class="vote-circular-box">
            <img class="vote-circular" src="../assets/images/vote-circular.png" alt="">
          </div>
        </div>
      </div>
      <div class="move-footer-img-box">
        <img class="move-vote3-icon" src="../assets/images/move-vote3-icon.png" alt="">
      </div>
      <div class="establish-active">
        <div class="content-img">
          <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
          <p class="name">发布闲置/求购信息</p>
        </div>
        <div class="establish-active-box">
          <p class="title">1. 快速发布闲置好物，输入标题、图片、售价、联系方</p>
          <p class="title">2. 发布者可以发布商品原价，便于购买或对比价格</p>
          <p class="title">3. 快速发布求购信息，输入标题、图片、联系方</p>
          <p class="btn-apply" @click="showWechatCode">立即使用</p>
          <img class="midden-vote" src="../assets/images/move-second-hand2-img.png" alt="">
        </div>
      </div>
      <div class="demand-box">
        <div class="content-box">
          <p class="name">个人中心</p>
          <p class="title">1. 个人中心可设置个性签名和所在位置</p>
          <p class="title">2. 可查看自己发布的闲置好物和求购信息，还有想要的商品</p>
          <p class="btn-demand" @click="showWechatCode">立即使用</p>
        </div>
        <div class="demand-img-box">
          <img class="demand2-icon" src="../assets/images/demand2-icon.png" alt="">
          <img class="demand1-icon" src="../assets/images/demand1-icon.png" alt="">
          <img class="demand3-icon" src="../assets/images/move-second-hand3-img.png" alt="">
        </div>
      </div>
      <div class="cms-box">
        <div class="content-box">
          <p class="name">自主管理后台</p>
          <p class="title">1. 后台发布商品人工审核，更安全、更放心</p>
          <p class="title">2. 数据清晰化，累计发布商品、累计交易量、累计阅读量、金额数，交易情况随时掌握，对学生交易习惯了解更清晰</p>
          <a href="https://es.tianshuai.com/cms" target="_blank"><p class="btn-apply">立即使用</p></a>
        </div>
        <img class="move-vote-icon" src="../assets/images/move-second-hand4-img.png" alt="">
      </div>
    </div>
    <!--   二维码弹窗   -->
    <van-popup class="wechat-code-popup" style="background-color:rgba(255, 255, 255, 0);overflow:inherit"
               v-model="iscodeShow">
      <div class="content-box">
        <img class="code-cover" alt src="../assets/images/es-logo.png"/>
        <p>长按保存或识别二维码</p>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    // name: 'Navigation',
    data () {
      return {
        iscodeShow: false // 二维码弹窗
      }
    },
    methods: {
      // 点击立即使用
      showWechatCode () {
        this.iscodeShow = true
      }
    },
    mounted () {
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #second-hand-tool
      .vote-content
        .vote-header
          background url("../assets/images/vote-header.png")no-repeat
          background-size 100% 100%
          .vote-header-box
            display flex
            width 1320px
            margin auto
            .vote-box-content
              padding-left 168px
              .title
                color #FFFFFF
                font-size 64px
                height 80px
                line-height 80px
                padding-top 176px
                font-family ZhenyanGB
                margin-top 32px
              .inner
                color #FFFFFF
                font-size 20px
                height 27px
                line-height 27px
                margin-bottom 12px
              .inner-last
                color #FFFFFF
                width 524px
                line-height 26px
                font-size 20px
              .btn-box
                display flex
                .btn-icon
                  color #2988FC
                  font-size 18px
                  text-align center
                  width 142px
                  height 50px
                  line-height 50px
                  background #FFFFFF
                  box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
                  opacity 1
                  border-radius 4px
                  cursor pointer
                  margin-top 48px
                .vote-applet
                  margin-top 26px
                  opacity 0
                .btn-icon:hover + .vote-applet
                  opacity 1
                .vote-logo
                  width 93px
                  height 93px
                  margin-left 172px

            .vote-box-picter
              text-align center
              margin-right 121px
              .vote-content-img
                margin-top 149px
                width 303px
                position relative
              .vote-circular
                width 429px
                height 156px
                margin-top -85px
        .vote-footer
          margin-bottom 20px
          .vote-footer-img
            width 100%
            background-size 100%
            height 105px
            margin-top -92px
        .middle-box
          width 1320px
          margin auto
          display flex
          .middle-content
            .mid-top
              display flex
              align-items center
              .tips-flow
                width 83px
                height 88px
                margin-left 56px
                margin-right 29px
              .name
                color #303133
                font-size 36px
                font-weight bold
            .mid-content-box
              display flex
              align-items flex-start
              .mid-content-box-style
                background #2988FC
                opacity 1
                border-radius 36px 0 0 36px
                padding 48px 0 54px 168px
                height 242px
                .list
                  color #FFFFFF
                  font-size 16px
                  line-height 24px
                  width 388px
                  margin-bottom 12px
                .mid-btn-icon
                  width 142px
                  height 50px
                  line-height 50px
                  background #FFFFFF
                  box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
                  opacity 1
                  border-radius 4px
                  color #2988FC
                  font-size 18px
                  text-align center
                  margin-top 24px
                  cursor pointer
                .vote-applet
                  width 197px
                  height 215px
                  position absolute
                  margin-top -64px
                  margin-left 150px
                  opacity 0
                .mid-btn-icon:hover + .vote-applet
                  opacity 1
              .midden-vote-box
                width 764px
                border-radius 0 36px 36px 0
                background #2988FC
                height 344px
                .midden-vote
                  height 719px
                  margin-top -172px
            .btn-vote-box
              .btn-vote-box-img
                margin 15px 0 140px 78px
        .demand-box
          width 1320px
          margin auto
          display flex
          margin-bottom 46px
          .demand-left-img
            display flex
            .demand1-icon
              position relative
              width 289px
              height 466px
              margin-top 96px
            .demand2-icon
              position absolute
              width 592px
              height 592px
              margin-left 138px
            .demand3-icon
              position relative
              margin-left -244px
              margin-top -74px
          .demand-right-content
            margin-left 143px
            .name
              color #303133
              font-size 36px
              font-weight bold
              margin-bottom 32px
            .title
              font-size 16px
              width 388px
              line-height 24px
              margin-bottom 12px
              color #303133
            .demand-btn
              font-size 18px
              color #FFFFFF
              text-align center
              width 142px
              height 50px
              line-height 50px
              background #2988FC
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
              opacity 1
              margin-top 36px
              cursor pointer
              border-radius 4px
            .vote-applet
              width 197.5px
              height 215px
              position absolute
              margin-top -64px
              margin-left 150px
              opacity 0
            .demand-btn:hover + .vote-applet
              opacity 1
            .demand4-icon
              width 450px
              margin-top 9px
              margin-left -24px
        .administrator
          background #2988FC
          opacity 1
          border-radius 128px 128px 0px 0px
          display flex
          align-items center
          justify-content center
          .cms-left
            .name
              color #FFFFFF
              font-size 36px
              font-weight bold
              margin-bottom 32px
            .list
              width 386px
              line-height 24px
              color #FFFFFF
              margin-bottom 12px
            .cms-btn
              width 142px
              height 50px
              line-height 50px
              color #2988FC
              font-size 18px
              text-align center
              background #FFFFFF
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
              opacity 1
              border-radius 4px
              margin-top 36px
              cursor pointer
          .demand5-icon
            margin-left 41px
  @media all and (max-width: 1024px)
    #second-hand-tool
      .vote-content
        .vote-header
          background url("../assets/images/vote-header.png")no-repeat center
          background-size 100% 100%
          .vote-header-box
            display flex
            justify-content space-between
            .vote-box-content
              color #fffff
              margin-left 11%
              .title
                font-family ZhenyanGB
                color #FFFFFF
                font-size 36px
                margin-top 108px
                margin-bottom 16px
              .inner
                width 286px
                line-height 21px
                margin-bottom 8px
                color #FFFFFF
                font-size 14px
              .inner-last
                font-size 12px
                line-height 21px
                margin-bottom 16px
                color #FFFFFF
                width 288px
              .btn-box
                display flex
                .btn-icon
                  width 82px
                  height 29px
                  line-height 29px
                  text-align center
                  color #2988FC
                  font-size 12px
                  background #FFFFFF
                  box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
                  opacity 1
                  border-radius 4px
                .btn-icon:hover + .vote-applet
                  opacity 1
                .vote-applet
                  width 61px
                  height 61px
                  margin-top -12px
                  opacity 0
                .vote-logo
                  width 61px
                  height 61px
                  margin-left 150px
                  margin-top -43px

            .vote-box-picter
              width 248px
              text-align center
              margin-right 85px
              .vote-content-img
                width 200px
                position relative
                margin-top 72px
              .vote-circular
                width 248px
                margin-top -50px
        .vote-footer
          .vote-footer-img
            width 100%
            background-size 100% 100%
            height 82px
            margin-top -92px
        .middle-box
          display flex
          padding 0 28px
          .middle-content
            margin-top 14px
            .mid-top
              display flex
              align-items center
              .tips-flow
                width 45px
                height 48px
                margin-right 6px
              .name
                color #303133
                font-weight bold
                font-size 20px
            .mid-content-box
              display flex
              align-items flex-start
              .mid-content-box-style
                background #2988fc
                opacity 1
                border-radius 18px 0 0 18px
                padding 24px 0 24px 77px
                margin-right -1px
                .list
                  width 250px
                  line-height 21px
                  font-size 14px
                  margin-bottom 7px
                  color #FFFFFF
                .mid-btn-icon
                  color #2988FC
                  font-size 12px
                  text-align center
                  width 77px
                  height 28px
                  line-height 28px
                  background #FFFFFF
                  box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
                  opacity 1
                  margin-top 9px
                  border-radius 4px
                .vote-applet
                  width 61px
                  height 61px
                  position absolute
                  margin-top -30px
                  margin-left 86px
                  opacity 0
                .mid-btn-icon:hover + .vote-applet
                  opacity 1
              .midden-vote-box
                width 100%
                border-radius 0 18px 18px 0
                background #2988fc
                height 224px
                .midden-vote
                  width 95%
                  margin-top -15%
                  margin-left 6%
            .btn-vote-box
              .btn-vote-box-img
                width 198px
                margin-top 4px
                margin-left 66px
        .demand-box
          display flex
          padding 0 28px
          margin-top 15%
          align-items center
          justify-content space-between
          .demand-left-img
            display flex
            .demand2-icon
              position absolute
              width 322px
              height 321px
              margin-left 98px
            .demand1-icon
              width 157px
              height 254px
              position relative
              margin-left 23px
              margin-top 64px
            .demand3-icon
              position relative
              width 100%
              margin-left -160px
              margin-top -65px
          .demand-right-content
            margin-right 3%
            .name
              color #303133
              font-size 20px
              font-weight bold
              margin-bottom 12px
            .demand4-icon
              width 267px
              margin-left -22px
            .title
              color #303133
              font-size 14px
              line-height 24px
              margin-bottom 8px
            .demand-btn
              color #FFFFFF
              font-size 12px
              text-align center
              width 77px
              height 27px
              line-height 27px
              background #2988FC
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
              opacity 1
              margin-top 9px
              border-radius 4px
            .vote-applet
              width 61px
              height 61px
              position absolute
              margin-top -30px
              margin-left 80px
              opacity 0
            .demand-btn:hover + .vote-applet
              opacity 1
        .administrator
          display flex
          justify-content center
          padding-left 28px
          background #2988FC
          opacity 1
          border-radius 48px 48px 0px 0px
          padding-top 40px
          padding-bottom 34px
          .cms-left
            margin-top 43px
            .name
              color #FFFFFF
              font-weight bolder
              margin-bottom 20px
              font-size 20px
            .list
              color #FFFFFF
              font-size 14px
              line-height 21px
              margin-bottom 8px
              width 284px
            .cms-btn
              color #2988FC
              font-size 12px
              text-align center
              width 87px
              height 31px
              line-height 31px
              background #FFFFFF
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
              opacity 1
              margin-top 16px
              border-radius 4px
          .demand5-icon
            width 60%
  @media all and (min-width: 768px)
    #second-hand-tool
      .move-vote
        display none
  @media all and (max-width: 767px)
    #second-hand-tool
      .vote-content
        display none
      .move-vote
        .header-box
          background url("../assets/images/move-vote2-icon.png")no-repeat
          background-size 100% 100%
          .content-box
            padding 0 20px
            .name
              padding-top 56px
              font-size 36px
              height 46px
              font-family ZhenyanGB
              color #FFFFFF
              margin-bottom 16px
            .title
              color #FFFFFF
              font-size 14px
              line-height 21px
              margin-bottom 8px
            .btn-wechat
              color #2988FC
              font-size 12px
              text-align center
              width 82px
              height 28px
              line-height 28px
              background #FFFFFF
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1);
              opacity 1
              margin-top 8px
              border-radius 4px
            .img-box
              display flex
              justify-content flex-end
              position relative
              .vote-logo
                width 59px
                height 59px
                margin-top 50px
              .move-vote1-icon
                width 170px
                margin-top -40px
                margin-right 3px
            .vote-circular-box
              display flex
              justify-content flex-end
              .vote-circular
                margin-top -50px
                width 182px
                height 73px
        .move-footer-img-box
          .move-vote3-icon
            margin-top -70px
            width 100%
        .establish-active
          margin-bottom 54px
          .content-img
            display flex
            align-items center
            .tips-flow
              width 45px
              height 48px
              margin-left 42px
            .name
              color #303133
              font-size 20px
              font-weight bold
              margin-left 6px
          .establish-active-box
            background #2988FC
            opacity 1
            border-radius 12px
            padding 24px 0
            .title
              padding 0 44px
              font-size 14px
              line-height 21px
              margin-bottom 8px
              color #FFFFFF
            .btn-apply
              font-size 12px
              color #2988FC
              text-align center
              width 77px
              height 28px
              line-height 28px
              background #FFFFFF
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
              opacity 1
              margin-top 8px
              margin-left 44px
              border-radius 4px
            .midden-vote
              width 100%
        .demand-box
          .content-box
            padding 0 73px
            .name
              margin 10px 0 12px 0
              color #303133
              font-size 20px
              font-weight bold
            .title
              font-size 14px
              line-height 24px
              margin-bottom 12px
              color #303133
            .btn-demand
              color #FFFFFF
              font-size 12px
              text-align center
              width 77px
              height 27px
              line-height 27px
              background #2988FC
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
              opacity 1
              margin-top 8px
              border-radius 4px

          .demand-img-box
            display flex
            justify-content center
            margin-bottom 45px
            .demand2-icon
              width 288px
              height 288px
              margin-top 30px
              position absolute
            .demand1-icon
              position absolute
              width 157px
              height 254px
              margin-left -92px
              margin-top 32px
            .demand3-icon
              position relative
              width 327px
              margin-top 40px
        .cms-box
          background #2988FC
          opacity 1
          border-radius 32px 32px 0px 0px
          .content-box
            padding 64px 28px 0 28px
            .name
              font-weight bold
              color #FFFFFF
              height 31px
              margin-bottom 20px
              font-size 20px
            .title
              font-size 14px
              line-height 21px
              margin-bottom 12px
              color #FFFFFF
            .btn-apply
              color #2988FC
              font-size 12px
              text-align center
              width 87px
              height 31px
              line-height 31px
              background #FFFFFF
              box-shadow 0px 3px 6px rgba(4, 30, 103, 0.1)
              opacity 1
              margin-top 12px
              border-radius 4px
          .move-vote-icon
            width 100%
            margin-top 29px
            margin-bottom 30px
</style>
