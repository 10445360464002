import Vue from 'vue'
import VueRouter from 'vue-router'
import Navigation from '../views/navigation.vue'
import Case from '../views/case.vue'
import Index from '../views/index.vue'
import CaseDetails from '../views/caseDetails.vue'
import DataCenter from '../views/dataCenter.vue'
import DataCenterDetail from '../views/dataCenterDetail.vue'
import VoteTool from '../views/voteTool.vue'
import ActiveTool from '../views/activeTool.vue'
import SecondHandTool from '../views/secondHandTool.vue'
import DevelopmentHistory from '../views/developmentHistory.vue'
import ResourceEntry from '../views/resourceEntry.vue'
import MerchantSponsorship from '../views/merchantSponsorship.vue'
import CampusActivities from '../views/campusActivities.vue'
import EnterpriseDetails from '../views/enterpriseDetails.vue'
import CampusActivitiesDetail from '../views/campusActivitiesDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
    name: 'Navigation',
    component: Navigation,
    children: [
      {
        path: '/Index',
        name: 'Index',
        component: Index,
        meta: { title: '天率校园首页' }
      },
      {
        path: '/Case',
        name: 'Case',
        component: Case,
        meta: { title: '天率校园案例' }
      },
      {
        path: '/CaseDetails',
        component: CaseDetails,
        meta: {
          title: '商品详情'
        }
      },
      {
        path: '/DataCenter',
        component: DataCenter,
        meta: {
          title: '数据中心',
          keepAlive: true // 需要被缓存
        }
      },
      {
        path: '/DataCenterDetail',
        component: DataCenterDetail,
        meta: {
          title: '数据中心'
        }
      },
      {
        path: '/VoteTool',
        component: VoteTool,
        meta: {
          title: '产品介绍'
        }
      },
      {
        path: '/ActiveTool',
        component: ActiveTool,
        meta: {
          title: '活动工具'
        }
      },
      {
        path: '/SecondHandTool',
        component: SecondHandTool,
        meta: {
          title: '二手市场'
        }
      },
      {
        path: '/DevelopmentHistory',
        component: DevelopmentHistory,
        meta: {
          title: '发展历程'
        }
      },
      {
        path: '/ResourceEntry',
        component: ResourceEntry,
        meta: {
          title: '资源入驻'
        }
      },
      {
        path: '/MerchantSponsorship',
        component: MerchantSponsorship,
        meta: {
          title: '商家赞助'
        }
      },
      {
        path: '/CampusActivities',
        component: CampusActivities,
        meta: {
          title: '校园活动'
        }
      },
      {
        path: '/EnterpriseDetails',
        component: EnterpriseDetails,
        meta: {
          title: '商家赞助详情'
        }
      },
      {
        path: '/CampusActivitiesDetail',
        component: CampusActivitiesDetail,
        meta: {
          title: '校园活动详情'
        }
      }


    ]
  }
  // {
  //   path: '/CaseDetails',
  //   component: CaseDetails,
  //   meta: {
  //     title: '商品详情'
  //   }
  // }
]

const router = new VueRouter({
  linkExactActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
