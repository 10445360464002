<template>
  <div id="index">
    <!--   pc,ipad, header-->
    <div class="header">
      <div class="header-content">
        <router-link to="/index" class="logo-cover-box">
          <img class="logo-cover" src="../assets/images/logo.png" alt="">
        </router-link>
        <div class="list">
          <router-link to="/index">
            <p class="list-title">首页</p>
          </router-link>
          <router-link to="/dataCenter">
            <p class="list-title">数据中心</p>
          </router-link>
          <div>
            <p class="list-title" @click="opentool">产品介绍</p>
            <div class="tool" v-show="istool">
              <div class="tool-name" @click="goVoteTool">投票工具</div>
              <div class="tool-name1" @click="goActiveTool">活动工具</div>
              <div class="tool-name1" @click="goSecondHandTool">二手工具</div>
            </div>
          </div>
          <router-link to="/case">
            <p class="list-title">成功案例</p>
          </router-link>
          <div class="sponsor-modular">
            <p class="list-title" @click="opentool">赞助活动</p>
            <div class="tool" v-show="istool">
              <div class="tool-name" @click="goMerchantSponsorship">商家赞助</div>
              <div class="tool-name1" @click="goCampusActivities">校园活动</div>
            </div>
          </div>
          <router-link to="/resourceEntry">
            <p class="list-title">资源入驻</p>
          </router-link>
          <router-link to="/developmentHistory">
            <p class="list-title">发展历程</p>
          </router-link>
        </div>
      </div>
    </div>
    <!--    移动端header-->
    <div class="mobile-terminal">
      <!--      <van-sticky :offset-top="0">-->
      <div class="all-pages">
        <router-link to="/index">
          <img class="logo-cover" src="../assets/images/logo.png" alt="">
        </router-link>
        <img class="see-more" @click="showPopup" src="../assets/images/see-more.png" alt="">
      </div>
      <!--      </van-sticky>-->
      <div class="specific" v-show="isshow">
        <router-link to="/index">
          <p @click="showPopup" class="list-title">首页</p>
        </router-link>
        <router-link to="/dataCenter">
          <p @click="showPopup" class="list-title">数据中心</p>
        </router-link>
        <van-collapse v-model="activeNames" :border="false">
          <van-collapse-item style="color: #ffffff;" :border="false" title="产品介绍" name="2">
            <router-link to="/voteTool">
            <p class="list" @click="goVoteTool">投票工具</p>
            </router-link>
            <router-link to="/activeTool">
            <p class="list" @click="goActiveTool">活动工具</p>
            </router-link>
            <router-link to="/secondHandTool">
            <p class="list" @click="goSecondHandTool">二手工具</p>
            </router-link>
          </van-collapse-item>
        </van-collapse>
        <router-link to="/case">
          <p @click="showPopup" class="list-title">成功案例</p>
        </router-link>
        <router-link to="/resourceEntry">
          <p @click="showPopup" class="list-title">资源入驻</p>
        </router-link>
        <router-link to="/developmentHistory">
          <p @click="showPopup" class="list-title">发展历程</p>
        </router-link>
      </div>
    </div>
    <!--    内容区  -->
    <div class="main-content">
      <router-view/>
    </div>   <!--    pc,footer-->
    <div class="footer">
      <div class="footer-nav-box">
        <div class="footer-left">
          <img class="footer-logo" src="../assets/images/footer-logo.png" alt="">
          <div class="mode">
            <span class="phone">010-81708868</span>
            <img class="wachat" src="../assets/images/wechat.png" alt="">
            <img class="qrcode" src="../assets/images/action_baner.png" alt="">
            <a href="https://weibo.com/u/6562694482" target="_blank">
              <img class="weibo-icon" src="../assets/images/weibo-icon.png" alt="">
            </a>
          </div>
          <p class="footer-information">北京天率娱乐有限公司 © 2018-2019京ICP备16003913号-2</p>
        </div>
        <div class="footer-right">
          <div class="join-us-box">
            <p class="footer-info-title">加入我们</p>
            <p class="footer-info">校园合伙人</p>
            <p class="footer-info">社会招聘</p>
          </div>
          <div class="join-us-box">
            <p class="footer-info-title">相关产品</p>
            <p class="footer-info">校园数据运营中心</p>
            <p class="footer-info">天率校园活动</p>
            <p class="footer-info">校研报告</p>
          </div>
          <div class="join-us-box">
            <p class="footer-info-title">友情链接</p>
            <p class="footer-info-link">妙策</p>
            <p class="footer-info-link">壹伴</p>
            <p class="footer-info-link">新榜</p>
            <p class="footer-info-link">嗨现场</p>
          </div>
          <div class="join-us-box">
            <p class="footer-info-title">联系我们</p>
            <p class="footer-info">电话：010-81708868</p>
            <p class="footer-info">邮箱：xiaoyuan@tianshuai.com</p>
            <p class="footer-info">地址：北京市昌平区TBD云集中心2号楼B座3层</p>
            <p class="footer-info">投诉反馈：feedback@tianshuai.com</p>
          </div>
        </div>
      </div>
    </div>
    <!--    ipad,移动端底部-->
    <div class="move-footer">
      <div class="list">
        <p class="move-phone">关注我们</p>
        <img class="move-wechat" @click="showWechatCode" src="../assets/images/wechat.png" alt="">
        <a href="https://weibo.com/u/6562694482" target="_blank">
          <img class="move-weibo" src="../assets/images/weibo.png" alt="">
        </a>
      </div>
      <van-collapse v-model="activeNames" :border="false" v-show="!isshow">
        <van-collapse-item style="color: #ffffff;" :border="false" title="加入我们" name="2">
          <p>校园合伙人</p>
          <p>社会招聘</p>
        </van-collapse-item>
        <van-collapse-item style="color: #ffffff;" :border="false" title="相关产品" name="3">
          <p>校园数据运营中心</p>
          <p>天率校园活动</p>
          <p>校研报告</p>
        </van-collapse-item>
        <van-collapse-item style="color: #ffffff;" :border="false" title="友情链接" name="4">
          <p>妙策</p>
          <p>壹伴</p>
          <p>新榜</p>
          <p>嗨现场</p>
        </van-collapse-item>
        <van-collapse-item style="color: #ffffff;" :border="false" title="联系我们" name="5">
          <p>电话：010-81708868</p>
          <p>邮箱：xiaoyuan@tianshuai.com</p>
          <p>地址：北京市昌平区TBD云集中心2号楼B座3层</p>
          <p>投诉反馈：feedback@tianshuai.com</p>
        </van-collapse-item>
      </van-collapse>
      <p class="mailbox">北京天率娱乐有限公司 © 2018-2019京ICP备16003913号-2</p>
    </div>
    <!--   二维码弹窗   -->
    <van-popup class="wechat-code-popup" style="background-color:rgba(255, 255, 255, 0);overflow:inherit"
               v-model="iscodeShow">
      <div class="content-box">
        <img class="code-cover" alt src="../assets/images/action_baner.png"/>
        <p>长按保存或识别二维码</p>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    name: 'Navigation',
    data () {
      return {
        istool: false, // 是否显示产品介绍
        isshow: false, // 是否显示移动端下拉列表
        activeNames: ['1'],
        iscodeShow: false // 二维码弹窗
        // isfooter: true // 在数据中心页面显示头部 不显示底部footer和header
      }
    },
    methods: {
      // 跳转到投票票工具页
      goVoteTool () {
        this.istool = !this.istool
        this.isshow = !this.isshow
        this.$router.push({
          path: '/voteTool'
        }).then()
      },
      // 跳转到活动工具页面
      goActiveTool () {
        this.istool = !this.istool
        this.isshow = !this.isshow
        this.$router.push({
          path: '/activeTool'
        }).then()
      },
      // 跳转到二手市场页面
      goSecondHandTool () {
        this.istool = !this.istool
        this.isshow = !this.isshow
        this.$router.push({
          path: '/secondHandTool'
        }).then()
      },
      // 跳转到商家赞助页面
      goMerchantSponsorship () {
        this.istool = !this.istool
        this.isshow = !this.isshow
        this.$router.push({
          path: '/MerchantSponsorship'
        }).then()
      },
      // 跳转到校园活动
      goCampusActivities () {
        this.istool = !this.istool
        this.isshow = !this.isshow
        this.$router.push({
          path: '/CampusActivities'
        }).then()
      },
      opentool () {
        this.istool = !this.istool
      },
      showPopup: function () {
        this.isshow = !this.isshow
        if (this.isshow === true) {
          document.querySelector('.all-pages').style.backgroundColor = 'rgba(255, 255, 255, 255)'
        } else {
          document.querySelector('.all-pages').style.backgroundColor = 'rgba(255, 255, 255, 0)'
        }
      },
      showWechatCode () {
        this.iscodeShow = true
      },
      scrollWindow () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (this.$route.path !== '/index' && this.$route.path !== '/voteTool' && this.$route.path !== '/activeTool' && this.$route.path !== '/secondHandTool' && this.$route.path !== '/developmentHistory' || scrollTop >= 500) {
          document.querySelector('.header').style.backgroundColor = 'rgba(255, 255, 255, 255)'
          document.querySelector('.all-pages').style.backgroundColor = 'rgba(255, 255, 255, 255)'
        } else {
          document.querySelector('.header').style.backgroundColor = `rgba(255, 255, 255,${(scrollTop / 500) * 1})`
          document.querySelector('.all-pages').style.backgroundColor = `rgba(255, 255, 255,${(scrollTop / 500) * 1})`
        }
      }
    },
    mounted () {
      this.$nextTick(() => {
        // 添加滚动事件
        window.addEventListener('scroll', this.scrollWindow)
      })
      if (this.$route.path === '/index' || this.$route.path === '/voteTool' || this.$route.path === '/activeTool' || this.$route.path === '/secondHandTool' || this.$route.path === '/developmentHistory') {
        document.querySelector('.header').style.backgroundColor = 'rgba(255, 255, 255, 0)'
        document.querySelector('.all-pages').style.backgroundColor = 'rgba(255, 255, 255, 0)'
      } else {
        document.querySelector('.header').style.backgroundColor = 'rgba(255, 255, 255, 255)'
        document.querySelector('.all-pages').style.backgroundColor = 'rgba(255, 255, 255, 255)'
      }
    },
    watch: {
      $route: function () {
        // console.log(this.$route.path)
        // if (this.$route.path === '/dataCenter') {
        //   this.isfooter = false
        // } else {
        //   this.isfooter = true
        // }
        if (this.$route.path === '/index' || this.$route.path === '/voteTool' || this.$route.path === '/activeTool' || this.$route.path === '/secondHandTool' || this.$route.path === '/developmentHistory') {
          document.querySelector('.header').style.backgroundColor = 'rgba(255, 255, 255, 0)'
          document.querySelector('.all-pages').style.backgroundColor = 'rgba(255, 255, 255, 0)'
        } else {
          document.querySelector('.header').style.backgroundColor = 'rgba(255, 255, 255, 255)'
          document.querySelector('.all-pages').style.backgroundColor = 'rgba(255, 255, 255, 255)'
        }
      }
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #index
      display flex
      flex-direction column
      min-height 100vh
      .main-content
        flex 1
      .header
        position fixed
        top 0
        left 0
        width 100%
        height 54px
        background-color #ffffff
        opacity 1
        line-height 54px
        z-index 999

        .header-content
          width 1320px
          margin 0 auto
          display flex
          justify-content space-between
          align-items center

          .logo-cover
            height 34px
            cursor pointer
            display flex

          .list
            display flex

            .list-title
              margin-left 62px
              color #3A3A3A
              font-size 14px
              cursor pointer
              transition all 0.3s linear

            .list-title:hover
              color #2988fc

            .tool
              width 136px
              height 137px
              background rgba(0, 0, 0, 0.6)
              opacity 1
              border-radius 20px
              position absolute
              margin-left 28px
              margin-bottom 20px
              display none

              .tool-name
                color #FFFFFF
                font-size 16px
                text-align center
                height 22px
                line-height 22px
                cursor pointer
                margin-top 30px
                margin-bottom 10px

              .tool-name1
                color #FFFFFF
                font-size 16px
                text-align center
                height 30px
                line-height 22px
                cursor pointer

            .tool:hover
              display block

            .list-title:hover + .tool
              display block

          .router-link-active .list-title
            color #2988FC

      .footer
        background #303133
        min-width 1320px
        opacity 1

        .footer-nav-box
          width 1320px
          margin 0 auto
          padding 64px 0
          display flex
          justify-content space-between

          .footer-left
            .footer-logo
              height 48px

            .mode
              display flex
              margin-top 16px
              justify-items center
              align-items center

              .phone
                color #FFFFFF
                font-size 20px
                height 28px
                line-height 26px

              .qrcode
                height 176px
                width 176px
                left 0
                display none
                position absolute
                margin-top -182px
                margin-left 480px
                transition opacity 0.2s linear

              .weibo-icon
                width 28px
                height 28px
                opacity 0.8
                transition all 0.3s linear
                cursor pointer
                margin-left 8px

              .wachat
                width 28px
                height 28px
                margin-left 48px
                opacity 0.8
                transition all 0.3s linear
                cursor pointer

                &:hover
                  opacity 1

              .wachat:hover + .qrcode
                display block

            .footer-information
              color rgba(255, 255, 255, 0.6)
              font-size 12px
              margin-top 38px

          .footer-right
            display flex

            .join-us-box
              margin-left 84px

              .footer-info-title
                font-size 14px
                color rgba(255, 255, 255, 0.8)
                margin-bottom 20px

              .footer-info
                font-size 12px
                color #ffffff
                opacity 0.6
                margin-bottom 12px

              .footer-info-link
                font-size 12px
                color #ffffff
                opacity 0.6
                margin-bottom 12px
                transition all 0.3s linear
                cursor pointer

                &:hover
                  opacity 1

      .mobile-terminal
        display none

      .move-footer
        display none

  @media all and (max-width: 1024px)
    #index
      display flex
      flex-direction column
      min-height 100vh
      .main-content
        flex 1
      .header
        position fixed
        top 0
        left 0
        width 100%
        height 60px
        background #FFFFFF
        opacity 1
        z-index 99999

        .header-content
          display flex
          padding 0 28px
          justify-content space-between
          align-items center
          line-height 60px
          .logo-cover-box
            line-height 28px
            .logo-cover
              height 38px

          .list
            display flex

            .list-title
              margin-left 24px
              color #3A3A3A
              font-size 14px

            .sponsor-modular
              display none
            .tool
              width 136px
              height 134px
              background rgba(0, 0, 0, 0.4)
              opacity 1
              border-radius 20px
              position absolute
              margin-left -14px
              margin-bottom 20px

              .tool-name
                color #fff
                font-size 16px
                text-align center
                height 22px
                line-height 22px
                cursor pointer
                margin-top 30px
                margin-bottom 10px

              .tool-name1
                color #fff
                font-size 16px
                text-align center
                height 30px
                line-height 22px
                cursor pointer

          .router-link-active .list-title
            color #2988FC

      .footer
        display none

      .move-footer
        background #303133
        opacity 1
        padding 24px 28px 16px 28px

        .list
          margin 11px 0
          display flex
          align-items center

          .move-phone
            font-size 14px
            color rgba(255, 255, 255, 0.8)
            margin-right 48px
            margin-left 2px

          .move-wechat
            width 28px
            height 28px
            margin-right 36px

          .move-weibo
            width 28px
            height 28px

        .van-cell
          background #303133 !important
          border 1px solid #303133
          padding 11px 0

        .van-collapse-item__content
          background #303133

        .van-cell__title span
          color: rgba(255, 255, 255, 0.8);
          font-size 14px

        .van-cell__right-icon
          width 24px
          font-size 20px

        .van-collapse-item__content
          padding 0

        .van-collapse-item__content p
          padding 10px 10px
          font-size 12px
          color rgba(255, 255, 255, 0.6)

        .mailbox
          font-size 11px
          color rgba(255, 255, 255, 0.5)
          margin-top 17px
          text-align center

      .wechat-code-popup
        background-color rgba(255, 255, 255, 0)
        overflow inherit

        .content-box
          display flex
          flex-direction column

          .code-cover
            width 176px
            height 176px

          p
            margin-top 8px
            text-align center
            font-size 14px
            color rgba(255, 255, 255, 0.8)

  @media all and (min-width: 768px)
    .mobile-terminal
      display none

  @media all and (max-width: 767px)
    .header
      display none

    .mobile-terminal
      position fixed
      width 100%
      z-index 999

      .all-pages
        display flex
        justify-content space-between
        height 40px
        background-color #ffffff
        opacity 1
        align-items center
        padding 0 16px

        .logo-cover
          height 28px

        .see-more
          width 28px
          height 28px

      .specific
        padding-top 64px
        position fixed
        top 40px
        width 100%
        height 49rem
        text-align center
        background #FFFFFF
        z-index 1000
        line-height 56px
        color #666666
        font-size 14px
        .list-title
          text-align left
          padding-left 32px
        .router-link-active .list-title
          color #2988FC
        .list-title:hover
          background #F0F6FF
          color #2988FC
        .van-cell--clickable
          display flex
          justify-content space-between
        .van-cell--clickable:hover
          background #F0F6FF
        .van-cell
          padding 12px 32px
          .van-cell__title
            flex none
        .list
          color #606266
          font-size 14px
          text-align left
          padding 11px 30px
        .router-link-active .list
          color #2988FC
        .list:hover
          background #F0F6FF
          color #2988FC
      .active-list
        width 100px
        margin auto
        padding-left 30px
        line-height 34px

    .move-footer
      padding 24px 16px 16px 16px

      .list
        .move-phone
          margin-right 24px

        .move-wechat
          width 24px
          height 24px
          margin-right 16px

        .move-weibo
          width 24px
          height 24px

      .van-cell__right-icon
        font-size 16px
</style>
