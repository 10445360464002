<template>
  <div id="history">
    <!--    pc 头部-->
    <div class="history-headline">
      <img class="ic-back" src="../assets/images/ic-back.png" alt="">
      <div class="headline-content">
        <div class="headline-content-left">
          <p class="title">为企业品牌年轻化赋能</p>
          <p class="small-title">十年高效市场营销经验，深刻洞察校园用户群体</p>
          <p class="about-us">进一步了解我们</p>
          <img class="ic-flow" src="../assets/images/ic-flow.png" alt="">
        </div>
        <div class="headline-content-right">
          <img class="ic-blackboard" src="../assets/images/ic-blackboard.png" alt="">
          <div class="list-top">
            <img class="ic-icon" src="../assets/images/ic-icon.png" alt="">
            <img class="ic-letter" src="../assets/images/ic-letter.png" alt="">
            <img class="ic-book" src="../assets/images/ic-book.png" alt="">
            <img class="ic-logo" src="../assets/images/ic-logo.png" alt="">
          </div>
          <div class="list-bottom">
            <img class="ic-girl" src="../assets/images/ic-girl.png" alt="">
            <div class="list-style">
              <img class="ic-look-book" src="../assets/images/ic-look-book.png" alt="">
            </div>
            <img class="ic-boy" src="../assets/images/ic-boy.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--    ipad 移动 头部-->
    <div class="history-headline-move">
      <img class="ic-back" src="../assets/images/ic-back.png" alt="">
      <div class="headline-content">
        <div class="headline-content-top">
          <p class="title">为企业品牌年轻化赋能</p>
          <p class="small-title">十年高效市场营销经验，深刻洞察校园用户群体</p>
          <p class="about-us">进一步了解我们</p>
        </div>
        <div class="headline-content-bottom">
          <img class="ic-blackboard" src="../assets/images/ic-blackboard.png" alt="">
          <div class="list-top">
            <img class="ic-icon" src="../assets/images/ic-icon.png" alt="">
            <img class="ic-letter" src="../assets/images/ic-letter.png" alt="">
            <img class="ic-book" src="../assets/images/ic-book.png" alt="">
            <img class="ic-logo" src="../assets/images/ic-logo.png" alt="">
          </div>
          <div class="list-bottom">
            <img class="ic-flow" src="../assets/images/ic-flow.png" alt="">
            <img class="ic-girl" src="../assets/images/ic-girl.png" alt="">
            <div class="list-style">
              <img class="ic-look-book" src="../assets/images/ic-look-book.png" alt="">
            </div>
            <img class="ic-boy" src="../assets/images/ic-boy.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--    pc,ipad大事记-->
    <div class="his-content-box">
      <div class="content-back-box">
        <div class="back-img-left">
          <div class="img-product"></div>
          <div class="left-circular"></div>
          <div class="right-circular"></div>
        </div>
      </div>
      <p class="title">大事记</p>
      <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" ref="swiper" @change="onChange"
                 :show-indicators="false">
        <van-swipe-item v-for="(item,index) in caseInfo" :key="index.id">
          <div class="case-content">
            <div class="logo-circular-box">
              <img class="logo-circular" src="../assets/images/logo-circular.png" alt="">
            </div>
            <div class="brief-introduction">
              <p class="time">{{item.time}}</p>
              <p class="content-title">{{item.title}}</p>
              <p class="content-introduce">{{item.introduce}}</p>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <!--      时间线-->
      <div class="time-axis">
        <div class="time-box" v-for="(item,index) in caseInfo" :key="index.id" @mouseover="swipeTap(index)"
             @mouseout="swipeTaps(index)">
          <div class="style" :class="{activeStyle:index==isActive}">
            <p class="list"></p>
            <p class="axis" :class="{active:index==isActive}"></p>
            <p class="list"></p>
          </div>
          <div class="select" :class="{selectActive:index==isActive}">
            <p class="time" :class="{activeTime:index==isActive}">{{item.time}}</p>
          </div>
        </div>
      </div>
    </div>
    <!--    移动端 大事记-->
    <div class="move-content">
      <p class="title">大事记</p>
      <!--      时间线-->
      <div class="time-axis">
        <div class="time-box" v-for="(item,index) in caseInfo" :key="index.id" @mouseover="moveSwipeTap(index)"
             @mouseout="swipeTaps(index)">
          <div class="style" :class="{activeStyle:index==isActive}">
            <p class="list"></p>
            <p class="axis" :class="{active:index==isActive}"></p>
            <p class="list"></p>
          </div>
          <div class="select" :class="{selectActive:index==isActive}">
            <p class="time" :class="{activeTime:index==isActive}">{{item.time}}</p>
          </div>
        </div>
      </div>
      <div class="shadow">
        <p class="left-shadow"></p>
        <p class="right-shadow"></p>
      </div>
      <!--      内容-->
      <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" ref="moveSwiper" @change="onChange" :show-indicators="false">
        <van-swipe-item v-for="(item,index) in caseInfo" :key="index.id">
          <div class="phase-history-box">
            <div class="title-box">
              <img class="logo" src="../assets/images/logo-circular.png" alt="">
              <p class="name">{{item.time}}</p>
            </div>
            <p class="list">{{item.title}}</p>
            <p class="introduce">{{item.introduce}}</p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        autoplay: 3000, // 时间间隔
        isActive: 0, // 默认选中
        caseInfo: [
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2021年',
            title: '天率校园更名校元，同时上线大数据平台第二代版本\n校元·二手市场小程序上线\n校元·投票小程序上线\n校元·赛事小程序上线',
            introduce: '校元大数据平台是面向品牌的数据信息基础平台。实时采集、统计、分类、存储、查询、展示数据，高效积累数据资产，赋能品牌营销场景，助力品牌构建扎实的数据根基，实现品牌年轻化'
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2020年',
            title: '天率校园大数据平台第一代版本上线',
            introduce: '天率娱乐赛事《TOO TOP》全国校园歌手大赛在新中关购物中心举办全国总决赛'
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2019年',
            title: 'NEXT IDOL校园街舞大赛在总决赛在京举办\n天率自主娱乐赛事—《说唱校园总决赛》全国校园说唱比赛十强出炉\n天率自主娱乐赛事—《音为校园》欢乐谷总决赛开赛\n天率广告荣获金远奖行业奖项2项金奖',
            introduce: ''
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2018年',
            title: '天率广告与火山小视频在北京举办“火山达人秀”总决赛\n天率广告在国家体育馆举办抖音iDouSchool全国高校街舞争霸赛，同年在首都体育馆举办抖音校园音乐梦想赛总决赛，莅临总决赛的嘉宾有吴克群、陈翔、郭聪明',
            introduce: '天率广告与酷狗音乐启动全国校园“星梦计划”，赞助全国500所大学的音乐活动，帮助有音乐梦想的大学生们实现音乐梦想'
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2017年',
            title: '天率广告与酷狗音乐展开战略合作，首度开启酷狗校园俱乐部，在中国的各大高学建立自品牌俱乐部\n天率科技开启了创建自己的移动互联网平台，并开启校园营销新模式\n天率广告推出演说类自主品牌赛事《一等讲》，在简言阅读会的大力支持下顺利举办，并活动BTV全程报告',
            introduce: ''
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2016年',
            title: '天率广告与乐视发布了乐视全国校园战略\n天率广告与天猫、搜狗共同举办《betterme全国校园服装搭配大赛》并请到知名艺人张一山担任嘉宾\n天率广告与网易传媒联合华泰证券共同举办“易·泰嘉年华”，为期15天的时间，完成了全国35个城市257所高校的路演',
            introduce: ''
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2015年',
            title: '乐舞时代第三季与乐视体育合作，并宣布乐舞时代升级为乐舞校园，成全国校园界最有影响力的街舞比赛\n天率与一加手机建立战略合作并启动校园演说类节目《一加讲》，并知名作家携手蒋方舟、苏红以及一加手机创始人兼CEO刘作虎等知名企业家举行全国巡回演说\n北京天率广告有限公司成立',
            introduce: ''
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2014年',
            title: '乐舞时代第二季与网易云音乐联手打造中国校园街舞比赛\n天率与网易云阅读携手罗振宇、马家辉、蒋方舟、吴晓波、马伯庸、张君宝、李银河等知名作家先后走进中国各大高校\n天率又创建了一个娱乐品牌赛事：新声时代-中国高校全明星校园歌手大赛，并得到了一加手机的冠名',
            introduce: ''
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2013年',
            title: '天率创建了自主娱乐品牌：乐舞时代-首个联合中国各大高校舞蹈大赛，是至今高校间规模最大的街舞赛事\n天率与网易财经达成合作，先后携手财经作家周其仁、江春泽、张维迎等进入北大、清华、人大等知名高校',
            introduce: ''
          },
          {
            logo: require('../assets/images/logo-circular.png'),
            time: '2012年',
            title: '天率在中关村创建了专属于大学生的电子商务平台-天率商城',
            introduce: '上线大学生社区平台-乐族时代'
          }
        ]
      }
    },
    methods: {
      swipeTap (index) {
        this.$refs.swiper.swipeTo(index)
        this.autoplay = 20000
        this.isActive = index
      },
      moveSwipeTap (index) {
        this.$refs.moveSwiper.swipeTo(index)
        this.autoplay = 20000
        this.isActive = index
      },
      swipeTaps () {
        this.autoplay = 3000
      },
      onChange (index) {
        this.isActive = index
      }
    },
    mounted () {
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @import '../assets/font/font.css';
  @media all and (min-width: 1025px)
    #history
      .history-headline
        background #FFFFFF

        .ic-back
          background 100%
          width 1224px
          position absolute
          right 0

        .headline-content
          display flex
          width 1320px
          padding-top 116px
          margin auto
          position relative

          .headline-content-left
            .title
              color #3A3A3A
              font-size 48px
              font-family ZhenyanGB
              height 60px
              line-height 60px
              margin-top 127px

            .small-title
              color #3A3A3A
              margin-top 8px
              font-size 18px
              letter-spacing 4px
              font-weight 500

            .about-us
              color #FFFFFF
              height 45px
              line-height 45px
              background #2988FC
              width 184px
              text-align center
              letter-spacing 2px
              border-radius 2px
              margin-top 36px
              cursor pointer

            .ic-flow
              width 124px
              height 132px
              object-fit cover
              margin-top 71px
              margin-left 364px

          .headline-content-right
            .ic-blackboard
              width 521px
              height 347px
              margin-left 226px

            .list-top
              display flex
              margin-top -344px

              .ic-icon
                width 93px
                height 93px
                margin-right 45px
                margin-left 116px
                margin-top 24px
                position absolute
                animation icIcon 3s ease infinite
                @keyframes icIcon {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-letter
                width 130px
                height 142px
                position absolute
                margin-left 263px
                animation letter 3s ease infinite
                @keyframes letter {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-book
                width 94px
                height 101px
                margin-top 10px
                position absolute
                margin-left 550px
                animation book 3s ease infinite
                @keyframes book {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-logo
                width 106px
                height 96px
                margin-top 18px
                position absolute
                margin-left 750px
                animation icLogo 3s ease infinite
                @keyframes icLogo {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

            .list-bottom
              display flex
              margin-top 128px

              .ic-girl
                width 208px
                height 452px
                margin-top -23px
                margin-left 57px

              .list-style
                .ic-look-book
                  width 303px
                  height 268px
                  margin-top 124px
                  margin-left 117px

              .ic-boy
                width 234px
                height 452px
                margin-left -28px
                margin-top -98px

      .history-headline-move
        display none

      .his-content-box
        width 1320px
        margin auto

        .content-back-box
          position absolute

          .back-img-left
            display flex
            width 1320px
            position absolute
            margin-top -58px

            .img-product
              background url("../assets/images/img-product.png") no-repeat
              background-size 100% 100%
              width 289px
              height 466px

            .left-circular
              background url("../assets/images/left-circular.png") no-repeat
              background-size 100% 100%
              width 266px
              height 266px
              margin-top 204px
              margin-left -155px

            .right-circular
              background url("../assets/images/right-circular.png") no-repeat
              background-size 100% 100%
              position absolute
              right 0
              width 394px
              height 394px
              margin-top 90px

        .title
          color #303133
          font-size 42px
          text-align right
          margin-top 128px

        .my-swipe
          .case-content
            display flex
            height 286px
            justify-content center
            margin-top 48px
            position relative

            .logo-circular-box
              width 336px
              background #2988FC
              box-shadow 0px 3px 18px rgba(4, 30, 103, 0.1)
              opacity 1
              border-radius 12px 0px 0px 12px
              text-align center

              .logo-circular
                width 237px
                height 118px
                margin-top 70px

            .brief-introduction
              width 552px
              background #FFFFFF
              box-shadow 0px 3px 18px rgba(4, 30, 103, 0.1)
              opacity 1
              border-radius 0 12px 12px 0

              .time
                margin 36px 0 24px 32px
                font-family ZhenyanGB
                font-size 18px
                color #606266

              .content-title
                margin-left 32px
                margin-right 36px
                color #606266
                font-size 16px
                white-space pre-wrap
                line-height 22px

              .content-introduce
                color #606266
                font-size 16px
                margin 20px 36px 40px 32px
                line-height 22px

                .logo-circular
                  width 237px
                  height 118px
                  margin-top 86px

        .time-axis
          margin 64px 0
          display flex
          justify-content center

          .time-box
            text-align center
            cursor pointer

            .activeStyle
              margin-top -4px

            .style
              display flex
              align-items center

              .list
                width 50px
                height 1px
                background #DDE0E3
                opacity 1

              .axis
                width 13px
                height 13px
                background #DDE0E3
                border-radius 50%
                opacity 1

              .active
                width 15px
                height 15px
                background #2988FC
                border-radius 50%
                border 3px solid #A6CCFB
                display inline-block

            .select
              margin-top 16px

              .time
                color #C0C4CC
                font-size 14px

              .activeTime
                color #2988FC
                font-size 18px

            .selectActive
              margin-top 10px

  @media all and (max-width: 1024px)
    #history
      .history-headline
        display none

      .history-headline-move
        background #ffffff

        .ic-back
          background 100%
          position absolute
          width 100%
          height 68%
          right 0

        .headline-content
          position relative

          .headline-content-top
            padding-left 28px

            .title
              color #3A3A3A
              font-size 48px
              height 60px
              line-height 60px
              font-family ZhenyanGB
              padding-top 108px

            .small-title
              color #3A3A3A
              font-size 18px
              font-weight 500
              letter-spacing 5px
              height 25px
              line-height 25px
              margin-top 8px

            .about-us
              color #FFFFFF
              width 184px
              height 45px
              line-height 45px
              text-align center
              background #2988FC
              margin-top 24px
              cursor pointer
              opacity 1
              border-radius 2px

          .headline-content-bottom
            .ic-blackboard
              width 54%
              height 40%
              margin-left: 28%;
              margin-top 6%

            .list-top
              display flex
              margin-top -324px

              .ic-icon
                width 10%
                height 10%
                margin-left 157px
                position absolute
                animation moveicIcon 3s ease infinite
                @keyframes moveicIcon {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-letter
                width 16%
                margin-left 33%
                margin-top 2%
                position absolute
                animation moveLetter 3s ease infinite
                @keyframes moveLetter {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-book
                width 11%
                height 13%
                margin-left 57%
                position absolute
                animation moveBook 3s ease infinite
                @keyframes moveBook {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-logo
                width 11%
                height 11%
                margin-left 82%
                position absolute
                animation moveLogo 3s ease infinite
                @keyframes moveLogo {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

            .list-bottom
              display flex
              margin-top 20%

              .ic-flow
                width 12%
                height 4%
                margin-top 25%

              .ic-girl
                width 22%
                height 9%
                margin-top -5%
                margin-left 2%

            .list-style
              .ic-look-book
                width 80%
                margin-top 44%
                margin-left 26%

            .ic-boy
              width 24%
              height 9%
              margin-top -9%
              padding-right 3%

      .his-content-box
        .content-back-box
          .back-img-left
            display flex
            width 100%
            position absolute
            margin-top -58px

            .img-product
              background url("../assets/images/img-product.png") no-repeat
              background-size 100% 100%
              width 116px
              height 265px
              margin-top 120px

            .left-circular
              background url("../assets/images/left-circular.png") no-repeat
              background-size 100% 100%
              width 143px
              height 143px
              margin-top 260px
              margin-left -90px

            .right-circular
              background url("../assets/images/right-circular.png") no-repeat
              background-size 100% 100%
              position absolute
              right 0
              width 212px
              height 208px
              margin-top 90px

        .title
          color #303133
          font-size 32px
          font-weight bold
          margin-top 54px
          text-align center

        .my-swipe
          .case-content
            display flex
            height 300px
            justify-content center
            margin-top 32px
            position relative

            .logo-circular-box
              width 35%
              background #2988FC
              box-shadow 0px 3px 18px rgba(4, 30, 103, 0.1)
              opacity 1
              border-radius 12px 0px 0px 12px
              text-align center

              .logo-circular
                width 224px
                height 111px
                margin-top 82px

            .brief-introduction
              width 56%
              background #FFFFFF
              border-radius 0 12px 12px 0

              .time
                margin 32px 0 24px 32px
                font-family ZhenyanGB
                color #606266
                font-size 18px

              .content-title
                margin 0 32px 24px 28px
                font-size 14px
                color #606266
                white-space pre-wrap
                line-height 22px

              .content-introduce
                margin 28px 32px 36px 28px
                color #606266
                font-size 16px
                white-space pre-wrap
                line-height 22px

        .time-axis
          margin 48px 28px
          display flex
          justify-content center

          .time-box
            text-align center

            .style
              display flex
              align-items center

              .list
                width 28px
                height 1px
                background #dde0e3
                opacity 1

              .axis
                width 13px
                height 13px
                background #DDE0E3
                border-radius 50%
                opacity 1

              .active
                width 15px
                height 15px
                background #2988FC
                border-radius 50%
                border 3px solid #A6CCFB
                display inline-block

            .select
              margin-top 16px

              .time
                color #C0C4CC
                font-size 14px

              .activeTime
                color #2988FC
                font-size 18px

            .selectActive
              margin-top 10px

            .activeStyle
              margin-top -4px

  @media all and (min-width: 768px)
    #history
      .move-content
        display none

  @media all and (max-width: 767px)
    #history
      .history-headline-move
        .ic-back
          height 428px

        .headline-content
          .headline-content-top
            padding-left 16px

            .title
              color #3A3A3A
              font-size 34px
              padding-top 72px
              height 43px
              line-height 43px

            .small-title
              color #3A3A3A
              font-size 12px
              letter-spacing 3px
              margin-top 6px

            .about-us
              width 131px
              height 32px
              line-height 32px
              background #2988FC
              opacity 1
              border-radius 2px
              font-size 12px
              color #FFFFFF

          .headline-content-bottom
            color #ffffff

            .ic-blackboard
              width 54%
              margin-top 8%

            .list-top
              margin-top -40%
              display block

              .ic-icon
                width 47px
                height 47px
                margin-left 19%
                margin-top 6%

              .ic-letter
                width 13%
                height 11%
                margin-left 34%
                margin-top 2%

              .ic-book
                width 10%
                height 10%
                margin-left 57%
                position absolute

              .ic-logo
                width 11%
                height 9%
                margin-left 82%
                position absolute

            .list-bottom
            .list-style
              .ic-look-book
                width 68%
                height 60%
                margin-top 41%
                margin-left 37%

      .his-content-box
        display none

      .move-content
        .title
          color #303133
          font-weight bold
          font-size 24px
          text-align center
          margin-top 36px
        .time-axis
          display flex
          overflow auto
          padding-top 32px
          .time-box
            text-align center
            cursor pointer
            .activeStyle
              margin-top -4px
            .style
              display flex
              align-items center
              .list
                width 30px
                height 1px
                background #DDE0E3
                opacity 1
              .axis
                width 13px
                height 13px
                background #DDE0E3
                border-radius 50%
                opacity 1
              .active
                width 15px
                height 15px
                background #2988FC
                border-radius 50%
                border 3px solid #A6CCFB
                display inline-block
            .select
              margin-top 16px
              .time
                color #C0C4CC
                font-size 14px
              .activeTime
                color #2988FC
                font-size 18px
            .selectActive
              margin-top 10px
        .time-axis::-webkit-scrollbar
          display none
        .shadow
          display flex
          position relative
          z-index 99
          .left-shadow
            position absolute
            width 45px
            height 63px
            background linear-gradient(270deg, rgba(250, 250, 250, 0) 0%, #FAFAFA 100%)
            opacity 1
            margin-top -66px
          .right-shadow
            width 45px
            height 63px
            opacity 1
            background linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, #FAFAFA 100%)
            position absolute
            right 0
            margin-top -66px
        .my-swipe
          .phase-history-box
            background #2988FC
            box-shadow 0px 3px 18px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 12px
            padding 12px
            margin 18px 16px 48px 16px
            height 238px

            .title-box
              display flex
              justify-content space-between
              align-items center
              margin-bottom 16px

              .logo
                width 68px
                height 34px

              .name
                font-family ZhenyanGB
                font-size 18px
                color #FFFFFF

            .list
              font-size 14px
              line-height 22px
              margin-bottom 18px
              color #FFFFFF

            .introduce
              font-size 14px
              line-height 22px
              color #FFFFFF
</style>
