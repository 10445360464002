export { addressInfo }
const addressInfo = [
  {
    value: '110000',
    label: '北京',
    children: [
      {
        value: '110100',
        label: '北京'
      }
    ]
  },
  {
    value: '120000',
    label: '天津',
    children: [
      {
        value: '120100',
        label: '天津'
      }
    ]
  },
  {
    value: '130000',
    label: '河北',
    children: [
      {
        value: '130100',
        label: '石家庄'
      },
      {
        value: '130200',
        label: '唐山'
      },
      {
        value: '130300',
        label: '秦皇岛'
      },
      {
        value: '130400',
        label: '邯郸'
      },
      {
        value: '130500',
        label: '邢台'
      },
      {
        value: '130600',
        label: '保定'
      },
      {
        value: '130700',
        label: '张家口'
      },
      {
        value: '130800',
        label: '承德'
      },
      {
        value: '130900',
        label: '沧州'
      },
      {
        value: '131000',
        label: '廊坊'
      },
      {
        value: '131100',
        label: '衡水'
      },
      {
        value: '139001',
        label: '定州'
      },
      {
        value: '139002',
        label: '辛集'
      }
    ]
  },
  {
    value: '140000',
    label: '山西',
    children: [
      {
        value: '140100',
        label: '太原'
      },
      {
        value: '140200',
        label: '大同'
      },
      {
        value: '140300',
        label: '阳泉'
      },
      {
        value: '140400',
        label: '长治'
      },
      {
        value: '140500',
        label: '晋城'
      },
      {
        value: '140600',
        label: '朔州'
      },
      {
        value: '140700',
        label: '晋中'
      },
      {
        value: '140800',
        label: '运城'
      },
      {
        value: '140900',
        label: '忻州'
      },
      {
        value: '141000',
        label: '临汾'
      },
      {
        value: '141100',
        label: '吕梁'
      }
    ]
  },
  {
    value: '150000',
    label: '内蒙古',
    children: [
      {
        value: '150100',
        label: '呼和浩特'
      },
      {
        value: '150200',
        label: '包头'
      },
      {
        value: '150300',
        label: '乌海'
      },
      {
        value: '150400',
        label: '赤峰'
      },
      {
        value: '150500',
        label: '通辽'
      },
      {
        value: '150600',
        label: '鄂尔多斯'
      },
      {
        value: '150700',
        label: '呼伦贝尔'
      },
      {
        value: '150800',
        label: '巴彦淖尔'
      },
      {
        value: '150900',
        label: '乌兰察布'
      },
      {
        value: '152200',
        label: '兴安盟'
      },
      {
        value: '152500',
        label: '锡林郭勒盟'
      },
      {
        value: '152900',
        label: '阿拉善盟'
      }
    ]
  },
  {
    value: '210000',
    label: '辽宁',
    children: [
      {
        value: '210100',
        label: '沈阳'
      },
      {
        value: '210200',
        label: '大连'
      },
      {
        value: '210300',
        label: '鞍山'
      },
      {
        value: '210400',
        label: '抚顺'
      },
      {
        value: '210500',
        label: '本溪'
      },
      {
        value: '210600',
        label: '丹东'
      },
      {
        value: '210700',
        label: '锦州'
      },
      {
        value: '210800',
        label: '营口'
      },
      {
        value: '210900',
        label: '阜新'
      },
      {
        value: '211000',
        label: '辽阳'
      },
      {
        value: '211100',
        label: '盘锦'
      },
      {
        value: '211200',
        label: '铁岭'
      },
      {
        value: '211300',
        label: '朝阳'
      },
      {
        value: '211400',
        label: '葫芦岛'
      }
    ]
  },
  {
    value: '220000',
    label: '吉林',
    children: [
      {
        value: '220100',
        label: '长春'
      },
      {
        value: '220200',
        label: '吉林'
      },
      {
        value: '220300',
        label: '四平'
      },
      {
        value: '220400',
        label: '辽源'
      },
      {
        value: '220500',
        label: '通化'
      },
      {
        value: '220600',
        label: '白山'
      },
      {
        value: '220700',
        label: '松原'
      },
      {
        value: '220800',
        label: '白城'
      },
      {
        value: '222400',
        label: '延边朝鲜族自治州'
      }
    ]
  },
  {
    value: '230000',
    label: '黑龙江',
    children: [
      {
        value: '230100',
        label: '哈尔滨'
      },
      {
        value: '230200',
        label: '齐齐哈尔'
      },
      {
        value: '230300',
        label: '鸡西'
      },
      {
        value: '230400',
        label: '鹤岗'
      },
      {
        value: '230500',
        label: '双鸭山'
      },
      {
        value: '230600',
        label: '大庆'
      },
      {
        value: '230700',
        label: '伊春'
      },
      {
        value: '230800',
        label: '佳木斯'
      },
      {
        value: '230900',
        label: '七台河'
      },
      {
        value: '231000',
        label: '牡丹江'
      },
      {
        value: '231100',
        label: '黑河'
      },
      {
        value: '231200',
        label: '绥化'
      },
      {
        value: '232700',
        label: '大兴安岭地区'
      }
    ]
  },
  {
    value: '310000',
    label: '上海',
    children: [
      {
        value: '310100',
        label: '上海'
      }
    ]
  },
  {
    value: '320000',
    label: '江苏',
    children: [
      {
        value: '320100',
        label: '南京'
      },
      {
        value: '320200',
        label: '无锡'
      },
      {
        value: '320300',
        label: '徐州'
      },
      {
        value: '320400',
        label: '常州'
      },
      {
        value: '320500',
        label: '苏州'
      },
      {
        value: '320600',
        label: '南通'
      },
      {
        value: '320700',
        label: '连云港'
      },
      {
        value: '320800',
        label: '淮安'
      },
      {
        value: '320900',
        label: '盐城'
      },
      {
        value: '321000',
        label: '扬州'
      },
      {
        value: '321100',
        label: '镇江'
      },
      {
        value: '321200',
        label: '泰州'
      },
      {
        value: '321300',
        label: '宿迁'
      }
    ]
  },
  {
    value: '330000',
    label: '浙江',
    children: [
      {
        value: '330100',
        label: '杭州'
      },
      {
        value: '330200',
        label: '宁波'
      },
      {
        value: '330300',
        label: '温州'
      },
      {
        value: '330400',
        label: '嘉兴'
      },
      {
        value: '330500',
        label: '湖州'
      },
      {
        value: '330600',
        label: '绍兴'
      },
      {
        value: '330700',
        label: '金华'
      },
      {
        value: '330800',
        label: '衢州'
      },
      {
        value: '330900',
        label: '舟山'
      },
      {
        value: '331000',
        label: '台州'
      },
      {
        value: '331100',
        label: '丽水'
      }
    ]
  },
  {
    value: '340000',
    label: '安徽',
    children: [
      {
        value: '340100',
        label: '合肥'
      },
      {
        value: '340200',
        label: '芜湖'
      },
      {
        value: '340300',
        label: '蚌埠'
      },
      {
        value: '340400',
        label: '淮南'
      },
      {
        value: '340500',
        label: '马鞍山'
      },
      {
        value: '340600',
        label: '淮北'
      },
      {
        value: '340700',
        label: '铜陵'
      },
      {
        value: '340800',
        label: '安庆'
      },
      {
        value: '341000',
        label: '黄山'
      },
      {
        value: '341100',
        label: '滁州'
      },
      {
        value: '341200',
        label: '阜阳'
      },
      {
        value: '341300',
        label: '宿州'
      },
      {
        value: '341500',
        label: '六安'
      },
      {
        value: '341600',
        label: '亳州'
      },
      {
        value: '341700',
        label: '池州'
      },
      {
        value: '341800',
        label: '宣城'
      }
    ]
  },
  {
    value: '350000',
    label: '福建',
    children: [
      {
        value: '350100',
        label: '福州'
      },
      {
        value: '350200',
        label: '厦门'
      },
      {
        value: '350300',
        label: '莆田'
      },
      {
        value: '350400',
        label: '三明'
      },
      {
        value: '350500',
        label: '泉州'
      },
      {
        value: '350600',
        label: '漳州'
      },
      {
        value: '350700',
        label: '南平'
      },
      {
        value: '350800',
        label: '龙岩'
      },
      {
        value: '350900',
        label: '宁德'
      }
    ]
  },
  {
    value: '360000',
    label: '江西',
    children: [
      {
        value: '360100',
        label: '南昌'
      },
      {
        value: '360200',
        label: '景德镇'
      },
      {
        value: '360300',
        label: '萍乡'
      },
      {
        value: '360400',
        label: '九江'
      },
      {
        value: '360500',
        label: '新余'
      },
      {
        value: '360600',
        label: '鹰潭'
      },
      {
        value: '360700',
        label: '赣州'
      },
      {
        value: '360800',
        label: '吉安'
      },
      {
        value: '360900',
        label: '宜春'
      },
      {
        value: '361000',
        label: '抚州'
      },
      {
        value: '361100',
        label: '上饶'
      }
    ]
  },
  {
    value: '370000',
    label: '山东',
    children: [
      {
        value: '370100',
        label: '济南'
      },
      {
        value: '370200',
        label: '青岛'
      },
      {
        value: '370300',
        label: '淄博'
      },
      {
        value: '370400',
        label: '枣庄'
      },
      {
        value: '370500',
        label: '东营'
      },
      {
        value: '370600',
        label: '烟台'
      },
      {
        value: '370700',
        label: '潍坊'
      },
      {
        value: '370800',
        label: '济宁'
      },
      {
        value: '370900',
        label: '泰安'
      },
      {
        value: '371000',
        label: '威海'
      },
      {
        value: '371100',
        label: '日照'
      },
      {
        value: '371200',
        label: '莱芜'
      },
      {
        value: '371300',
        label: '临沂'
      },
      {
        value: '371400',
        label: '德州'
      },
      {
        value: '371500',
        label: '聊城'
      },
      {
        value: '371600',
        label: '滨州'
      },
      {
        value: '371700',
        label: '菏泽'
      }
    ]
  },
  {
    value: '410000',
    label: '河南',
    children: [
      {
        value: '410100',
        label: '郑州'
      },
      {
        value: '410200',
        label: '开封'
      },
      {
        value: '410300',
        label: '洛阳'
      },
      {
        value: '410400',
        label: '平顶山'
      },
      {
        value: '410500',
        label: '安阳'
      },
      {
        value: '410600',
        label: '鹤壁'
      },
      {
        value: '410700',
        label: '新乡'
      },
      {
        value: '410800',
        label: '焦作'
      },
      {
        value: '410900',
        label: '濮阳'
      },
      {
        value: '411000',
        label: '许昌'
      },
      {
        value: '411100',
        label: '漯河'
      },
      {
        value: '411200',
        label: '三门峡'
      },
      {
        value: '411300',
        label: '南阳'
      },
      {
        value: '411400',
        label: '商丘'
      },
      {
        value: '411500',
        label: '信阳'
      },
      {
        value: '411600',
        label: '周口'
      },
      {
        value: '411700',
        label: '驻马店'
      },
      {
        value: '419001',
        label: '济源'
      }
    ]
  },
  {
    value: '420000',
    label: '湖北',
    children: [
      {
        value: '420100',
        label: '武汉'
      },
      {
        value: '420200',
        label: '黄石'
      },
      {
        value: '420300',
        label: '十堰'
      },
      {
        value: '420500',
        label: '宜昌'
      },
      {
        value: '420600',
        label: '襄阳'
      },
      {
        value: '420700',
        label: '鄂州'
      },
      {
        value: '420800',
        label: '荆门'
      },
      {
        value: '420900',
        label: '孝感'
      },
      {
        value: '421000',
        label: '荆州'
      },
      {
        value: '421100',
        label: '黄冈'
      },
      {
        value: '421200',
        label: '咸宁'
      },
      {
        value: '421300',
        label: '随州'
      },
      {
        value: '422800',
        label: '恩施土家族苗族自治州'
      },
      {
        value: '429004',
        label: '仙桃'
      },
      {
        value: '429005',
        label: '潜江'
      },
      {
        value: '429006',
        label: '天门'
      },
      {
        value: '429021',
        label: '神农架林区'
      }
    ]
  },
  {
    value: '430000',
    label: '湖南',
    children: [
      {
        value: '430100',
        label: '长沙'
      },
      {
        value: '430200',
        label: '株洲'
      },
      {
        value: '430300',
        label: '湘潭'
      },
      {
        value: '430400',
        label: '衡阳'
      },
      {
        value: '430500',
        label: '邵阳'
      },
      {
        value: '430600',
        label: '岳阳'
      },
      {
        value: '430700',
        label: '常德'
      },
      {
        value: '430800',
        label: '张家界'
      },
      {
        value: '430900',
        label: '益阳'
      },
      {
        value: '431000',
        label: '郴州'
      },
      {
        value: '431100',
        label: '永州'
      },
      {
        value: '431200',
        label: '怀化'
      },
      {
        value: '431300',
        label: '娄底'
      },
      {
        value: '433100',
        label: '湘西土家族苗族自治州'
      }
    ]
  },
  {
    value: '440000',
    label: '广东',
    children: [
      {
        value: '440100',
        label: '广州'
      },
      {
        value: '440200',
        label: '韶关'
      },
      {
        value: '440300',
        label: '深圳'
      },
      {
        value: '440400',
        label: '珠海'
      },
      {
        value: '440500',
        label: '汕头'
      },
      {
        value: '440600',
        label: '佛山'
      },
      {
        value: '440700',
        label: '江门'
      },
      {
        value: '440800',
        label: '湛江'
      },
      {
        value: '440900',
        label: '茂名'
      },
      {
        value: '441200',
        label: '肇庆'
      },
      {
        value: '441300',
        label: '惠州'
      },
      {
        value: '441400',
        label: '梅州'
      },
      {
        value: '441500',
        label: '汕尾'
      },
      {
        value: '441600',
        label: '河源'
      },
      {
        value: '441700',
        label: '阳江'
      },
      {
        value: '441800',
        label: '清远'
      },
      {
        value: '441900',
        label: '东莞'
      },
      {
        value: '442000',
        label: '中山'
      },
      {
        value: '445100',
        label: '潮州'
      },
      {
        value: '445200',
        label: '揭阳'
      },
      {
        value: '445300',
        label: '云浮'
      }
    ]
  },
  {
    value: '450000',
    label: '广西壮族',
    children: [
      {
        value: '450100',
        label: '南宁'
      },
      {
        value: '450200',
        label: '柳州'
      },
      {
        value: '450300',
        label: '桂林'
      },
      {
        value: '450400',
        label: '梧州'
      },
      {
        value: '450500',
        label: '北海'
      },
      {
        value: '450600',
        label: '防城港'
      },
      {
        value: '450700',
        label: '钦州'
      },
      {
        value: '450800',
        label: '贵港'
      },
      {
        value: '450900',
        label: '玉林'
      },
      {
        value: '451000',
        label: '百色'
      },
      {
        value: '451100',
        label: '贺州'
      },
      {
        value: '451200',
        label: '河池'
      },
      {
        value: '451300',
        label: '来宾'
      },
      {
        value: '451400',
        label: '崇左'
      }
    ]
  },
  {
    value: '460000',
    label: '海南',
    children: [
      {
        value: '460100',
        label: '海口'
      },
      {
        value: '460200',
        label: '三亚'
      },
      {
        value: '460300',
        label: '三沙'
      },
      {
        value: '460400',
        label: '儋州'
      },
      {
        value: '469001',
        label: '五指山'
      },
      {
        value: '469002',
        label: '琼海'
      },
      {
        value: '469005',
        label: '文昌'
      },
      {
        value: '469006',
        label: '万宁'
      },
      {
        value: '469007',
        label: '东方'
      },
      {
        value: '469021',
        label: '定安县'
      },
      {
        value: '469022',
        label: '屯昌县'
      },
      {
        value: '469023',
        label: '澄迈县'
      },
      {
        value: '469024',
        label: '临高县'
      },
      {
        value: '469025',
        label: '白沙黎族自治县'
      },
      {
        value: '469026',
        label: '昌江黎族自治县'
      },
      {
        value: '469027',
        label: '乐东黎族自治县'
      },
      {
        value: '469028',
        label: '陵水黎族自治县'
      },
      {
        value: '469029',
        label: '保亭黎族苗族自治县'
      },
      {
        value: '469030',
        label: '琼中黎族苗族自治县'
      }
    ]
  },
  {
    value: '500000',
    label: '重庆',
    children: [
      {
        value: '500100',
        label: '重庆'
      }
    ]
  },
  {
    value: '510000',
    label: '四川',
    children: [
      {
        value: '510100',
        label: '成都'
      },
      {
        value: '510300',
        label: '自贡'
      },
      {
        value: '510400',
        label: '攀枝花'
      },
      {
        value: '510500',
        label: '泸州'
      },
      {
        value: '510600',
        label: '德阳'
      },
      {
        value: '510700',
        label: '绵阳'
      },
      {
        value: '510800',
        label: '广元'
      },
      {
        value: '510900',
        label: '遂宁'
      },
      {
        value: '511000',
        label: '内江'
      },
      {
        value: '511100',
        label: '乐山'
      },
      {
        value: '511300',
        label: '南充'
      },
      {
        value: '511400',
        label: '眉山'
      },
      {
        value: '511500',
        label: '宜宾'
      },
      {
        value: '511600',
        label: '广安'
      },
      {
        value: '511700',
        label: '达州'
      },
      {
        value: '511800',
        label: '雅安'
      },
      {
        value: '511900',
        label: '巴中'
      },
      {
        value: '512000',
        label: '资阳'
      },
      {
        value: '513200',
        label: '阿坝藏族羌族自治州'
      },
      {
        value: '513300',
        label: '甘孜藏族自治州'
      },
      {
        value: '513400',
        label: '凉山彝族自治州'
      }
    ]
  },
  {
    value: '520000',
    label: '贵州',
    children: [
      {
        value: '520100',
        label: '贵阳'
      },
      {
        value: '520200',
        label: '六盘水'
      },
      {
        value: '520300',
        label: '遵义'
      },
      {
        value: '520400',
        label: '安顺'
      },
      {
        value: '520500',
        label: '毕节'
      },
      {
        value: '520600',
        label: '铜仁'
      },
      {
        value: '522300',
        label: '黔西南布依族苗族自治州'
      },
      {
        value: '522600',
        label: '黔东南苗族侗族自治州'
      },
      {
        value: '522700',
        label: '黔南布依族苗族自治州'
      }
    ]
  },
  {
    value: '530000',
    label: '云南',
    children: [
      {
        value: '530100',
        label: '昆明'
      },
      {
        value: '530300',
        label: '曲靖'
      },
      {
        value: '530400',
        label: '玉溪'
      },
      {
        value: '530500',
        label: '保山'
      },
      {
        value: '530600',
        label: '昭通'
      },
      {
        value: '530700',
        label: '丽江'
      },
      {
        value: '530800',
        label: '普洱'
      },
      {
        value: '530900',
        label: '临沧'
      },
      {
        value: '532300',
        label: '楚雄彝族自治州'
      },
      {
        value: '532500',
        label: '红河哈尼族彝族自治州'
      },
      {
        value: '532600',
        label: '文山壮族苗族自治州'
      },
      {
        value: '532800',
        label: '西双版纳傣族自治州'
      },
      {
        value: '532900',
        label: '大理白族自治州'
      },
      {
        value: '533100',
        label: '德宏傣族景颇族自治州'
      },
      {
        value: '533300',
        label: '怒江傈僳族自治州'
      },
      {
        value: '533400',
        label: '迪庆藏族自治州'
      }
    ]
  },
  {
    value: '540000',
    label: '西藏',
    children: [
      {
        value: '540100',
        label: '拉萨'
      },
      {
        value: '540200',
        label: '日喀则'
      },
      {
        value: '540300',
        label: '昌都'
      },
      {
        value: '540400',
        label: '林芝'
      },
      {
        value: '540500',
        label: '山南'
      },
      {
        value: '542400',
        label: '那曲地区'
      },
      {
        value: '542500',
        label: '阿里地区'
      }
    ]
  },
  {
    value: '610000',
    label: '陕西',
    children: [
      {
        value: '610100',
        label: '西安'
      },
      {
        value: '610200',
        label: '铜川'
      },
      {
        value: '610300',
        label: '宝鸡'
      },
      {
        value: '610400',
        label: '咸阳'
      },
      {
        value: '610500',
        label: '渭南'
      },
      {
        value: '610600',
        label: '延安'
      },
      {
        value: '610700',
        label: '汉中'
      },
      {
        value: '610800',
        label: '榆林'
      },
      {
        value: '610900',
        label: '安康'
      },
      {
        value: '611000',
        label: '商洛'
      }
    ]
  },
  {
    value: '620000',
    label: '甘肃',
    children: [
      {
        value: '620100',
        label: '兰州'
      },
      {
        value: '620200',
        label: '嘉峪关'
      },
      {
        value: '620300',
        label: '金昌'
      },
      {
        value: '620400',
        label: '白银'
      },
      {
        value: '620500',
        label: '天水'
      },
      {
        value: '620600',
        label: '武威'
      },
      {
        value: '620700',
        label: '张掖'
      },
      {
        value: '620800',
        label: '平凉'
      },
      {
        value: '620900',
        label: '酒泉'
      },
      {
        value: '621000',
        label: '庆阳'
      },
      {
        value: '621100',
        label: '定西'
      },
      {
        value: '621200',
        label: '陇南'
      },
      {
        value: '622900',
        label: '临夏回族自治州'
      },
      {
        value: '623000',
        label: '甘南藏族自治州'
      }
    ]
  },
  {
    value: '630000',
    label: '青海',
    children: [
      {
        value: '630100',
        label: '西宁'
      },
      {
        value: '630200',
        label: '海东'
      },
      {
        value: '632200',
        label: '海北藏族自治州'
      },
      {
        value: '632300',
        label: '黄南藏族自治州'
      },
      {
        value: '632500',
        label: '海南藏族自治州'
      },
      {
        value: '632600',
        label: '果洛藏族自治州'
      },
      {
        value: '632700',
        label: '玉树藏族自治州'
      },
      {
        value: '632800',
        label: '海西蒙古族藏族自治州'
      }
    ]
  },
  {
    value: '640000',
    label: '宁夏回族',
    children: [
      {
        value: '640100',
        label: '银川'
      },
      {
        value: '640200',
        label: '石嘴山'
      },
      {
        value: '640300',
        label: '吴忠'
      },
      {
        value: '640400',
        label: '固原'
      },
      {
        value: '640500',
        label: '中卫'
      }
    ]
  },
  {
    value: '650000',
    label: '新疆维吾尔',
    children: [
      {
        value: '650100',
        label: '乌鲁木齐'
      },
      {
        value: '650200',
        label: '克拉玛依'
      },
      {
        value: '650400',
        label: '吐鲁番'
      },
      {
        value: '650500',
        label: '哈密'
      },
      {
        value: '652300',
        label: '昌吉回族自治州'
      },
      {
        value: '652700',
        label: '博尔塔拉蒙古自治州'
      },
      {
        value: '652800',
        label: '巴音郭楞蒙古自治州'
      },
      {
        value: '652900',
        label: '阿克苏地区'
      },
      {
        value: '653000',
        label: '克孜勒苏柯尔克孜自治州'
      },
      {
        value: '653100',
        label: '喀什地区'
      },
      {
        value: '653200',
        label: '和田地区'
      },
      {
        value: '654000',
        label: '伊犁哈萨克自治州'
      },
      {
        value: '654200',
        label: '塔城地区'
      },
      {
        value: '654300',
        label: '阿勒泰地区'
      },
      {
        value: '659001',
        label: '石河子'
      },
      {
        value: '659002',
        label: '阿拉尔'
      },
      {
        value: '659003',
        label: '图木舒克'
      },
      {
        value: '659004',
        label: '五家渠'
      },
      {
        value: '659006',
        label: '铁门关'
      }
    ]
  },
  {
    value: '710000',
    label: '台湾'
  },
  {
    value: '810000',
    label: '香港特别行政区',
    children: [
      {
        value: '810001',
        label: '中西區'
      },
      {
        value: '810002',
        label: '灣仔區'
      },
      {
        value: '810003',
        label: '東區'
      },
      {
        value: '810004',
        label: '南區'
      },
      {
        value: '810005',
        label: '油尖旺區'
      },
      {
        value: '810006',
        label: '深水埗區'
      },
      {
        value: '810007',
        label: '九龍城區'
      },
      {
        value: '810008',
        label: '黃大仙區'
      },
      {
        value: '810009',
        label: '觀塘區'
      },
      {
        value: '810010',
        label: '荃灣區'
      },
      {
        value: '810011',
        label: '屯門區'
      },
      {
        value: '810012',
        label: '元朗區'
      },
      {
        value: '810013',
        label: '北區'
      },
      {
        value: '810014',
        label: '大埔區'
      },
      {
        value: '810015',
        label: '西貢區'
      },
      {
        value: '810016',
        label: '沙田區'
      },
      {
        value: '810017',
        label: '葵青區'
      },
      {
        value: '810018',
        label: '離島區'
      }
    ]
  },
  {
    value: '820000',
    label: '澳门特别行政区',
    children: [
      {
        value: '820001',
        label: '花地瑪堂區'
      },
      {
        value: '820002',
        label: '花王堂區'
      },
      {
        value: '820003',
        label: '望德堂區'
      },
      {
        value: '820004',
        label: '大堂區'
      },
      {
        value: '820005',
        label: '風順堂區'
      },
      {
        value: '820006',
        label: '嘉模堂區'
      },
      {
        value: '820007',
        label: '路氹填海區'
      },
      {
        value: '820008',
        label: '聖方濟各堂區'
      }
    ]
  }
]
