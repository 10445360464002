<template>
  <div id="sponsor">
    <!--    <div class="header-box">-->
    <!--      <div class="header-left">-->
    <!--        <p class="title">公司名称</p>-->
    <!--        <p class="name">企业</p>-->
    <!--        <p class="name">企业2</p>-->
    <!--      </div>-->
    <!--      <p class="sign-state" v-if="signAlready" @click="signIn">登录/注册</p>-->
    <!--      <p class="sign-state" v-if="personalCenter" @click="goPersonalCenter">个人中心</p>-->
    <!--    </div>-->
    <div class="option-box">
      <div class="list-box">
        <div class="list-box-left">
          <p class="filter-name">赞助形式：</p>
          <div class="form-type-box">
            <p class="title" :class="online_type === 1? 'active': ''" @click="changeDetailType(1)">不限</p>
            <p class="title" :class="online_type === 2? 'active': ''" @click="changeDetailType(2)">现金赞助</p>
            <p class="title" :class="online_type === 3? 'active': ''" @click="changeDetailType(3)">物质赞助</p>
          </div>
        </div>
        <p class="release-btn" @click="dialogVisible = true">发布赞助</p>
      </div>
      <div class="list-box">
        <div class="list-box-left">
          <p class="filter-name">赞助省份：</p>
          <el-checkbox-group size="medium" v-model="cityName" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="type in cityUnlimited" :label="type" :key="type" @change="FilterCityName(type)"
                                v-cloak>
              {{type}}
            </el-checkbox-button>
          </el-checkbox-group>
          <el-checkbox-group size="medium" v-model="provinceAll" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="type in province" :label="type" :key="type" @change="FilterProvince(type)"
                                v-cloak>
              {{type}}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <div class="list-box">
        <div class="list-box-left">
          <p class="filter-name">赞助类别：</p>
          <el-checkbox-group size="medium" v-model="categoryAll" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="type in categoryUnlimited" :label="type" :key="type"
                                @change="FilterCategoryAll(type)" v-cloak>
              {{type}}
            </el-checkbox-button>
          </el-checkbox-group>

          <el-checkbox-group size="medium" v-model="categoryOther" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="type in category" :label="type" :key="type" @change="FilterCategoryOther(type)"
                                v-cloak>
              {{type}}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <div class="list-box">
        <div class="list-box-left">
          <p class="filter-name">赞助金额：</p>
          <el-checkbox-group size="medium" v-model="checkedMoneyAll" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="type in moneyAll" :label="type" :key="type" @change="FilterMoneyAll(type)"
                                v-cloak>
              {{type}}
            </el-checkbox-button>
          </el-checkbox-group>
          <el-checkbox-group size="medium" v-model="checkedMoney" text-color="#2988FC"
                             fill="rgba(41, 136, 252, 0.1)">
            <el-checkbox-button v-for="type in money" :label="type" :key="type" @change="FilterMoney(type)" v-cloak>
              {{type}}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="list-box" @click="goDetails(item.id)" v-for="(item,index) in sponsorList" :key="index.id">
        <div class="sponsor-list-box">
          <p class="name">{{item.sponsor_title}}</p>
          <p class="price">{{item.sponsorship_amount}} <span class="element">元</span></p>
        </div>
        <div class="introduce-box">
          <p class="name">{{item.enterprise_name}} {{item.department_name}} {{item.enterprise_scale}}</p>
        </div>
        <div class="label-box">
          <p class="label-name">{{address}}</p>
          <p class="label-name" v-if="item.material !== ''">{{item.material}}</p>
          <p class="label-name" v-if="item.is_cash === '1'">资金赞助</p>
          <p class="label-name" v-if="item.is_material === '1'">物资赞助</p>
          <p class="label-name" v-if="item.material !== ''">{{item.material}}</p>
          <p class="label-name" v-for="(val,index) in JSON.parse(item.promotion_purpose)" :key="index.id">{{val}}</p>
          <p class="label-name" v-for="(val,index) in JSON.parse(item.promotion_mode)" :key="index.id">{{val}}</p>
        </div>
        <div class="personal-box">
          <div class="personal-left">
            <img class="head-portrait" src="../assets/images/weichat-icon.png" alt="">
            <p class="name">{{item.nickname}}</p>
          </div>
          <p class="release-time">{{item.create_at}}</p>
        </div>
      </div>
    </div>
    <!--      分页-->
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pageDate.currentPage" :page-sizes="pageDate.pageGroup" :page-size="20"
                     layout="total, sizes, prev, pager, next, jumper" :total="pageDate.totalNumber">
      </el-pagination>
    </div>
    <!--    登录弹窗-->
    <el-dialog
      class="login-registration-box"
      :visible.sync="loginTip"
      width="30%"
      center>
      <div class="content-popup-box" v-if="loginTipDetails">
        <div class="content-title">
          <div class="introduce">
            <p class="login">验证码登录</p>
            <p class="title">未注册将自动创建账号</p>
          </div>
          <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
        </div>
        <div class="information-box">
          <p class="phone">手机号</p>
          <input class="phone-input" @input="changeUserName" v-model="tel" maxlength="11" type="text"
                 placeholder="请输入手机号">
          <p class="prompt" v-show="isTel">请输入正确手机号</p>
          <p class="verification-code">验证码</p>
          <div class="code-box">
            <input class="number" type="text" @input="changeCode" v-model="safeCode" maxlength="6"
                   placeholder="请输入验证码">
            <p id="submitBtn" class="obtain-btn" v-show="show" @click="getSafeCode">{{countDownMessage}}</p>
            <p class="prompt" v-show="isCode">请输入正确验证码</p>
            <p class="get-verification-code" v-show="notDisplay">{{countDownMessage}}</p>
          </div>
          <p class="submit-button" @click="onSubmit" id="submitSelect">提交</p>
        </div>
      </div>
      <div class="content-popup-box" v-show="identityTip">
        <div class="content-title">
          <div class="introduce">
            <p class="login">请选择身份认证</p>
            <p class="title">发布前需要实名认证哦~</p>
          </div>
          <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
        </div>
        <div class="information-box">
          <div class="select-box">
            <p class="enterprise" @click="goEnterpriseAttestation">我是企业</p>
            <p class="association" @click="goCampusAttestation">我是社团</p>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--    赞助弹窗-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="410px">
      <div class="QRCode-box">
        <div class="explain-box">
          <div class="list">
            <p class="title">请使用微信扫码</p>
            <p class="show">微信扫一扫在手机端操作</p>
          </div>
          <img class="tips-flow" src="../assets/images/tips-flow.png" alt="">
        </div>
        <img class="vote-applet" src="../assets/images/sponsor-code.png" alt="">
      </div>
    </el-dialog>
  </div>
</template>
<script>

  const cityUnlimited = ['不限']
  const sponsorProvince = ['北京', '天津', '上海', '浙江省', '安徽省', '山东省', '河南省', '湖北省', '广东省', '广西壮族自治区', '重庆', '四川省']

  const categoryUnlimited = ['不限']
  const sponsorCategory = ['线上投票', '才艺竞赛', '社会实践', '体育竞技', '设计开发', '讲座论坛', '迎新招新', '演讲辩论', '晚会典礼', '高校推广', '影视音乐', '其他']

  const sponsorUnlimited = ['不限']
  const sponsorMoney = ['0~1000', '1000~2000', '2000~3000', '3000~5000', '5000~8000', '8000~10000', '10000以上']
  export default {
    data () {
      return {
        pageDate: {
          pageGroup: [20, 50, 100, 200],
          pageCurrent: 1,
          pageSize: 20,
          totalNumber: 0
        },


        dialogVisible: false, // 扫码弹窗

        signAlready: true, // 登录状态
        personalCenter: false, // 登录之后个人中心
        show: true,
        notDisplay: false,
        tel: '', // 手机号

        cityUnlimited: cityUnlimited, // 赞助省份
        province: sponsorProvince, // 赞助省份
        provinceAll: [], // 省份
        cityName: ['不限'], // 赞助省份

        category: sponsorCategory, // 赞助类别
        categoryUnlimited: categoryUnlimited, // 赞助类别
        categoryAll: ['不限'], //类别不限
        categoryOther: [], //类别不限

        money: sponsorMoney, // 赞助金额
        moneyAll: sponsorUnlimited, // 赞助金额
        checkedMoneyAll: ['不限'], // 赞助金额
        checkedMoney: [], // 赞助金额

        loginTip: false, // 登录弹窗全
        loginTipDetails: true, // 登录弹窗
        identityTip: false, // 身份弹窗
        isTel: false, // 提示语
        isCode: false, // 提示语
        safeCode: '', // 验证码
        countDownNum: 60,
        timer: null,
        countDownMessage: '获取验证码',
        user_id: '', // 用户user_id
        sponsorList: '', // 赞助列表
        address: '', // 城市

        online_type: 1, // 城市
        sponsorFrom: ''
      }
    },
    methods: {
      // 赞助形式
      changeDetailType (type) {
        this.online_type = type
        if (this.online_type == 1) {
          this.sponsorFrom = ''
        } else if (this.online_type == 2) {
          this.sponsorFrom = '现金赞助'
        } else if (this.online_type == 3) {
          this.sponsorFrom = '物质赞助'
        }
        console.log(this.online_type, 123)
        this.merchantList()
      },
      // 赞助省份
      FilterCityName () {
        this.provinceAll = []
        this.merchantList()
      },
      FilterProvince () {
        this.cityName = []
        this.merchantList()
      },
      // 赞助类别
      FilterCategoryAll () {
        this.categoryOther = []
        this.merchantList()
      },
      FilterCategoryOther () {
        this.categoryAll = []
        this.merchantList()
      },
      // 赞助金额
      FilterMoneyAll () {
        this.checkedMoney = []
        this.merchantList()
      },
      FilterMoney () {
        this.checkedMoneyAll = []
        this.merchantList()
      },
      // 跳转到个人中心页面
      goPersonalCenter () {
        this.$router.push({
          path: '/PersonalCenter'
        }).then()
      },
      // 进入商家赞助列表详情页
      goDetails (id) {
        this.$router.push({
          path: '/enterpriseDetails',
          query: {
            artist: id
          }
        }).then()
      },
      // 进入企业认证页面
      goEnterpriseAttestation () {
        this.$router.push({
          path: '/EnterpriseAttestation'
        }).then()
        this.loginTip = false
      },
      // 进入校园认证页面
      goCampusAttestation () {
        this.$router.push({
          path: '/CampusAttestation'
        }).then()
        this.loginTip = false
      },
      // 校验手机号码
      checkTel (tel) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        return reg.test(tel)
      },
      // 点击登录 弹窗显示
      signIn () {
        this.loginTip = true
      },
      // 输入手机号实时监测
      changeUserName (e) {
        const { value } = e.target
        if (value) {
          document.getElementById('submitBtn').style.color = '#2988FC'
          document.getElementById('submitBtn').style.border = '1px solid #2988FC'
        } else {
          document.getElementById('submitBtn').style.color = '#C0C4CC'
          document.getElementById('submitBtn').style.border = '1px solid #C0C4CC'
        }
      },
      // 验证码
      changeCode (e) {
        const { value } = e.target
        if (value) {
          document.getElementById('submitSelect').style.color = '#2988FC'
          document.getElementById('submitSelect').style.border = '1px solid #2988FC'
        } else {
          document.getElementById('submitSelect').style.color = '#C0C4CC'
          document.getElementById('submitSelect').style.border = '1px solid #C0C4CC'
        }
      },
      // 获取验证码
      getSafeCode () {
        if (!this.checkTel(this.tel)) {
          this.istel = !this.istel
          return false
        } else {
          const formData = new FormData()
          formData.append('telephone', this.tel)
          formData.append('sms_type', '2')
          this.$http.request({
            url: 'Sms/actionGetVerificationCode',
            method: 'POST',
            data: formData
          }).then((res) => {
            const code = res.data.code
            if (code === 200) {
              this.show = false
              this.notDisplay = true
              this.timer = setInterval(() => {
                this.countDownNum--
                if (this.countDownNum <= 0) {
                  clearInterval(this.timer)
                  this.countDownMessage = '重新获取'
                  this.countDownNum = 60
                  this.show = true
                  this.notDisplay = false
                } else {
                  this.countDownMessage = this.countDownNum + 's'
                }
              }, 1000)
            } else {
            }
          })
        }
      },
      // 提交
      onSubmit () {
        if (!this.checkTel(this.tel)) {
          this.isTel = !this.isTel
          return false
        } else if (!this.safeCode) {
          this.isCode = !this.isCode
          return false
        } else {
          const formData = new FormData()
          formData.append('telephone', this.tel)
          formData.append('code', this.safeCode)
          this.$http.request({
            url: 'Login/actionVerificationCodeLoginApi',
            method: 'POST',
            data: formData
          }).then((res) => {
            if (res.data.code === 200) {
              localStorage.setItem('user_id', res.data.data.user_id)
              this.user_id = res.data.user_id
              this.$toast('登录成功')
              this.loginTipDetails = false
              this.identityTip = true
            } else if (res.data.code === 402) {
              this.$toast('该账号不存在')
            } else if (res.data.code === 202) {
              this.$toast('网络请求失败')
            } else if (res.data.code === 403) {
              this.$toast('验证码有误')
            }
          })
        }
      },
      // 分页
      handleSizeChange (val) {
        this.pageDate.pageSize = +val
        this.merchantList()
      },
      handleCurrentChange (val) {
        this.pageDate.pageCurrent = +val
        this.merchantList()
      },
      // 获取页面信息
      async merchantList () {
        const formData = new FormData()
        formData.append('pageCurrent', this.pageDate.pageCurrent)
        formData.append('pageSize', this.pageDate.pageSize)
        formData.append('sponsor_from', this.sponsorFrom) // 赞助形式
        formData.append('s_province', this.provinceAll) // 城市
        formData.append('sponsor_type', this.categoryOther) // 类别
        formData.append('checkedMoneyAll', this.checkedMoney) // 金额
        this.$http.request({
          url: 'Square/actionSponsorListApi',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.sponsorList = res.data.data.SponsorList // 案例列表
            if (this.sponsorList.length > 0) {
              this.sponsorList.forEach(item => {
                // 解析地点
                if (item.s_province === '全部') {
                  this.address = '全国'
                } else if (item.s_county === '全部') {
                  this.address = item.s_county
                } else if (item.s_city === '全部') {
                  this.address = item.s_city
                } else {
                  this.address = item.s_province + ' ' + item.s_city + ' ' + item.s_county
                }
              })
            }
          }
        })
      },
    },
    mounted () {
      this.user_id = localStorage.getItem('user_id')
      if (this.user_id == null) {
        this.signAlready = true
        this.personalCenter = false
      } else {
        this.signAlready = false
        this.personalCenter = true
      }
      this.merchantList()
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @media all and (min-width: 1025px)
    #sponsor
      width 1320px
      margin auto
      padding-top 54px

      .header-box
        padding 0 24px
        margin-top 16px
        margin-bottom 18px
        height 52px
        background #FFFFFF
        opacity 1
        border-radius 3px
        display flex
        align-items center
        justify-content space-between

        .header-left
          display flex
          align-items center

          .title
            color #606266
            font-size 14px
            margin-right 12px

          .name
            padding 4px 12px
            background #E9F3FF
            border-radius 2px
            color #2988FC
            line-height 22px
            font-size 10px
            margin-right 8px

        .sign-state
          color #2988FC
          font-size 14px
          cursor pointer

      .option-box
        padding 24px
        background #FFFFFF
        border-radius 12px
        margin-bottom 16px
        margin-top 18px


        .list-box
          display flex
          align-items center
          justify-content space-between
          margin-bottom 16px

        .list-box-left
          display flex
          align-items center

          .filter-name
            color #909399
            font-size 16px
            margin-right 12px
            font-weight bold

          .form-type-box
            display flex

            .title
              color #606266
              font-size 14px
              padding 8px 16px
              border-radius 2px
              background #fff
              cursor pointer
              transition all 0.3s

            .active
              color #2988fc
              font-size 14px
              background rgba(41, 136, 252, 0.1)

          .el-checkbox-button
            margin-right 7px
            opacity 1
            color #606266
            font-size 14px
            cursor pointer

            .el-checkbox-button__inner
              font-size 14px
              color #606266
              border-radius 2px
              border 0
              padding 8px 16px

          .el-radio-group
            margin-right 7px
            opacity 1
            color #606266
            font-size 14px
            cursor pointer

            .el-radio-button__inner
              font-size 14px
              color #606266
              border-radius 2px
              border 0
              padding 8px 16px

        .release-btn
          color #FFFFFF
          font-size 14px
          text-align center
          width 120px
          height 40px
          line-height 40px
          background #2988FC
          opacity 1
          border-radius 4px
          cursor pointer

      .content-box
        width 1320px
        display grid
        grid-template-columns repeat(auto-fill, 652px)
        justify-content space-between
        margin-bottom 16px

        .list-box
          width 604px
          background #FFFFFF
          padding 24px
          margin-bottom 16px
          cursor pointer

          .sponsor-list-box
            display flex
            justify-content space-between
            margin-bottom 8px

            .name
              color #303133
              font-size 16px
              font-weight bold

            .price
              color #FF4E5F
              font-size 16px

              .element
                color #606266
                font-size 12px

          .introduce-box
            display flex
            margin-bottom 8px

            .name
              color #909399
              font-size 14px
              margin-right 8px

            .number
              color #909399
              font-size 14px

          .label-box
            display flex
            margin-bottom 9px
            flex-wrap wrap

            .label-name
              border 1px solid #2988FC
              border-radius 4px
              padding 4px 8px
              line-height 16px
              font-size 12px
              margin-right 8px
              cursor pointer
              color #2988FC
              margin-bottom 7px

          .personal-box
            display flex
            justify-content space-between
            align-items center

            .personal-left
              display flex
              align-items center

              .head-portrait
                width 24px
                height 24px
                border-radius 50%
                margin-right 6px

              .name
                font-size 14px
                color #909399
                margin-right 8px

            .release-time
              color #909399
              font-size 12px

      .login-registration-box
        position fixed
        top 0
        left 0
        z-index 2001
        width 100%
        height 100%
        background rgba(0, 11, 25, 0.4)

      .el-dialog__body
        padding 0

        .content-popup-box
          border-radius 12px
          background #ffffff
          /*position fixed*/
          top 8%
          left 33%
          padding-top 20px
          padding-bottom 48px

          .content-title
            display flex
            justify-content space-between
            margin 0 28px 0 48px

            .introduce
              .login
                color #606266
                font-size 24px
                margin-bottom 6px
                font-weight bold

              .title
                color #606266
                font-size 16px

            .tips-flow
              width 83px
              height 88px

          .information-box
            margin 0 48px

            .phone
              color #606266
              font-size 16px
              margin-bottom 8px

            .phone-input
              width 432px
              height 42px
              background #FFFFFF
              padding-left 12px
              font-size 16px
              border 1px solid #909399
              opacity 1
              border-radius 2px
              margin-bottom 16px

            .prompt
              font-size 12px
              color #FF4E5F
              margin-bottom 16px

            .verification-code
              color #606266
              font-size 16px
              margin-bottom 8px

            .code-box
              display flex

              .number
                font-size 16px
                padding-left 12px
                width 286px
                height 42px
                background #FFFFFF
                border 1px solid #909399
                margin-right 10px
                border-radius 2px

              .obtain-btn
                color #95989E
                font-size 16px
                text-align center
                line-height 42px
                width 136px
                height 42px
                border 1px solid #95989E
                opacity 1
                border-radius 2px
                cursor pointer

              .get-verification-code
                color #2988FC
                font-size 16px
                text-align center
                line-height 42px
                width 136px
                height 42px
                border 1px solid #2988FC
                opacity 1
                border-radius 2px
                cursor pointer

            .submit-button
              margin-top 32px
              color #C0C4CC
              font-size 20px
              text-align center
              width 446px
              height 48px
              line-height 48px
              border 1px solid #C0C4CC
              opacity 1
              cursor pointer
              border-radius 2px

            .select-box
            .select-box
              display flex

              .enterprise
                padding 164px 65px 32px 65px
                margin-right 12px
                border 1px solid #909399
                color #606266
                font-size 20px
                cursor pointer
                border-radius 4px

              .association
                color #606266
                font-size 20px
                border 1px solid #909399
                padding 164px 65px 32px 65px
                border-radius 4px
                cursor pointer

      .el-dialog
        border-radius 12px

      .QRCode-box
        padding 16px 48px 48px 48px
        text-align center

        .explain-box
          display flex
          justify-content space-between

          .list
            .title
              color #606266
              font-size 24px
              font-weight bold
              margin-bottom 2px
              line-height 33px

            .show
              color #606266
              font-size 16px

          .tips-flow
            width 83px
            heght 88px

        .vote-applet
          text-align center
          width 148px
          height 148px
      .block
        height 86px
        display flex
        background rgba(41,136,252,0.1)
        opacity 1
        border-radius 0px 0px 4px 4px
        align-items center
        padding 0 14px
        justify-content flex-end
        width 1295px
        margin-bottom 30px
  @media all and (max-width: 1024px)
    #sponsor
      display none
</style>
