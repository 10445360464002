<template>
  <div id="dataCenterDetail">
    <!--    左侧内容-->
    <div class="detail-left-box">
      <!--      上半部分-->
      <div class="left-top-box">
        <div class="brief-content">
          <img class="weichat-icon" src="../assets/images/weichat-icon.png" alt="">
          <div class="brief-top">
            <div class="user-box">
              <p class="user-name">{{datacenterInfo.datacenter_name}}</p>
              <p v-if="datacenterInfo.official_type === ''" class="num-type">{{datacenterInfo.official_type}}</p>
            </div>
            <p class="info-bottom"><span class="fans">覆盖人数：{{datacenterInfo.fans_num}}</span><span class="read">平均阅读：{{datacenterInfo.average_read_num}}</span>
            </p>
          </div>
        </div>
        <div class="programme-box">
          <p class="programme-btn" @click="projectTip">获取定制投放方案</p>
        </div>
      </div>
      <div class="left-bottom-box">
        <p class="line"></p><span class="school-list">所属学校</span>
        <div class="school-box">
          <div class="content-left">
            <img class="school-icon" src="../assets/images/school-icon.png" alt="">
            <div class="detail-info">
              <span class="school-name">{{universityInfo.university_name}}</span>
              <!--              <span class="school-type">985</span>-->
              <!--              <span class="school-type">211</span>-->
              <div class="det-info-bot">
                <img class="attribute-icon" src="../assets/images/attribute-icon.png" alt="">
                <span class="name">{{universityInfo.kind_name}}</span>
                <img class="attribute-icon" src="../assets/images/department-icon.png" alt="">
                <span class="title">{{universityInfo.ministry_name}}</span>
              </div>
            </div>
          </div>
          <div class="content-right">
            <div class="statistics-list">
              <p class="name">学生数量</p>
              <p class="number">{{universityInfo.student_count}}</p>
            </div>
            <div class="statistics-list">
              <p class="name">女生比例</p>
              <p class="number">{{universityInfo.female_rate}}</p>
            </div>
            <div class="statistics-list">
              <p class="name">博士数量</p>
              <p class="number">{{universityInfo.phd_program_num}}</p>
            </div>
          </div>
        </div>
      </div>
      <!--      下半部分-->
      <div class="content-details">
        <div class="line-list">
          <p class="line"></p><span class="school-list">媒介投放合作案例</span>
        </div>
        <p v-if="defaultBouquet" class="default-bouquet">暂无相关案例</p>
        <div class="case-list-box" v-for="(item,index) in casesList" :key="index.id" @click="goCaseDetails(item.id)">
          <img class="case-cover" :src="item.case_cover" alt="">
          <div class="case-content">
            <p class="name">{{item.case_title}}</p>
            <p class="title">{{item.case_introduction}}</p>
            <div class="brand">
              <p class="brand-name">品牌：{{item.sponsor}}</p>
              <p class="brand-time">{{item.holding_time}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    右侧内容-->
    <div class="detail-right-box">
      <div class="line-list">
        <p class="line"></p><span class="school-list">其他本校资源</span>
        <div class="resources-tips-box" v-show="resourcesTips">
          <p class="resources-tips">暂无其他本校资源</p>
        </div>
      </div>
      <!--      v-for="(merchandise, index) in item.ProductList.slice(0,3)"-->
      <div class="resources-box" v-for="(item,index) in otherInfo.slice(0,5)" :key="index.id"
           @click="goDetails(item.id,item.type)">
        <div class="silde-top">
          <img class="silde-cover" v-if="item.type === '1'" src="../assets/images/icon-app-logo7.png" alt="">
          <img class="silde-cover" v-if="item.type === '2'" src="../assets/images/icon-app-logo1.png" alt="">
          <img class="silde-cover" v-if="item.type === '3'" src="../assets/images/icon-app-logo5.png" alt="">
          <img class="silde-cover" v-if="item.type === '4'" src="../assets/images/icon-app-logo6.png" alt="">
          <img class="silde-cover" v-if="item.type === '5'" src="../assets/images/icon-app-logo7.png" alt="">
          <img class="silde-cover" v-if="item.type === '6'" src="../assets/images/icon-app-logo7.png" alt="">
          <img class="silde-cover" v-if="item.type === '7'" src="../assets/images/icon-app-logo3.png" alt="">
          <img class="silde-cover" v-if="item.type === '8'" src="../assets/images/icon-app-logo10.png" alt="">
          <img class="silde-cover" v-if="item.type === '9'" src="../assets/images/icon-app-logo4.png" alt="">
          <img class="silde-cover" v-if="item.type === '10'" src="../assets/images/icon-app-logo9.png" alt="">
          <img class="silde-cover" v-if="item.type === '11'" src="../assets/images/icon-app-logo8.png" alt="">
          <img class="silde-cover" v-if="item.type === '12'" src="../assets/images/icon-app-logo7.png" alt="">
          <p class="silde-name">{{item.name}}</p>
        </div>
        <div class="content-list">
          <div class="list">
            <p class="name" v-for="(resourcesItem,index) in item" :key="index">{{resourcesItem.key}}
              {{resourcesItem.value}}</p>
          </div>
          <p class="see-more">查看详情</p>
        </div>
      </div>
      <!--      查看更多-->
      <div class="see-more-box" v-show="seeMore" @click="seeMoreSchool(universityInfo.university_name)">
        <p class="see-more">查看更多</p>
      </div>
      <!--      <div class="resources-box">-->
      <!--        <div class="silde-top">-->
      <!--          <img class="silde-cover" src="../assets/images/weichat-icon.png" alt="">-->
      <!--          <p class="silde-name">北大微生活</p>-->
      <!--        </div>-->
      <!--        <p class="number-people">覆盖人数：12.5W</p>-->
      <!--        <div class="read-number-box">-->
      <!--          <p class="number">平均阅读：1,920</p>-->
      <!--          <p class="view-details">查看详情</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!--    移动端和ipad内容-->
    <div class="move-box">
      <!--      学校简介-->
      <div class="header-box">
        <img class="back-img" :src="universityInfo.banner" alt="">
        <div class="content-box">
          <img class="school-icon" :src="universityInfo.logo" alt="">
          <div class="introduction">
            <p class="name">{{universityInfo.university_name}}</p>
            <span class="label" v-html="universityInfo.level == 1 ? '本科' : '专科'"></span>
            <span class="label" v-if="universityInfo.is_985 === '1'">985</span>
            <span class="label" v-if="universityInfo.is_211 === '1'">211</span>
            <!--            <p><span class="name">武汉大学</span> <span class="label">985</span></p>-->
            <div class="label-box">
              <img class="attribute-icon" src="../assets/images/school-icon1.png" alt="">
              <p class="name">理工</p>
              <img class="attribute-icon" src="../assets/images/school-icon2.png" alt="">
              <p class="name">教育部</p>
            </div>
            <div class="number-box">
              <div class="list">
                <p class="title">学生数量</p>
                <p class="number">{{universityInfo.student_count}}</p>
              </div>
              <div class="list">
                <p class="title">女生比例</p>
                <p class="number">{{universityInfo.female_rate}}</p>
              </div>
              <div class="list">
                <p class="title">博士数量</p>
                <p class="number">{{universityInfo.phd_program_num}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      学校-->
      <div class="attribute-box">
        <img class="wechat" src="../assets/images/weichat-icon.png" alt="">
        <div class="attribute">
          <p class="table">{{datacenterInfo.datacenter_name}} <span
            class="service-number">{{universityInfo.kind_name}}</span></p>
          <p class="cover-number">覆盖人数 {{datacenterInfo.datacenter_number}}</p>
        </div>
      </div>
      <!--      ipad场地照片-->
      <div class="field-img" style="display: none">
        <p class="line"></p>
        <span class="list">场地照片</span>
        <div class="field-bpx">
          <img class="field-cover" src="../assets/images/action_baner.png" alt="">
          <img class="field-cover" src="../assets/images/action_baner.png" alt="">
          <img class="field-cover" src="../assets/images/action_baner.png" alt="">
        </div>
      </div>
      <!--      移动端场地照片-->
      <div class="move-field-img" style="display: none">
        <p>场地照片</p>
      </div>
      <!--      资源价格-->
      <div class="price-box">
        <p class="line"></p>
        <span class="list">资源价格</span>
        <p class="price">头条价格：￥1,000.00元</p>
        <p class="price">次条价格：￥300.00元</p>
      </div>
      <!--      ipad合作案例-->
      <div class="cooperation-cases">
        <p class="line"></p>
        <span class="list">合作案例</span>
        <div class="cases-box">
          <div class="content-box" v-for="(item,index) in casesList" :key="index.id">
            <img class="case-cover" :src="item.case_cover" alt="">
            <div class="content-type">
              <p class="name">{{item.case_title}}</p>
              <div class="time-box">
                <p class="time">{{item.holding_time}}</p>
                <p class="detail">查看详情</p>
              </div>
            </div>
          </div>
        </div>
        <p v-if="defaultBouquet" class="default-bouquet">暂无相关案例</p>
      </div>
      <!--      移动端合作案例-->
      <div class="move-cooperation-cases">
        <p class="line"></p>
        <span class="list">合作案例</span>
        <div class="case-box" v-if="defaultCase">
          <img class="left-btn" @click="prevTap()" src="../assets/images/left-btn.png" alt="">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" ref="swiper">
            <van-swipe-item v-for="(item,index) in casesList" :key="index.id">
              <img class="case-cover" :src="item.case_cover" alt="">
              <p class="name">{{item.case_title}}</p>
              <p class="time">{{item.holding_time}}</p>
            </van-swipe-item>
          </van-swipe>
          <img class="right-btn" @click="nextTap()" src="../assets/images/right-btn.png" alt="">
        </div>
        <p v-if="defaultBouquet" class="default-bouquet">暂无相关案例</p>
        <div class="click-btn-next" v-if="defaultCase">
          <p class="next-list" v-for="(item,index) in casesList" :key="index.id" @mouseover="moveSwipeTap(index)"
             @mouseout="swipeTaps(index)" :class="{active:index==isActive}"></p>
          <!--          <p class="active"></p>-->
        </div>
      </div>
    </div>
    <!--    获取定制投放方案弹窗组件-->
    <div class="pc-popup">
      <my-tips :show="show" @submit="submit"></my-tips>
    </div>
  </div>
</template>
<script>
  import Tips from '../components/pcTip.vue'

  export default {
    // 组件
    components: {
      'my-tips': Tips
    },
    data () {
      return {
        show: false, // 弹窗隐藏
        seeMore: false, // 其他本校资源 查看更多
        item: '',
        defaultCase: true,
        otherInfo: [], // 其他本校资源
        otherInfoArr: '',
        isActive: 0,
        datacenterInfo: [], // 公众号阅读号
        universityInfo: [], // 所属学校
        casesList: [], // 合作案例
        defaultBouquet: false, // 暂无相关案例缺省文字
        resourcesTips: false // 暂无本校资源缺省文字
      }
    },
    methods: {
      // 查看更多学校
      seeMoreSchool (name) {
        this.$router.push({
          path: '/dataCenter',
          query: {
            name: name
          }
        }).then()
      },
      // 点击显示弹窗
      projectTip () {
        this.show = true
      },
      // 点击空白处 进行弹窗回调
      submit () {
        this.show = false
      },
      onChange (index) {
        this.isActive = index
      },
      prevTap () {
        this.$refs.swiper.prev()
        this.autoplay = 10000
      },
      nextTap () {
        this.$refs.swiper.next()
        this.autoplay = 10000
      },
      // 点击本校资源 进入详情
      async goDetails (id, type) {
        this.data_id = id
        this.data_type = type
        // this.data_type = this.$route.query.type
        this.telephone = this.$route.query.tel
        const formData = new FormData()
        formData.append('data_id', this.data_id)
        formData.append('data_type', this.data_type)
        formData.append('telephone', this.telephone)
        this.$http.request({
          url: 'Datacenter/actionDatacenterInfoApi/',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.datacenterInfo = res.data.data.DatacenterInfo // 公众号 阅读号
            this.casesList = res.data.data.casesList // 合作案例
            this.universityInfo = res.data.data.DatacenterInfo.university.universityInfo // 所属学校
            this.otherInfo = res.data.data.DatacenterInfo.university.Other // 其他本校资源
          }
          if (this.otherInfo.length === 0) {
            this.resourcesTips = true
          } else {
            this.resourcesTips = false
          }
          if (this.casesList.length === 0) {
            this.defaultBouquet = true
            this.defaultCase = false
          } else {
            this.defaultBouquet = false
            this.defaultCase = true
          }
        })
      },
      // pc数据中心详情
      async dataCenterDetails () {
        this.data_id = this.$route.query.id
        this.data_type = this.$route.query.type
        this.telephone = this.$route.query.tel
        const formData = new FormData()
        formData.append('data_id', this.data_id)
        formData.append('data_type', this.data_type)
        formData.append('telephone', this.telephone)
        this.$http.request({
          url: 'Datacenter/actionDatacenterInfoApi/',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.datacenterInfo = res.data.data.DatacenterInfo // 公众号 阅读号
            this.casesList = res.data.data.casesList // 合作案例
            this.universityInfo = res.data.data.DatacenterInfo.university.universityInfo // 所属学校
            this.otherInfo = res.data.data.DatacenterInfo.university.Other // 其他本校资源
          }
          if (this.otherInfo.length >= 5) {
            this.seeMore = true
          } else {
            this.seeMore = false
          }
          if (this.otherInfo.length === 0) {
            this.resourcesTips = true
          } else {
            this.resourcesTips = false
          }
          if (this.casesList.length === 0) {
            this.defaultBouquet = true
            this.defaultCase = false
          } else {
            this.defaultBouquet = false
            this.defaultCase = true
          }
        })
      }
    },
    mounted () {
      this.dataCenterDetails()
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl'
  @media all and (min-width: 1025px)
    #dataCenterDetail
      width 1320px
      margin 70px auto
      display flex
      align-items end

      .detail-left-box
        width 960px
        background #FFFFFF

        .left-top-box
          padding 32px
          display flex
          justify-content space-between
          margin-bottom 24px
          border-bottom 1px rgba(41, 136, 252, 0.1) solid

          .brief-content
            display flex
            align-items center

            .weichat-icon
              width 80px
              height 80px
              margin-right 12px

            .brief-top
              .user-box
                display flex
                align-items flex-end
                margin-bottom 10px

                .user-name
                  color #303133
                  font-size 26px
                  font-weight bold
                  margin-right 16px

                .num-type
                  color #2988FC
                  font-size 12px
                  width 60px
                  height 25px
                  line-height 25px
                  text-align center
                  border 1px solid #2988FC
                  border-radius 20px
                  margin-top 6px

              .info-bottom
                color #909399
                font-size 14px

                .fans
                  margin-right 8px

          .programme-box
            display flex
            align-items flex-end

            .programme-btn
              width 144px
              height 32px
              line-height 32px
              text-align center
              background #2988FC
              border-radius 3px
              font-size 14px
              color #FFFFFF
              cursor pointer

        .left-bottom-box
          padding 24px 32px 32px 32px

          .line
            width 3px
            height 12px
            background #2988FC
            box-shadow 0px 3px 6px rgba(41, 136, 252, 0.1)
            opacity 1
            border-radius 2px
            display inline-flex
            margin-right 5px

          .school-list
            font-weight bold
            color #303133
            font-size 16px

          .school-box
            display flex
            justify-content space-between
            margin-top 16px

            .content-left
              display flex
              align-items center

              .school-icon
                width 58px
                height 58px
                margin-right 10px

              .detail-info
                .school-name
                  colro #303133
                  font-size 16px
                  margin-right 10px

                .school-type
                  color #4C7BFF
                  font-size 10px
                  padding 3px 6px
                  background rgba(41, 136, 252, 0.1)
                  border-radius 3px
                  margin-right 7px

                .det-info-bot
                  display flex
                  align-items center
                  margin-top 6px

                  .attribute-icon
                    width 18px
                    height 18px
                    margin-right 2px

                  .name
                    color #909399
                    font-size 13px
                    margin-right 24px

                  .title
                    font-size 13px
                    color #909399

            .content-right
              display flex

              .statistics-list
                text-align right
                margin-right 32px

                .name
                  color #909399
                  font-size 14px
                  margin-bottom 4px

                .number
                  font-size 20px
                  color #303133

        .content-details
          background #FFFFFF
          padding 32px

          .line-list
            margin-bottom 16px

            .line
              width 3px
              height 12px
              background #2988FC
              box-shadow 0px 3px 6px rgba(41, 136, 252, 0.1)
              opacity 1
              border-radius 2px
              display inline-flex
              margin-right 5px

            .school-list
              font-weight bold
              color #303133
              font-size 16px

          .default-bouquet
            height 82px
            line-height 82px
            font-size 12px
            color #909399
            text-align center

          .case-list-box
            display flex
            margin-bottom 16px

            .case-cover
              object-fit cover
              margin-right 17px
              width 288px
              height 162px
              border-radius 3px 3px 0px 0px
              cursor pointer

            .case-content
              width 596px

              .name
                color #303133
                font-size 18px
                margin-bottom 8px

              .title
                color #606266
                font-size 14px
                margin-bottom 73px
                line-height 20px
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical
                overflow hidden

              .brand
                display flex
                justify-content space-between

                .brand-name
                  color #C0C4CC
                  font-size 12px

                .brand-time
                  color #C0C4CC
                  font-size 12px

      .detail-right-box
        width 248px
        background #FFFFFF
        padding 16px
        margin-left 16px

        .line-list
          margin-bottom 17px

          .line
            width 3px
            height 12px
            background #2988FC
            box-shadow 0px 3px 6px rgba(41, 136, 252, 0.1)
            opacity 1
            border-radius 2px
            display inline-flex
            margin-right 5px

          .school-list
            font-weight bold
            color #303133
            font-size 16px

          .resources-tips-box
            .resources-tips
              padding-top 40px
              font-size 12px
              color #909399
              text-align center

        .resources-box
          margin-bottom 16px
          cursor pointer

          .silde-top
            display flex
            align-items center
            margin-bottom 10px

            .silde-cover
              width 18px
              height 18px
              margin-right 4px

            .silde-name
              font-size 14px
              line-height 21px
              color #303133

          .content-list
            display flex
            justify-content space-between
            align-items flex-end
            line-height 20px

            .list
              .name
                color #909399
                font-size 12px

            .see-more
              color #909399
              font-size 12px
              cursor pointer

          .number-people
            font-size 12px
            color #909399
            margin-bottom 6px

          .read-number-box
            display flex
            justify-content space-between
            align-items center

            .number
              color #909399
              font-size 12px

            .view-details
              color #909399
              font-size 12px
              cursor pointer

        .see-more-box
          font-size 12px
          color #909399
          text-align center
          height 50px
          line-height 50px
          cursor pointer

      .move-box
        display none

  @media all and (max-width: 1024px)
    #dataCenterDetail
      .detail-left-box
        display none

      .detail-right-box
        display none

      .move-box
        padding-top 60px

        .header-box
          position relative

          .back-img
            height 100%
            width 100%
            position absolute
            top 0
            left 0
            background linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)
            filter blur(1px)
            opacity 1
            width 100%

          .content-box
            padding 28px 28px 18px 28px
            display flex
            position relative

            .school-icon
              width 60px
              height 60px
              margin-right 16px
              border-radius 50%

            .introduction
              margin-top 8px

              .name
                color #FFFFFF
                height 22px
                font-size 16px
                margin-right 8px
                display initial

              .label
                padding 3px 6px
                border-radius 3px
                background rgba(255, 255, 255, 0.8)
                margin-right 4px
                font-size 10px
                color #4C7BFF

              .label-box
                display flex
                margin-top 10px

                .attribute-icon
                  color #ffffff

                .attribute-icon
                  margin-right 8px
                  width 13px
                  height 13px

                .name
                  margin-right 20px
                  height 18px
                  color #FFFFFF
                  font-size 13px

              .number-box
                margin-top 16px
                display flex

                .list
                  margin-right 24px

                  .title
                    height 20px
                    color #FFFFFF
                    font-size 14px

                  .number
                    height 24px
                    margin-top 4px
                    color #FFFFFF
                    font-size 20px

        .attribute-box
          padding 20px 28px 28px 32px
          display flex
          align-items center

          .wechat
            margin-right 16px
            width 60px
            height 60px

          .attribute
            .table
              margin-right 4px
              height 22px
              color #303133
              font-size 16px

              .service-number
                color #FFB300
                font-size 10px
                padding 3px 6px
                background: #FEF4DE;

            .cover-number
              margin-top 8px
              height 18px
              font-size 12px
              color #303133

        .field-img
          padding 0 28px 32px 28px

          .line
            width 3px
            height 12px
            background #2988fc
            box-shadow 0px 2px 4px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 2px
            display inline-flex
            margin-right 5px

          .list
            font-weight bold
            color #303133
            font-size 14px

          .field-bpx
            display grid
            grid-template-columns repeat(auto-fill, 32.5%)
            justify-content space-between
            margin-top 12px

            .field-cover
              border-radius 4px
              height 124px
              object-fit cover
              width 100%

        .price-box
          padding 0 28px 32px 28px

          .line
            width 3px
            height 12px
            background #2988fc
            box-shadow 0px 2px 4px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 2px
            display inline-flex
            margin-right 5px

          .list
            font-weight bold
            color #303133
            font-size 14px

          .price
            margin-top 16px
            color #303133
            font-size 14px

        .cooperation-cases
          padding 0 28px 32px 28px

          .line
            width 3px
            height 12px
            background #2988fc
            box-shadow 0px 2px 4px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 2px
            display inline-flex
            margin-right 5px

          .list
            font-weight bold
            color #303133
            font-size 14px

          .cases-box
            display grid
            grid-template-columns repeat(auto-fill, 32.5%)
            justify-content space-between
            margin-top 12px

            .case-cover
              width 100%

            .content-box
              .case-cover
                object-fit cover
                border-radius 4px 4px 0px 0px
                height 134px
                vertical-align middle

              .content-type
                padding 12px
                background #FFFFFF

                .name
                  height 40px
                  line-height 20px
                  margin-bottom 6px
                  font-size 14px
                  color #303133

                .time-box
                  display flex
                  justify-content space-between
                  align-items center

                  .time
                    color #909399
                    font-size 12px

                  .detail
                    color #909399
                    font-size 12px

          .default-bouquet
            height 82px
            line-height 82px
            font-size 12px
            color #909399
            text-align center

  @media all and (min-width: 768px)
    #dataCenterDetail
      .move-cooperation-cases
        display none

      .move-field-img
        display none

  @media all and (max-width: 767px)
    #dataCenterDetail
      .move-box
        padding-top 40px

        .field-img
          display none

        .cooperation-cases
          display none

        .price-box
          padding 0 24px 24px 24px

        .move-cooperation-cases
          padding 0 24px 40px 24px

          .line
            width 3px
            height 12px
            background #2988fc
            box-shadow 0px 2px 4px rgba(4, 30, 103, 0.1)
            opacity 1
            border-radius 2px
            display inline-flex
            margin-right 5px

          .list
            height 20px
            font-weight bold
            color #303133
            font-size 14px

          .case-box
            display flex
            justify-content space-between
            margin-top 12px

            .left-btn
              width 30px
              height 30px
              margin-top 50px

            .my-swipe
              color #cccccc

              .van-swipe-item
                .case-cover
                  width 220px
                  height 130px
                  object-fit cover
                  vertical-align middle
                  border-radius 4px

                .name
                  margin-top 8px
                  width 220px
                  color #303133
                  font-size 14px

                .time
                  color #303133
                  font-size 12px
                  margin-top 4px
                  text-align center

            .right-btn
              width 30px
              height 30px
              margin-top 50px

          .default-bouquet
            height 82px
            line-height 82px
            font-size 12px
            color #909399
            text-align center

          .click-btn-next
            display flex
            justify-content center
            margin-top 24px

            .next-list
              width 16px
              height 3px
              background: rgba(76, 123, 255, 0.1);
              opacity 1
              border-radius 2px
              margin-right 6px

            .active
              width 16px
              height 3px
              background: #4C7BFF;
              opacity 1
              border-radius 2px
              margin-right 6px
</style>
