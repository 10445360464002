<template>
  <div id="index">
    <!--    pc端 header-img-->
    <div class="headline">
      <img class="ic-back" src="../assets/images/ic-back.png" alt="">
      <div class="headline-content">
        <div class="headline-content-left">
          <p class="title">为企业品牌年轻化赋能</p>
          <p class="small-title">十年高效市场营销经验，深刻洞察校园用户群体</p>
          <p class="about-us" @click="pctips">进一步了解我们</p>
          <img class="ic-flow" src="../assets/images/ic-flow.png" alt="">
        </div>
        <div class="headline-content-right">
          <img class="ic-blackboard" src="../assets/images/ic-blackboard.png" alt="">
          <div class="list-top">
            <img class="ic-icon" src="../assets/images/ic-icon.png" alt="">
            <img class="ic-letter" src="../assets/images/ic-letter.png" alt="">
            <img class="ic-book" src="../assets/images/ic-book.png" alt="">
            <img class="ic-logo" src="../assets/images/ic-logo.png" alt="">
          </div>
          <div class="list-bottom">
            <img class="ic-girl" src="../assets/images/ic-girl.png" alt="">
            <div class="list-style">
              <img class="ic-look-book" src="../assets/images/ic-look-book.png" alt="">
            </div>
            <img class="ic-boy" src="../assets/images/ic-boy.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--    ipad和移动端header-img-->
    <div class="headline-move">
      <img class="ic-back" src="../assets/images/ic-back.png" alt="">
      <div class="headline-content">
        <div class="headline-content-top">
          <p class="title">为企业品牌年轻化赋能</p>
          <p class="small-title">十年高效市场营销经验，深刻洞察校园用户群体</p>
          <p class="about-us" @click="pctips">进一步了解我们</p>
        </div>
        <div class="headline-content-bottom">
          <img class="ic-blackboard" src="../assets/images/ic-blackboard.png" alt="">
          <div class="list-top">
            <img class="ic-icon" src="../assets/images/ic-icon.png" alt="">
            <img class="ic-letter" src="../assets/images/ic-letter.png" alt="">
            <img class="ic-book" src="../assets/images/ic-book.png" alt="">
            <img class="ic-logo" src="../assets/images/ic-logo.png" alt="">
          </div>
          <div class="list-bottom">
            <img class="ic-flow" src="../assets/images/ic-flow.png" alt="">
            <img class="ic-girl" src="../assets/images/ic-girl.png" alt="">
            <div class="list-style">
              <img class="ic-look-book" src="../assets/images/ic-look-book.png" alt="">
            </div>
            <img class="ic-boy" src="../assets/images/ic-boy.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--    pc我们产品-->
    <div class="pc-product">
      <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" ref="swiper" @change="onChange">
        <van-swipe-item v-for="(item,index) in swiperInfo" :key="index.id" @mouseover="swipeTapList(index)"
                        @mouseout="swipeTaps(index)">
          <a :href="item.href">
            <div class="product-content-box">
              <div class="product-left">
                <img class="img-product" :src="item.cover" alt="">
                <img class="img-product-cover" :src="item.logo" alt="">
              </div>
              <div class="product-right">
                <p class="title">{{item.title}}</p>
                <p class="name">{{item.name}}</p>
                <p class="introduce">{{item.introduce}}</p>
                <span class="see-more">{{item.more}}</span>
              </div>
            </div>
          </a>
        </van-swipe-item>
      </van-swipe>
      <!--      pc切换轮播图-->
      <div class="indicator">
        <p class="indicator-list" v-for="(item,index) in swiperInfo" :key="index.id"
           @mouseover="swipeTap(index)" @mouseout="swipeTaps(index)" :class="{active:index==isActive}">
          {{item.banner_title}}</p>
      </div>
    </div>
    <!--    ipad 移动 我们的产品-->
    <div class="product">
      <p class="title">我们产品</p>
      <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" ref="moveSwiper" @change="onChange">
        <van-swipe-item v-for="(item,index) in swiperInfo" :key="index.id">
          <div class="product-box">
            <p class="name">{{item.name}}</p>
            <p class="introduce">{{item.introduce}}</p>
            <img class="img-product-cover-list" :src="item.logo" alt="">
            <a :href="item.href">
              <p class="see-more">{{item.more}}</p>
            </a>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="indicator-slide">
        <p class="indicator-list" v-for="(item,index) in swiperInfo" :key="index.id"
           @mouseover="moveSwipeTap(index)" @mouseout="swipeTaps(index)" :class="{active:index==isActive}">
          {{item.banner_title}}</p>
      </div>
    </div>
    <!--    我们的服务-->
    <div class="service-all">
      <div class="service-all-box">
        <p class="service-all-title">我们的服务</p>
        <p class="service-all-introduce">从内容到落地，我们有丰富的经验和资源支撑。能够在品牌深入高校市场的过程中，提供一站式服务。</p>
        <div class="content">
          <div class="content-list" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000" data-aos-offset="100">
            <img class="ser-market-plann" src="../assets/images/ser-market-plann.png" alt="">
            <p class="ser-market-tit">营销策划</p>
            <div class="active-list">
              <p>内容创意</p>
              <p>活动策划</p>
              <p>品牌塑造</p>
            </div>
          </div>
          <div class="content-list" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000" data-aos-offset="250">
            <img class="ser-market-plann" src="../assets/images/ser-launch.png" alt="">
            <p class="ser-market-tit">媒介投放</p>
            <div class="active-list">
              <p>投档策略</p>
              <p>资源采购</p>
              <p>数据服务</p>
            </div>
          </div>
          <div class="content-list" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000" data-aos-offset="300">
            <img class="ser-market-plann" src="../assets/images/ser-campus.png" alt="">
            <p class="ser-market-tit">校园大使建设</p>
            <div class="active-list">
              <p>团队架构</p>
              <p>招募培训</p>
              <p>运营管理</p>
            </div>
          </div>
          <div class="content-list" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000" data-aos-offset="400">
            <img class="ser-market-plann" src="../assets/images/ser-active.png" alt="">
            <p class="ser-market-tit">校园活动赞助</p>
            <div class="active-list">
              <p>渠道商务</p>
              <p>活动运营</p>
              <p>效果管理</p>
            </div>
          </div>
        </div>
        <p class="view-more" @click="pctips">即刻获取解决方案</p>
      </div>
    </div>
    <!--    成功案例-->
    <div class="success-case">
      <p class="title">成功案例</p>
      <div class="case-title-box">
      </div>
      <div class="case-list-box">
        <div class="list" v-for="(item,index) in casesList" :key="index" @click="goCaseDetails(item.id)">
          <img class="case-cover" :src="item.case_cover" alt="">
          <div class="content">
            <p class="content-title">{{item.case_title}}</p>
            <p class="content-name">{{item.case_introduction}}</p>
            <div class="brand-list">
              <p class="brand">品牌：{{item.sponsor}}</p>
              <p class="time">{{item.holding_time_year}} / {{item.holding_time_month}}</p>
            </div>
          </div>
        </div>
      </div>
      <p class="view-more-case" @click="goCase">查看更多</p>
    </div>
    <!--    累计服务客户-->
    <div class="work-partner">
      <div class="work-partner-box">
        <p class="work-number">累计服务客户100+</p>
        <div class="shadow">
          <p class="left-shadow"></p>
          <p class="right-shadow"></p>
        </div>
        <!--        pc端合作伙伴-->
        <div class="pc-work-logo-list">
          <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white">
            <van-swipe-item v-for="(item,index) in partnerList" :key="index" @mouseover="swipePCTapList(index)"
                            @mouseout="swipePCTaps(index)">
              <a class="icone-partner" :href="items.jump_link" v-for="(items, indexs) in item" :key="indexs"
                 target="_blank">
                <img class="login-partner" :src="items.partner_logo" alt/>
              </a>
            </van-swipe-item>
          </van-swipe>
        </div>
        <!--        ipad端合作伙伴-->
        <div class="ipad-work-logo-list">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in partnerListIpad" :key="index">
              <a class="icone-partner" :href="items.jump_link" v-for="(items, indexs) in item" :key="indexs"
                 target="_blank">
                <img class="login-partner" :src="items.partner_logo" alt/>
              </a>
            </van-swipe-item>
          </van-swipe>
        </div>
        <!--        移动端合作伙伴-->
        <div class="move-work-logo-list">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in partnerListMove" :key="index">
              <a class="icone-partner" :href="items.jump_link" v-for="(items, indexs) in item" :key="indexs"
                 target="_blank">
                <img class="login-partner" :src="items.partner_logo" alt/>
              </a>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="button-tip-box">
          <p class="tip-title">天率校元，助力打破校企围墙，开拓年轻人的市场</p>
          <p class="btn-tip" @click="pctips">立即咨询</p>
        </div>
      </div>
    </div>
    <!--    pc弹窗组件-->
    <div class="pc-popup">
      <my-tips :show="show" @submit="submit"></my-tips>
    </div>
  </div>
</template>
<script>
  import AOS from 'aos'
  import Tips from '../components/pcTip.vue'

  export default {
    // 组件
    components: {
      'my-tips': Tips
    },
    data () {
      return {
        // mobileshow: false,
        show: false, // 弹窗隐藏
        // 合作伙伴每个页面数量不一样，需要按需调整，切割数量
        partnerList: [], // pc端合作伙伴
        partnerListIpad: [], // ipad端合作伙伴
        partnerListMove: [], // 移动端合作伙伴
        casesList: [], // 成功案例
        isActive: 0,
        autoplay: 3000,
        swiperInfo: [
          {
            cover: require('../assets/images/img-product.png'),
            logo: require('../assets/images/img-product-cover.png'),
            title: '我们产品',
            name: '活动运营工具',
            introduce: '专为校园活动打造的一款社团活动管理小程序，3分钟快速创建活动，移动端随时查看和管理活动数据，还支持付费活动的创建。',
            href: '/activeTool',
            more: '点击查看更多'
          },
          {
            cover: require('../assets/images/img-product.png'),
            logo: require('../assets/images/img-product-cover-list.png'),
            title: '我们产品',
            name: '赛事投票平台',
            introduce: '专为全国大学生打造的，“免费”、“多场景”赛事投票工具，3分钟快捷创建活动，支持“图文”、“音频”、“视频”三种方式参赛作品，随时随地通过小程序查看和管理你的赛事。',
            href: '/voteTool',
            more: '点击查看更多'
          },
          {
            cover: require('../assets/images/img-product.png'),
            logo: require('../assets/images/img-product-cover-data.png'),
            title: '我们产品',
            name: '校园数据中心',
            introduce: '整合全国2000+校园营销资源数据，90%以上的一手资源优势。做传播、做活动、做地推，全场景资源透明呈现。',
            href: '/dataCenter',
            more: '点击查看更多'
          }
        ]
      }
    },
    methods: {
      // 点击空白处 进行弹窗回调
      submit () {
        this.show = false
      },
      // tips() {
      //   this.mobileshow = false
      // },
      // 点击显示弹窗
      pctips () {
        this.show = true
      },
      // pc端 底部鼠标放在上 不进行轮播
      swipePCTapList () {
        this.autoplay = 20000
      },
      // pc端底部进行恢复自动轮播
      swipePCTaps () {
        this.autoplay = 3000
      },
      swipeTapList () {
        this.autoplay = 20000
      },
      swipeTap (index) {
        this.$refs.swiper.swipeTo(index)
        this.autoplay = 20000
        this.isActive = index
      },
      swipeTaps () {
        this.autoplay = 3000
      },
      moveSwipeTap (index) {
        this.$refs.moveSwiper.swipeTo(index)
        this.autoplay = 20000
        this.isActive = index
      },
      onChange (index) {
        this.isActive = index
      },
      // 跳转到案例详情页
      async goCaseDetails (caseId) {
        this.$router.push({
          path: '/CaseDetails',
          query: {
            artist: caseId
          }
        }).then()
      },
      // 跳转到案例页面
      async goCase () {
        this.$router.push({
          path: '/case'
        }).then()
      },
      // 合作伙伴
      async workList () {
        const formData = new FormData()
        this.$http.request({
          url: 'Home/actionHomeInfoApi/',
          method: 'POST',
          data: formData
        }).then((res) => {
          if (res.data.code === 200) {
            this.partnerList = this.funData(res.data.data.CooperativePartnerList) // pc端合作伙伴
            this.partnerListIpad = this.funDataIpad(res.data.data.CooperativePartnerList) // ipad端合作伙伴
            this.partnerListMove = this.funDataMove(res.data.data.CooperativePartnerList) // 移动端合作伙伴
            this.casesList = res.data.data.CasesList // 成功案例
          }
        })
      },
      // pc端分割合作伙伴
      funData (e) {
        const proportion = 24 // 按照比例切割
        let num = 0
        const data = []
        for (let i = 0; i < e.length; i++) {
          if (i % proportion === 0 && i !== 0) {
            data.push(e.slice(num, i))
            num = i
          }
          if (i + 1 === e.length) {
            data.push(e.slice(num, i + 1))
          }
        }
        return data
      },
      // ipad切割合作伙伴
      funDataIpad (e) {
        const proportionIpad = 16 // 按照比例切割
        let num = 0
        const data = []
        for (let i = 0; i < e.length; i++) {
          if (i % proportionIpad === 0 && i !== 0) {
            data.push(e.slice(num, i))
            num = i
          }
          if (i + 1 === e.length) {
            data.push(e.slice(num, i + 1))
          }
        }
        return data
      },
      // 移动端合作伙伴
      funDataMove (e) {
        const proportionMove = 12 // 按照比例切割
        let num = 0
        const data = []
        for (let i = 0; i < e.length; i++) {
          if (i % proportionMove === 0 && i !== 0) {
            data.push(e.slice(num, i))
            num = i
          }
          if (i + 1 === e.length) {
            data.push(e.slice(num, i + 1))
          }
        }
        return data
      }
    },
    created () {
      this.$nextTick(() => {
        AOS.refresh()
      })
    },
    mounted () {
      this.workList()
    }
  }
</script>
<style lang="stylus">
  @import '../components/stylus/reset.styl';
  @import '../assets/font/font.css';
  @media all and (min-width: 1025px)
    #index
      .headline-move
        display none

      .headline
        background #FFFFFF

        .ic-back
          background 100%
          width 1224px
          position absolute
          right 0

        .headline-content
          display flex
          width 1320px
          padding-top 116px
          margin auto
          position relative

          .headline-content-left
            .title
              color #3A3A3A
              font-size 48px
              font-family ZhenyanGB
              height 60px
              line-height 60px
              margin-top 127px

            .small-title
              color #3A3A3A
              margin-top 8px
              font-size 18px
              letter-spacing 4px
              font-weight 500

            .about-us
              color #FFFFFF
              height 45px
              line-height 45px
              background #2988FC
              width 184px
              text-align center
              letter-spacing 2px
              border-radius 2px
              margin-top 36px
              cursor pointer

            .ic-flow
              width 124px
              height 132px
              object-fit cover
              margin-top 71px
              margin-left 364px

          .headline-content-right
            .ic-blackboard
              width 521px
              height 347px
              margin-left 226px

            .list-top
              display flex
              margin-top -344px

              .ic-icon
                width 93px
                height 93px
                margin-right 45px
                margin-left 116px
                margin-top 24px
                position absolute
                animation icIcon 3s ease infinite
                @keyframes icIcon {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-letter
                width 130px
                height 142px
                position absolute
                margin-left 263px
                animation letter 3s ease infinite
                @keyframes letter {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-book
                width 94px
                height 101px
                margin-top 10px
                position absolute
                margin-left 550px
                animation book 3s ease infinite
                @keyframes book {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-logo
                width 106px
                height 96px
                margin-top 18px
                position absolute
                margin-left 750px
                animation icLogo 3s ease infinite
                @keyframes icLogo {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

            .list-bottom
              display flex
              margin-top: 128px;

              .ic-girl
                width 208px
                height 452px
                margin-top -23px
                margin-left 57px

              .list-style

                .ic-look-book
                  width 303px
                  height 268px
                  margin-top 124px
                  margin-left 117px

              .ic-boy
                width 234px
                height 452px
                margin-left -28px
                margin-top -98px

      .pc-product
        width 1320px
        margin auto
        padding 128px 0 64px 0

        .my-swipe
          .van-swipe-item
            .product-content-box
              display flex

              .product-left
                display flex

                .img-product
                  width 288px
                  height 466px

                .img-product-cover
                  width 888px
                  height 495px
                  margin-left -238px
                  margin-right 12px

              .product-right
                width 432px
                text-align right

                .title
                  color #303133
                  font-size 42px
                  font-weight bold
                  margin-bottom 48px

                .name
                  color #303133
                  font-size 18px
                  margin-bottom 24px
                  font-weight bold

                .introduce
                  color #606266
                  font-size 16px
                  line-height 22px
                  margin-bottom 42px

                .see-more
                  color #606266
                  font-size 16px
                  padding 6px 20px
                  border 1px solid #909399
                  opacity 1
                  border-radius 2px
                  cursor pointer

        .indicator
          display flex
          position absolute
          left 81%
          margin-top -10%
          cursor pointer
          padding 50px 0

          .indicator-list
            width 20px
            height 3px
            background #2988FC
            opacity 0.4
            border-radius 3px
            margin-right 6px
            cursor pointer
            transition all 0.4s linear

          .indicator-list:hover
            opacity 1

          .active
            width 40px
            background #2988FC
            opacity 1

      .product
        display none

      .service-all
        background #FFFFFF

        .service-all-box
          width 1320px
          margin 0 auto
          padding-bottom 64px

          .service-all-title
            color #303133
            font-size 42px
            padding 64px 0 48px 0
            text-align center
            font-weight bold

          .service-all-introduce
            width 544px
            text-align center
            height 44px
            line-height 22px
            font-weight bold
            font-size 16px
            color #606266
            margin auto
            margin-bottom 36px

          .content
            display flex
            justify-content space-between

            .content-list
              width 256px
              height 171px
              background #FAFAFA
              opacity 1
              border-radius 8px
              padding 37px 32px 32px 32px
              text-align center

              .ser-market-plann
                width 83px
                height 83px

              .ser-market-tit
                font-size 18px
                margin-top 29px
                font-weight 500
                color #303133
                letter-spacing 1px

              .active-list
                color #606266
                font-size 16px
                margin-top 12px
                display flex
                justify-content space-between

            .content-list:hover
              box-shadow 0 17px 20px 0 rgba(0, 0, 0, .19)
              transform translate3d(0, -3px, 0)
              transition all .5s cubic-bezier(.55, 0, .1, 1)

          .view-more
            font-size 16px
            width 168px
            height 46px
            line-height 46px
            text-align center
            background #2988FC
            opacity 1
            border-radius 4px
            margin auto
            margin-top 36px
            color #FFFFFF
            cursor pointer

      .success-case
        width 1320px
        padding 64px 0
        margin auto

        .case-title-box
          display flex
          justify-content space-between

          .title
            color #303133
            font-weight bold
            font-size 42px

        .case-list-box
          font-size 12px
          margin-top 48px
          display grid
          grid-template-columns repeat(auto-fill, 320px)
          justify-content space-between

          .list
            margin-right 16px
            -moz-transition all .3s
            -o-transition all .3s
            -webkit-transition all .3s
            transition all .3s
            margin-bottom 16px

            .case-cover
              width 320px
              height 180px
              background #F0F2F5
              opacity 1
              object-fit cover
              cursor pointer
              border-radius 3px 3px 0px 0px
              vertical-align middle

            .content
              background #FFFFFF
              border 1px solid #F0F2F5
              opacity 1
              border-radius 0px 0px 3px 3px
              width 296px
              padding 12px

              .content-title
                color #303133
                font-size 18px
                margin-bottom 6px
                font-weight bold
                height 40px
                line-height 22px

              .content-name
                line-height 20px
                margin-bottom 10px
                height 40px
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical
                overflow hidden
                font-size 14px
                color #606266

              .brand-list
                display flex
                font-size 12px
                justify-content space-between
                color #C0C4CC

          .list:hover
            box-shadow 0 5px 20px 0 rgba(0, 0, 0, .19)
            transform translate3d(0, -5px, 0)
            transition all .3s cubic-bezier(.55, 0, .1, 1)
            border-radius 0px 0px 3px 3px

        .view-more-case
          width 176px
          height 42px
          line-height 42px
          text-align center
          margin auto
          border 1px solid #909399
          opacity 1
          border-radius 2px
          color #606266
          margin-top 32px
          cursor pointer
          font-size 16px

      .work-partner
        background #FFFFFF

        .work-partner-box
          width 1320px
          padding-top 64px
          margin auto

          .work-number
            color #303133
            font-weight bold
            font-size 42px
            margin-bottom 48px

          .shadow
            display flex
            position relative
            z-index 9

            .left-shadow
              width 82px
              height 280px
              background linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
              opacity 1
              position absolute

            .right-shadow
              width 82px
              height 280px
              background linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
              opacity 1
              position absolute
              right 0

          .pc-work-logo-list
            margin-bottom 62px

            .van-swipe-item
              display flex
              flex-wrap wrap

              .icone-partner
                width 220px
                height 56px
                margin-bottom 16px

                .login-partner
                  width 200px

          .ipad-work-logo-list
            display none

          .move-work-logo-list
            display none

          .button-tip-box
            height 88px
            line-height 88px
            display flex
            background #2988FC
            border-radius 8px 8px 0px 0px
            justify-content space-between
            padding 0 40px
            align-items center

            .tip-title
              color #FFFFFF
              font-size 24px

            .btn-tip
              width 168px
              height 49px
              line-height 49px
              text-align center
              background #FFFFFF
              opacity 0.9
              border-radius 4px
              cursor pointer
              color #2988FC
              font-size 18px

  @media all and (max-width: 1024px)
    #index
      .headline
        display none

      .headline-move
        background #ffffff

        .ic-back
          background 100%
          position absolute
          width 100%
          height 68%
          right 0

        .headline-content
          position relative

          .headline-content-top
            padding-left 28px

            .title
              color #3A3A3A
              font-size 48px
              height 60px
              line-height 60px
              font-family ZhenyanGB
              padding-top 108px

            .small-title
              color #3A3A3A
              font-size 18px
              font-weight 500
              letter-spacing 5px
              height 25px
              line-height 25px
              margin-top 8px

            .about-us
              color #FFFFFF
              width 184px
              height 45px
              line-height 45px
              text-align center
              background #2988FC
              margin-top 24px
              cursor pointer
              opacity 1
              border-radius 2px

          .headline-content-bottom
            .ic-blackboard
              width 54%
              height 40%
              margin-left: 28%;
              margin-top 6%

            .list-top
              display flex
              margin-top -324px

              .ic-icon
                width 10%
                height 10%
                margin-left 157px
                position absolute
                animation moveicIcon 3s ease infinite
                @keyframes moveicIcon {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-letter
                width 16%
                margin-left 33%
                margin-top 2%
                position absolute
                animation moveLetter 3s ease infinite
                @keyframes moveLetter {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-book
                width 11%
                height 13%
                margin-left 57%
                position absolute
                animation moveBook 3s ease infinite
                @keyframes moveBook {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

              .ic-logo
                width 11%
                height 11%
                margin-left 82%
                position absolute
                animation moveLogo 3s ease infinite
                @keyframes moveLogo {
                  0% {
                    transform translate(0)
                  }
                  50% {
                    transform translate(0, 10%)
                  }
                }

            .list-bottom
              display flex
              margin-top 20%

              .ic-flow
                width 12%
                height 4%
                margin-top 25%

              .ic-girl
                width 22%
                height 9%
                margin-top -5%
                margin-left 2%

            .list-style
              .ic-look-book
                width 80%
                margin-top 44%
                margin-left 26%

            .ic-boy
              width 24%
              height 9%
              margin-top -9%
              padding-right 3%

      .pc-product
        display none

      .product
        .title
          color: #303133;
          font-size: 32px;
          font-weight: bold;
          padding: 54px 0 32px 0;
          text-align: center;

        .my-swipe
          .van-swipe-item
            .product-box
              text-align center

              .name
                color #303133
                font-size 18px
                font-weight bold
                margin-bottom 24px

              .introduce
                color #606266
                font-size 14px
                line-height 20px
                padding 0 28px 18px 28px

              .img-product-cover-list
                width 100%
                margin-bottom 15px
                object-fit cover
                height 100%

              .see-more
                width 136px
                height 34px
                line-height 34px
                text-align center
                border 1px solid #909399
                opacity 1
                margin auto
                border-radius 2px
                color #606266
                font-size 16px
                margin-bottom 24px

        .indicator-slide
          display flex
          justify-content center
          margin-bottom 48px

          .indicator-list
            width 46px
            height 3px
            background rgba(41, 136, 252, 0.3)
            opacity 1
            border-radius 2px
            margin-right 16px
            transition all 0.4s linear

          .indicator-list:hover
            opacity 1

          .active
            background #2988FC
            opacity 1

      .service-all
        background #FFFFFF

        .service-all-box
          padding 48px 56px

          .service-all-title
            color #303133
            font-weight bold
            font-size 32px
            text-align center

          .service-all-introduce
            margin auto
            line-height 20px
            color #606266
            font-size 14px
            width 544px
            margin-top 24px
            text-align center
            margin-bottom 24px

          .content
            display flex
            flex-wrap wrap
            justify-content space-between

            .content-list
              width 39%
              height 30%
              background #FAFAFA
              opacity 1
              border-radius 8px
              padding 6% 5%
              text-align center
              margin-bottom 16px

              .ser-market-plann
                width 83px
                height 83px

              .ser-market-tit
                font-size 18px
                margin-top 29px
                letter-spacing 2px
                color #303133

              .active-list
                color #606266
                font-size 16px
                margin-top 12px
                display flex
                justify-content space-between

          .view-more
            font-size 16px
            width 168px
            height 46px
            line-height 46px
            background #2988FC
            opacity 1
            border-radius 4px
            text-align center
            margin auto
            margin-top 16px
            color #FFFFFF

      .success-case
        padding 48px 5%

        .case-title-box
          .title
            color #303133
            font-size 32px
            font-weight bold
            margin-bottom 36px
            text-align center

          .list
            display none

        .case-list-box
          display flex
          flex-wrap wrap
          justify-content space-between

          .list
            width 49%
            margin-bottom 18px
            box-shadow 0px 2px 10px rgba(1, 20, 44, 0.1)

            .case-cover
              width 100%
              height 195px
              object-fit cover
              border-radius 3px 3px 0px 0px
              vertical-align middle

            .content
              padding 12px
              opacity 1
              border-radius 0px 0px 3px 3px
              background #FFFFFF

              .content-title
                font-size 18px
                font-weight bold
                margin-bottom 6px
                color #303133

              .content-name
                font-size 14px
                color #606266
                line-height 20px
                margin-bottom 10px
                height 40px
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical
                overflow hidden

              .brand-list
                color #C0C4CC
                font-size 12px
                display flex
                justify-content space-between

        .view-more-case
          width 176px
          height 42px
          line-height 42px
          text-align center
          margin auto
          border 1px solid #909399
          opacity 1
          border-radius 3px
          font-size 16px
          margin-top 6px
          color #606266

      .work-partner
        background #FFFFFF

        .work-partner-box
          .work-number
            text-align center
            padding 48px 0 45px 0
            color #303133
            font-size 32px
            font-weight bold

          .shadow
            display flex
            position relative
            z-index 99

            .left-shadow
              width 82px
              height 280px
              background linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
              opacity 1
              position absolute

            .right-shadow
              width 76px
              height 280px
              background linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
              opacity 1
              position absolute
              right 0

          .pc-work-logo-list
            display none

          .ipad-work-logo-list
            margin-bottom 40px

            .van-swipe-item
              display flex
              flex-wrap wrap

              .icone-partner
                width 25%
                margin-bottom 24px

                .login-partner
                  width 100%

          .button-tip-box
            display flex
            justify-content space-between
            align-items center
            height 64px
            line-height 64px
            padding 0 16px
            margin 0 28px
            background #2988FC
            opacity 1
            border-radius 8px 8px 0 0

            .tip-title
              color #FFFFFF
              font-size 20px

            .btn-tip
              width 133px
              height 46px
              line-height 46px
              text-align center
              color #2988FC
              font-size 22px
              background #FFFFFF
              border-radius 4px

  @media all and (min-width: 768px)
    .move-work-logo-list
      display none

  @media all and (max-width: 767px)
    #index
      .headline-move
        .ic-back
          height 428px

        .headline-content
          .headline-content-top
            padding-left 16px

            .title
              color #3A3A3A
              font-size 34px
              padding-top 72px
              height 43px
              line-height 43px

            .small-title
              color #3A3A3A
              font-size 12px
              letter-spacing 3px
              margin-top 6px

            .about-us
              width 131px
              height 32px
              line-height 32px
              background #2988FC
              opacity 1
              border-radius 2px
              font-size 12px
              color #FFFFFF

          .headline-content-bottom
            color #ffffff

            .ic-blackboard
              width 54%
              margin-top 8%

            .list-top
              margin-top -40%
              display block

              .ic-icon
                width 47px
                height 47px
                margin-left 19%
                margin-top 6%

              .ic-letter
                width 13%
                height 11%
                margin-left 34%
                margin-top 2%

              .ic-book
                width 10%
                height 10%
                margin-left 57%
                position absolute

              .ic-logo
                width 15%
                height 12%
                margin-left 82%
                position absolute

            .list-bottom
            .list-style
              .ic-look-book
                width 68%
                height 60%
                margin-top 41%
                margin-left 37%

      .pc-product
        display none

      .product
        .title
          font-size 24px
          padding 36px 0 24px 0

        .my-swipe
          .van-swipe-item
            .product-box

              .name
                font-size 16px
                margin-bottom 10px

              .introduce
                padding 0 16px 10px 16px

              .see-more
                font-size 14px
                width 124px
                height 30px
                line-height 30px

        .indicator-slide
          margin-bottom 31px

          .indicator-list
            width 22px
            height 2px
            background rgba(41, 136, 252, 0.3)
            opacity 1
            border-radius 2px
            margin-right 8px

          .active
            background #2988FC
            opacity 1

      .service-all
        font-size 12px

        .service-all-box
          padding 32px 16px

          .service-all-title
            color #303133
            font-size 24px
            margin-bottom 24px

          .service-all-introduce
            width 100%

          .content
            .content-list
              width 44%
              height 3%
              padding 4% 2.5%
              margin-bottom 10px

              .ser-market-plann
                width 44px
                height 44px

              .ser-market-tit
                color #303133
                font-size 14px
                letter-spacing 1px
                margin-top 10px

              .active-list
                font-size 12px
                color #606266

          .view-more
            width 160px
            height 40px
            line-height 40px
            margin-top 6px
            font-size 14px

      .success-case
        padding 32px 18px

        .case-title-box
          .title
            margin-bottom 24px
            font-size 24px

        .case-list-box
          .list
            width 100%
            margin-bottom 16px

            .case-cover
              height 192px
              border-radius 3px 3px 0px 0px
              vertical-align middle

        .view-more-case
          width 124px
          height 30px
          line-height 30px
          font-size 14px
          margin-top 0
          color #606266

      .work-partner
        .work-partner-box
          .work-number
            font-size 24px
            padding 24px 0

          .shadow
            .left-shadow
              height 194px

            .right-shadow
              height 194px

          .pc-work-logo-list
            display none

          .ipad-work-logo-list
            display none

          .move-work-logo-list
            padding-bottom 12px

            .van-swipe-item
              display flex
              flex-wrap wrap

              .icone-partner
                width 33%
                margin-bottom 15px

                .login-partner
                  width 100%

          .button-tip-box
            display none
</style>
