import Vue from 'vue'
import Element from 'element-ui'
import AOS from 'aos'
import 'aos/dist/aos.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import myaxios from './assets/js/myaxios.js'
import 'vant/lib/index.css'
import 'element-ui/lib/theme-chalk/index.css'
const Base64 = require('js-base64').Base64
Vue.use(myaxios)
Vue.use(Element)
Vue.use(Vant)
Vue.config.productionTip = false

new Vue({
  Base64,
  router,
  store,
  render: h => h(App),
  created () {
    AOS.init()
  },
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
